import { TituloEleitoral } from "./TituloEleitoral";
import { Reservista } from "./Reservista";
import { RegNacionalExtrang } from "./RegNacionalExtrang";
import { InformacoesAdicionais } from "./InformacoesAdicionais";
import { IdentidadeProfissional } from "./IdentidadeProfissional";
import { EndExtrangeiro } from "./EndExtrangeiro";
import { CarteiraProfissional } from "./CarteiraProfissional";
import { CarteiraDeHabitacao } from "./CarteiraDeHabitacao";
import { Aposentadoria } from "./Aposentadoria";
import { RegistroGeral } from "./RegistroGeral";
import { Certidao } from "./Certidao";
import { Endereco } from "./Endereco";
import { Ric } from "./Ric";
import { Dependente } from "./Dependente";
import { Formacao } from "./Formacao";
import { Lotacao } from "./Lotacao";

export class DadosGeraisAprovacao {
  id: number;
  origemDados: string;
  cqc: boolean;
  empresa: string;
  matricula01: string;
  matricula02: string;
  cargoEfetivo01: string;
  cargoEfetivo02: string;
  lotacao01: string;
  lotacao02: string;
  rhResponsavel: string;
  cargo: string;
  cpf: string;
  cpfAtualizado: any;
  docCpfAtualizado: string;
  imgCpfAtualizado: string;
  nome: string;
  docNomeAttFrente: string;
  imgNomeAttFrente: string;
  docNomeAttVerso: string;
  imgNomeAttVerso: string;
  nomeSocial: string;
  docNomeSocialAttFrente: string;
  imgNomeSocialAttFrente: string;
  docNomeSocialAttVerso: string;
  imgNomeSocialAttVerso: string;
  ramal: string;
  sexo: string;

  docSexoAttFrente: string;
  imgSexoAttFrente: string;

  docSexoAttVerso: string;
  imgSexoAttVerso: string;

  docNascFrente: string;
  imgNascFrente: string;

  docNascVerso: string;
  imgNascVerso: string;

  grupoSanguineo: string;
  racaCor: string;
  deficienciaFisica: string;
  docCertNascFrente: String;
  docCertNascVerso: String;

  docDeficienciaFisicaFrente: string;
  defFisicaFrente: string;
  docDeficienciaFisicaVerso: string;
  defFisicaVerso: string;

  deficienciaVisual: string;
  docDeficienciaVisualFrente: string;
  docDeficienciaVisualVerso: string;

  defVisualFrente: string;
  defVisualVerso: string;

  deficienciaAuditiva: string;
  docDeficienciaAuditivaFrente: string;
  defAuditivaFrente: string;
  docDeficienciaAuditivaVerso: string;
  defAuditivaVerso: string;

  deficienciaMental: string;
  docDeficienciaMentalFrente: string;
  defMentalFrente: string;
  docDeficienciaMentalVerso: string;
  defMentalVerso: string;

  deficienciaIntelectual: string;
  docDeficienciaIntelectualFrente: string;
  defIntelectualFrente: string;
  docDeficienciaIntelectualVerso: string;
  defIntelectualVerso: string;

  docNacionalidadeFrente: string;
  imgNacionalidadeFrente: string;

  docNacionalidadeVerso: string;
  imgNacionalidadeVerso: string;

  reabilitado: string;
  cota: string;
  dataNascimento: string;
  paisNacionalidade: string;
  municipioNascimento: string;
  ufNascimento: string;
  nomePai: string;
  nomeMae: string;
  estadoCivil: string;
  identidadeGenero: string;
  grauInstrucao: string;
  primeiroEmprego: string;
  ufEmpregoAnterior: string;
  numeroNis: string;
  docNumeroNisFrente: string;
  imgNumeroNisFrente: string;
  docNumeroNisVerso: string;
  imgNumeroNisVerso: string;
  numeroDependentes: string;
  aposentadoria: Aposentadoria = new Aposentadoria();
  registroGeral: RegistroGeral = new RegistroGeral();
  carteiraDeHabilitacao: CarteiraDeHabitacao = new CarteiraDeHabitacao();
  carteiraProfissional: CarteiraProfissional = new CarteiraProfissional();
  certidao: Certidao = new Certidao();
  endereco: Endereco = new Endereco();
  endExtrangeiro: EndExtrangeiro = new EndExtrangeiro();
  identidadeProfissional: IdentidadeProfissional = new IdentidadeProfissional();
  infoAdicionais: InformacoesAdicionais = new InformacoesAdicionais();
  regNacionalExtrang: RegNacionalExtrang = new RegNacionalExtrang();
  reservista: Reservista = new Reservista();
  ric: Ric = new Ric();
  tituloEleitoral:TituloEleitoral = new TituloEleitoral();
  dependentes: Dependente[] = [];
  formacoes: Formacao[] = [];
  status: number;
  visualiza: boolean;
  fotoAgente: string;
  imagemAgente: string;
  nacionalidade: string;
  modalidade: string;
  cursoFormacao: string;
  entidade: string;
  inicioCurso: string;
  conclusaoCurso: string;
  arquivoInformacao: string;
  mensagem: string;
  lotacoes: Lotacao[];
  ip: string;
  operacao: string;
  loginRh: string;
}
