import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { SelectItem } from 'primeng/primeng';

import { CombosService } from '../combos.service';
import { ErrorHandlerService } from '../core/error-handler.service';
import { Aposentadoria } from '../model/Aposentadoria';
import { CarteiraDeHabitacao } from '../model/CarteiraDeHabitacao';
import { CertidaoDependente } from '../model/CertidaoDependente';
import { DadosGeraisAprovacao } from '../model/DadosGeraisAprovacao';
import { DadosGeraisAprovacaoDetails } from '../model/DadosGeraisAprovacaoDetails';
import { Dependente } from '../model/Dependente';
import { Endereco } from '../model/Endereco';
import { EnderecoDependente } from '../model/EnderecoDependente';
import { EndExtrangeiro } from '../model/EndExtrangeiro';
import { Formacao } from '../model/Formacao';
import { IdentidadeProfissional } from '../model/IdentidadeProfissional';
import { InformacoesAdicionais } from '../model/InformacoesAdicionais';
import { JsonMessage } from '../model/JsonMessage';
import { RegistroGeralDependente } from '../model/RegistroGeralDependente';
import { RegNacionalExtrang } from '../model/RegNacionalExtrang';
import { Reservista } from '../model/Reservista';
import { Ric } from '../model/Ric';
import { TituloEleitoral } from '../model/TituloEleitoral';
import { RecadastramentoService } from '../recadastramento/recadastramento.service';
import { AuthService } from '../seguranca/auth.service';
import { CarteiraProfissional } from './../model/CarteiraProfissional';
import { Certidao } from './../model/Certidao';
import { RegistroGeral } from './../model/RegistroGeral';
import { FileDownloadService } from './file-download.service';

const MIME_TYPES = {
  jpg: "image/jpg",
  png: "image/png",
  pdf: "application/pdf",
};

@Component({
  selector: "app-visualiza-recadastramento",
  templateUrl: "./visualiza-recadastramento.component.html",
  styleUrls: ["visualiza-recadastramento.component.css"],
})
export class VisualizaRecadastramentoComponent implements OnInit {
  dadosGeraisAprovacao: DadosGeraisAprovacao = new DadosGeraisAprovacao();
  dependente: Dependente;
  mensagem: string;
  cpf: string;
  visualiza: boolean;
  racaecor: SelectItem[];
  grauinstrucoes: SelectItem[];
  paises: SelectItem[];
  municipios: SelectItem[];
  ufs: SelectItem[];
  estadosCivis: SelectItem[];
  idGenero: SelectItem[];

  grupoSanguineo: SelectItem[];
  tiposCursos: SelectItem[];

  ptBr: any;
  tiposCertidao: SelectItem[];
  logradouros: SelectItem[];
  classificacaoes: SelectItem[];
  tiposDependentes: SelectItem[];
  bytearrayimgteste: any;
  temFoto: boolean = false;
  columns: number[];
  url: string;
  userIP: any;
  tipoDeUniao = new FormGroup({
    myRadioUniao: new FormControl(),
  });
  isLoad: boolean;
  isLoadImagem: boolean;

  public screenWidth: any;
  public screenHeight: any;

  constructor(
    private recadastramentoService: RecadastramentoService,
    public auth: AuthService,
    private toasty: ToastyService,
    private errorHandler: ErrorHandlerService,
    private combo: CombosService,
    private activatedRoute: ActivatedRoute,
    private service: FileDownloadService,
    private _sanitizer: DomSanitizer,
    private httpClient: HttpClient
  ) {
    this.activatedRoute.queryParams.subscribe((parametros) => {
      if (parametros["cpf"]) {
        this.cpf = parametros["cpf"];
      }
      if (parametros["visualiza"]) {
        this.visualiza = parametros["visualiza"];
      }
    });
  }

  ngOnInit() {
    this.pesquisar();
    this.carregarCombos();
    this.dependente = new Dependente();
    this.columns = [];
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.httpClient.get<{ip:string}>('https://api.ipify.org?format=json')
    .subscribe( data => {
      this.userIP = data.ip;
    });
    console.log(this.dadosGeraisAprovacao.dependentes)
  }

  addColumn() {
    this.columns.push(this.columns.length);
  }

  removeColumn() {
    this.columns.splice(-1, 1);
  }

  ngAfterViewChecked() {
    var chechbutton = document.getElementsByClassName("background-download");
    if (chechbutton.length !== 0) {
      var el = document.getElementsByClassName("background-download")[0]
        .parentElement.parentElement.parentElement.parentElement.parentElement
        .firstElementChild;
      el.setAttribute("style", "background: #ADD8E6 ");
    }
  }

  changeCss() {
    this.temFoto = false;
  }

  pesquisar() {
    this.isLoad = true;
    this.recadastramentoService
      .pesquisar(this.cpf)
      .then((resultado) => {
        this.loadDetails(this.cpf);

        this.dadosGeraisAprovacao = resultado;
        if (
          resultado.fotoAgente != null &&
          resultado.fotoAgente != "" &&
          resultado.fotoAgente != undefined
        ) {
          let urlImg = resultado.cpf;

          this.getimgurl(urlImg);
        }
        this.dadosGeraisAprovacao.fotoAgente = resultado.fotoAgente;
        this.dadosGeraisAprovacao.registroGeral =
          this.dadosGeraisAprovacao.registroGeral != null
            ? this.dadosGeraisAprovacao.registroGeral
            : new RegistroGeral();
        this.dadosGeraisAprovacao.aposentadoria =
          this.dadosGeraisAprovacao.aposentadoria != null
            ? this.dadosGeraisAprovacao.aposentadoria
            : new Aposentadoria();
        this.dadosGeraisAprovacao.carteiraDeHabilitacao =
          this.dadosGeraisAprovacao.carteiraDeHabilitacao != null
            ? this.dadosGeraisAprovacao.carteiraDeHabilitacao
            : new CarteiraDeHabitacao();
        this.dadosGeraisAprovacao.carteiraProfissional =
          this.dadosGeraisAprovacao.carteiraProfissional != null
            ? this.dadosGeraisAprovacao.carteiraProfissional
            : new CarteiraProfissional();
        this.dadosGeraisAprovacao.certidao =
          this.dadosGeraisAprovacao.certidao != null
            ? this.dadosGeraisAprovacao.certidao
            : new Certidao();
        this.dadosGeraisAprovacao.endereco =
          this.dadosGeraisAprovacao.endereco != null
            ? this.dadosGeraisAprovacao.endereco
            : new Endereco();
        this.dadosGeraisAprovacao.endExtrangeiro =
          this.dadosGeraisAprovacao.endExtrangeiro != null
            ? this.dadosGeraisAprovacao.endExtrangeiro
            : new EndExtrangeiro();
        this.dadosGeraisAprovacao.identidadeProfissional =
          this.dadosGeraisAprovacao.identidadeProfissional != null
            ? this.dadosGeraisAprovacao.identidadeProfissional
            : new IdentidadeProfissional();
        this.dadosGeraisAprovacao.infoAdicionais =
          this.dadosGeraisAprovacao.infoAdicionais != null
            ? this.dadosGeraisAprovacao.infoAdicionais
            : new InformacoesAdicionais();
        this.dadosGeraisAprovacao.regNacionalExtrang =
          this.dadosGeraisAprovacao.regNacionalExtrang != null
            ? this.dadosGeraisAprovacao.regNacionalExtrang
            : new RegNacionalExtrang();
        this.dadosGeraisAprovacao.reservista =
          this.dadosGeraisAprovacao.reservista != null
            ? this.dadosGeraisAprovacao.reservista
            : new Reservista();
        this.dadosGeraisAprovacao.tituloEleitoral =
          this.dadosGeraisAprovacao.tituloEleitoral != null
            ? this.dadosGeraisAprovacao.tituloEleitoral
            : new TituloEleitoral();
        this.dadosGeraisAprovacao.dependentes =
          this.dadosGeraisAprovacao.dependentes != null
            ? this.dadosGeraisAprovacao.dependentes
            : ([] as Dependente[]);
        this.dadosGeraisAprovacao.formacoes =
          this.dadosGeraisAprovacao.formacoes != null
            ? this.dadosGeraisAprovacao.formacoes
            : ([] as Formacao[]);
        this.dadosGeraisAprovacao.visualiza = this.visualiza;
        this.dadosGeraisAprovacao.ric =
          this.dadosGeraisAprovacao.ric != null
            ? this.dadosGeraisAprovacao.ric
            : new Ric();
        if (
          this.dadosGeraisAprovacao.arquivoInformacao == undefined ||
          this.dadosGeraisAprovacao.arquivoInformacao == "" ||
          this.dadosGeraisAprovacao.arquivoInformacao != "N"
        ) {
          this.dadosGeraisAprovacao.arquivoInformacao = "S";
        }
        if (this.dadosGeraisAprovacao.dependentes.length > 0) {
          this.dependente = this.dadosGeraisAprovacao.dependentes[0];
          if (
            this.dependente.regGeralDependente == null ||
            this.dependente.regGeralDependente == undefined
          ) {
            this.dependente.regGeralDependente = new RegistroGeralDependente();
          }
          if (
            this.dependente.certidao == null ||
            this.dependente.certidao == undefined
          ) {
            this.dependente.certidao = new CertidaoDependente();
          }
          if (
            this.dependente.endDependente == null ||
            this.dependente.endDependente == undefined
          ) {
            this.dependente.endDependente = new EnderecoDependente();
          }
        }
        this.isLoad = false;
      })
      .catch((erro) => {
        this.isLoad = false;
        this.errorHandler.handle(erro);
      });
  }

  loadDetails(cpf: string) {
    console.log(this.dadosGeraisAprovacao.registroGeral)
    this.recadastramentoService.getDetails(cpf).then((res: DadosGeraisAprovacaoDetails) => {
        this.dadosGeraisAprovacao.registroGeral = res.registroGeral || this.dadosGeraisAprovacao.registroGeral;
        this.dadosGeraisAprovacao.tituloEleitoral = res.tituloEleitoral || this.dadosGeraisAprovacao.tituloEleitoral;
        this.dadosGeraisAprovacao.carteiraDeHabilitacao = res.carteiraDeHabilitacao || this.dadosGeraisAprovacao.carteiraDeHabilitacao;
        this.dadosGeraisAprovacao.carteiraProfissional = res.carteiraProfissional || this.dadosGeraisAprovacao.carteiraProfissional;
        this.dadosGeraisAprovacao.reservista = res.reservista || this.dadosGeraisAprovacao.reservista;
        this.dadosGeraisAprovacao.identidadeProfissional = res.identidadeProfissional || this.dadosGeraisAprovacao.identidadeProfissional;
        this.dadosGeraisAprovacao.certidao = res.certidao || this.dadosGeraisAprovacao.certidao;
        this.dadosGeraisAprovacao.endereco = res.endereco || this.dadosGeraisAprovacao.endereco;
        this.dadosGeraisAprovacao.ric = res.ric || this.dadosGeraisAprovacao.ric;
        this.dadosGeraisAprovacao.regNacionalExtrang = res.regNacionalExtrang || this.dadosGeraisAprovacao.regNacionalExtrang;
        this.dadosGeraisAprovacao.infoAdicionais = res.infoAdicionais || this.dadosGeraisAprovacao.infoAdicionais;
        this.dadosGeraisAprovacao.endExtrangeiro = res.endExtrangeiro || this.dadosGeraisAprovacao.endExtrangeiro;
        this.dadosGeraisAprovacao.aposentadoria = res.aposentadoria || this.dadosGeraisAprovacao.aposentadoria;
        this.dadosGeraisAprovacao.dependentes = res.dependentes || this.dadosGeraisAprovacao.dependentes;
        this.dadosGeraisAprovacao.formacoes = res.formacoes || this.dadosGeraisAprovacao.formacoes;
        this.toasty.success('Todas as informações foram carregadas');
    })
}

  //Carrega no campo de visualizar a foto de perfil
  getimgurl(cpf: string) {
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "", campo: "FOTO"})
      .then((resultado) => {
        this.temFoto = true;
        this.bytearrayimgteste = this._sanitizer.bypassSecurityTrustResourceUrl(
          "data:image/jpg;base64," + resultado.message
        );
        //this.bytearrayimgteste = resultado.message;
        //document.getElementsByClassName("ui-fileupload-content")[0].insertAdjacentHTML('beforeend',"<img id='imgOld' style=\"width:126%;height:111%;margin-left: -15px;margin-top: -11px; overflow: hidden;background-size: cover;background-position: center;background-image:url('data:image/png;base64,"+this.bytearrayimgteste+"')\"></img>");

        // document.getElementsByClassName("ui-fileupload-content")[0].insertAdjacentHTML('beforeend',"<img id='imgOld' [src]='\"this.bytearrayimgteste\"' style=\"width:126%;height:111%;margin-left: -15px;margin-top: -11px; overflow: hidden;background-size: cover;background-position: center;\"></img>");
      })
      .catch((erro) => {
        this.errorHandler.handle(erro);
      });
  }

  carregarCombos() {
    this.racaecor = this.combo.racaecor;
    this.grauinstrucoes = this.combo.grauinstrucoes;
    this.paises = this.combo.paises;
    this.municipios = this.combo.municipios;
    this.ufs = this.combo.ufs;
    this.estadosCivis = this.combo.estadosCivis;
    this.tiposCursos = this.combo.tiposCursos;
    this.grupoSanguineo = this.combo.grupoSanguineo;
    this.ptBr = this.combo.ptBr;
    this.tiposCertidao = this.combo.tiposCertidao;
    this.logradouros = this.combo.tipoLogradouros;
    this.classificacaoes = this.combo.tiposClassificacao;
    this.tiposDependentes = this.combo.tiposDependentes;
    this.idGenero = this.combo.idGenero;
  }

  editarDep(dependente: any) {
    this.dependente = JSON.parse(JSON.stringify(dependente));
    if (this.dependente.regGeralDependente == null) {
      this.dependente.regGeralDependente = new RegistroGeralDependente();
    }
    if (this.dependente.certidao == null) {
      this.dependente.certidao = new CertidaoDependente();
    }
  }

  // ========CPF atualizado========
  downloadFileCpfAtualizado(cpfAtualizado: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CPF"})
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
  }

  getImgUrlCpfAtualizado(cpfAtualizado: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCpfAtualizado(cpfAtualizado)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========RNE FRENTE========
  downloadFileRneFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "RNE"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlRneFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageRneFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========RNE VERSO========
  downloadFileRneVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "RNE"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgRneVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageRneVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Nome atualizado frente========
  downloadFileNomeAttFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "NOME"})
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
  }

  getImgUrlNomeAttFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNomeAttFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Nome atualizado verso========
  downloadFileNomeAttVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "NOME"})
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
  }

  getImgUrlNomeAttVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNomeAttVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Nome Social Frente========
  downloadFileNomeSocialFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "NSOC"})
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
  }

  getImgUrlNomeSocialFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNomeSocialFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Nome Social Verso========
  downloadFileNomeSocialVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "NSOC"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlNomeSocialVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNomeSocialVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Sexo Frente========
  downloadFileSexoFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "SEXO"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlSexoFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageSexoFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Sexo Verso========
  downloadFileSexoVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "SEXO"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlSexoVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageSexoVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Nasc Frente========
  downloadFileNascFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CERTNASC"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }



  openImageNewWindow(data: JsonMessage) {
    if(data == null || data.message == 'Arquivo não localizado no servidor') {
      this.toasty.error("Imagem não localizada, tente novamente mais tarde!")
      return;
    }
    if(data.fileType != "pdf") {
      var image = new Image();
      image.src = "data:image/"+data.fileType+";base64," + data.message;
  
      var w = window.open("");
      w.document.body.style.margin = "0px"; 
      w.document.body.style.background = "#0e0e0e";
      w.document.body.style.height = "100%";
   
      w.document.body.appendChild(w.document.createElement("img"));
      var img = w.document.getElementsByTagName("img")[0];
      img.style.width = "100%";
      img.style.height = "auto";    
      img.style.display = "block";
      img.src = image.src;  
    } else {     
      var byteCharacters = atob(data.message);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);    
    }
    
  }

  getImgUrlNascFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNascFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ========Nasc Verso========
  downloadFileNascVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "CERTNASC"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlNascVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNascVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ========Numero Inscricao Frente========
  downloadFileNumInscricaoFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "IP"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlNumInscricaoFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNumInscricaoFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.errorHandler.handle(erro);
      });
  }


  // ========Numero Inscricao Verso========
  downloadFileNumInscricaoVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "IP"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlNumInscricaoVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNumInscricaoVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.errorHandler.handle(erro);
      });
  }

  // ========Nacionalidade Frente========
  downloadFileNacionalidadeFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "NACIO"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlNacionalidadeFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNacionalidadeFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Nacionalidade Verso========
  downloadFileNacionalidadeVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "NACIO"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlNacionalidadeVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNacionalidadeVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente RG========
  downloadFileFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "RG"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso RG========
  downloadFileVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "RG"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Titulo de eleitor frente========
  downloadFileTitEleitorFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "TITULO"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlTitEleitorFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageTitEleitorFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        let img = this._sanitizer.sanitize(
          SecurityContext.RESOURCE_URL,
          this._sanitizer.bypassSecurityTrustResourceUrl(
            "data:image/jpg;base64," + resultado.message
          )
        );
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Titulo de eleitor verso========
  downloadFileTitEleitorVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "TITULO"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlTitEleitorVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageTitEleitorVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        let img = this._sanitizer.sanitize(
          SecurityContext.RESOURCE_URL,
          this._sanitizer.bypassSecurityTrustResourceUrl(
            "data:image/jpg;base64," + resultado.message
          )
        );
        this.openImageNewWindow(resultado);

      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }



  // ========Cnh frente========
  downloadFileCnhFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CNH"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlCnhFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCnhFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Cnh verso========
  downloadFileCnhVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "CNH"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlCnhVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCnhVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Ctps Frente========
  downloadFileCtpsFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CTPS"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlCtpsFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCtpsFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Ctps Verso========
  downloadFileCtpsVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "CTPS"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlCtpsVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCtpsVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Numero Nis Frente========
  downloadFileNumeroNisFrente(numeroNis: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "NIS"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlNumeroNisFrente(numeroNis: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNumeroNisFrente(numeroNis)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Numero Nis Verso========
  downloadFileNumeroNisVerso(numeroNis: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "NIS"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlNumeroNisVerso(numeroNis: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNumeroNisVerso(numeroNis)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Documento Militar Frente========
  downloadFileDocumentoMilitarFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DM"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDocumentoMilitarFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDocumentoMilitarFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ========Documento Militar Verso========
  downloadFileDocumentoMilitarVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DM"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDocumentoMilitarVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDocumentoMilitarVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Dependente NomeAttFrente========
  downloadFileDependenteNomeAttFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "NOME", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteNomeAttFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteNomeAttFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Dependente NomeAttVerso========
  downloadFileDependenteNomeAttVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "NOME", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteNomeAttVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteNomeAttVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Dependente SexoAttFrente========
  downloadFileDependenteSexoAttFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "SEXO", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteSexoAttFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteSexoAttFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Dependente SexoAttVerso========
  downloadFileDependenteSexoAttVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "SEXO", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteSexoAttVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteSexoAttVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Dependente Registro Geral Frente========
  downloadFileDependenteRegGeral(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "RG", dependente: id})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteRegGeral(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteRegGeral(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Dependente Registro Geral Verso========
  downloadFileDependenteRegGeralVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "RG", dependente: id})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteRegGeralVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteRegGeralVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente DefFisica========
  downloadFileFrenteDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFF"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlFrenteDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrenteDefFisica(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso DefFisica========
  downloadFileVersoDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFF"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlVersoDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVersoDefFisica(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente DefVisual========
  downloadFileFrenteDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFV"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlFrenteDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrenteDefVisual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso DefVisual========
  downloadFileVersoDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFV"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlVersoDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVersoDefVisual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente DefAuditiva========
  downloadFileFrenteDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFA"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlFrenteDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrenteDefAuditiva(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso DefAuditiva========
  downloadFileVersoDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFA"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlVersoDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVersoDefAuditiva(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente DefMental========
  downloadFileFrenteDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFM"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlFrenteDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrenteDefMental(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso DefMental========
  downloadFileVersoDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFM"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlVersoDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVersoDefMental(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente DefIntelectual========
  downloadFileFrenteDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFI"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlFrenteDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrenteDefIntelectual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso DefIntelectual========
  downloadFileVersoDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFI"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlVersoDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVersoDefIntelectual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ----------------------------------------------------------------------------------------
  // ======== Busca a imagem parte da frente DefFisica========

  downloadFileDependenteFrenteDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFF", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteFrenteDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefFisica(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da verso DefFisica========

  downloadFileDependenteVersoDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFF", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteVersoDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteVersoDefFisica(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da frente DefVisual========

  downloadFileDependenteFrenteDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFV", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteFrenteDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefVisual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da verso DefVisual========

  downloadFileDependenteVersoDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFV", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteVersoDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteVersoDefVisual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da frente DefFisica========

  downloadFileDependenteFrenteDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFA", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteFrenteDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefAuditiva(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da Verso DefAuditiva========

  downloadFileDependenteVersoDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFA", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteVersoDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteVersoDefAuditiva(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da frente DefMental========

  downloadFileDependenteFrenteDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFM", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteFrenteDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefMental(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da Verso DefFisica========

  downloadFileDependenteVersoDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFM", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteVersoDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefMental(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da frente DefIntelectual========

  downloadFileDependenteFrenteDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFI", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteFrenteDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefIntelectual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da Verso DefFisica========

  downloadFileDependenteVersoDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFI", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlDependenteVersoDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteVersoDefIntelectual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ----------------------------------------------------------------------------------------

  aprovarReprovar(status) {
    this.dadosGeraisAprovacao.status = status;
    this.dadosGeraisAprovacao.loginRh = this.auth.jwtPayload.cpf;
    this.dadosGeraisAprovacao.ip = this.userIP;

    console.log("carteiraDeHabilitacao", this.dadosGeraisAprovacao.carteiraDeHabilitacao)

    this.recadastramentoService
      .aprovar(this.dadosGeraisAprovacao)
      .then(() => {
        if (status == 2) {
          this.toasty.success("Aprovação realizada com sucesso!");
        } else {
          this.toasty.success("Reprovação realizada com sucesso!");
        }
        this.dadosGeraisAprovacao.visualiza = true;
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  rgFrenteUpload(event, upload: any) {
    if (this.dadosGeraisAprovacao.docNascFrente == null) {
      this.recadastramentoService
        .salvarAnexo(
          event,
          "_FRENTE",
          "_CERTNASC",
          this.dadosGeraisAprovacao.docNascFrente,
          null
        )
        .then((resultado) => {
          this.dadosGeraisAprovacao.docNascFrente = resultado.message;
          upload.chooseLabel = this.dadosGeraisAprovacao.docNascFrente;
        })
        .catch((erro) => this.errorHandler.handle(erro));
    } else {
      this.dadosGeraisAprovacao.docNascFrente = null;
      upload.chooseLabel = "Doc RG Frente";
      upload.clear();
    }
  }

  rgVersoUpload(event, upload: any) {
    if (this.dadosGeraisAprovacao.docNascVerso == null) {
      this.recadastramentoService
        .salvarAnexo(
          event,
          "_VERSO",
          "_CERTNASC",
          this.dadosGeraisAprovacao.docNascVerso,
          null
        )
        .then((resultado) => {
          this.dadosGeraisAprovacao.docNascVerso = resultado.message;
          upload.chooseLabel = this.dadosGeraisAprovacao.docNascVerso;
        })
        .catch((erro) => this.errorHandler.handle(erro));
    } else {
      this.dadosGeraisAprovacao.docNascVerso = null;
      upload.chooseLabel = "Doc RG Verso";
      upload.clear();
    }
  }

  // ========CPF atualizado========
  downloadFileCpfFrenteDependente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CPF", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlCpfFrenteDependente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCpfFrenteDependente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========CPF atualizado========
  downloadFileCpfVersoDependente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "CPF", dependente: cpf})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlCpfVersoDependente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCpfVersoDependente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Certidão UniaoEstavel Verso========
  downloadFileCertidaoUniaoEstavelFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CCASA"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlCertidaoUniaoEstavelFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCertidaoUniaoEstavelFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Certidão UniaoEstavel Verso========
  downloadFileCertidaoUniaoEstavelVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "CCASA"})
    .then((resultado) => {
      this.isLoadImagem = false;
      this.openImageNewWindow(resultado);
    })
    .catch((erro) => {
      this.isLoadImagem = false;
      this.errorHandler.handle(erro);
    });
  }

  getImgUrlCertidaoUniaoEstavelVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCertidaoUniaoEstavelVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  visualizarDependente(dep: Dependente) {
    this.dependente = dep;
    if (
      this.dependente.regGeralDependente == null ||
      this.dependente.regGeralDependente == undefined
    ) {
      this.dependente.regGeralDependente = new RegistroGeralDependente();
    }
    if (
      this.dependente.certidao == null ||
      this.dependente.certidao == undefined
    ) {
      this.dependente.certidao = new CertidaoDependente();
    }
    if (
      this.dependente.endDependente == null ||
      this.dependente.endDependente == undefined
    ) {
      this.dependente.endDependente = new EnderecoDependente();
    }
  }
}
