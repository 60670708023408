import { Perfil } from "./Perfil";

export class UsuarioFiltro {
  id: number;
  nome: string;
  login: string;
  cpf: string;
  perfis: Perfil[];
  perfil: Perfil;
  page = 0;
  itensPorPagina = 50;
}
