import { UsuarioFiltro } from "./../model/UsuarioFiltro";
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpParams } from "@angular/common/http";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { RecadastramentoHttp } from "../seguranca/recadastramentoHttp";
import { Usuario } from "../model/Usuario";

@Injectable({
  providedIn: "root",
})
export class ConsultaLotacaoService {
  usuariosUrl: string;

  constructor(private http: RecadastramentoHttp) {
    this.usuariosUrl = `${environment.apiUrl}/usuario`;
  }

  async pesquisarAgentes(filtro: UsuarioFiltro): Promise<any> {
    let params = new HttpParams();

    params = params.append("page", filtro.page.toString());
    params = params.append("size", filtro.itensPorPagina.toString());

    if (filtro.nome != null) {
      params = params.append("nome", filtro.nome);
    }

    if (filtro.login != null) {
      params = params.append("login", filtro.login);
    }

    if (filtro.cpf != null) {
      params = params.append("cpf", filtro.cpf);
    }

    const response = await this.http
      .get<any>(`${this.usuariosUrl}/listar/agentes`, { params })
      .toPromise();
    const usuarios = response.content;
    const resultado = {
      usuarios,
      total: response.totalElements,
    };
    return resultado;
  }
}
