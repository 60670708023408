import { Injectable } from '@angular/core';
import { RecadastramentoHttp } from './recadastramentoHttp';
import { AuthService } from './auth.service';

import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  tokensRenokeUrl: string;

  constructor(
    private http: RecadastramentoHttp,
    private auth: AuthService
  ) {
    this.tokensRenokeUrl = `${environment.apiUrl}/tokens/revoke`;
  }

  async logout() {
    await this.http.delete(this.tokensRenokeUrl, { withCredentials: true })
      .toPromise();
    this.auth.limparAccessToken();
  }
}
