import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  urlDowload: string;

  constructor(private http:HttpClient) { 
    this.urlDowload = `${environment.apiUrl}/download`;
  }

  downloadFile(data) {
    const REQUEST_PARAMS = new HttpParams().set('fileName', data.fileName);
    return this.http.get(this.urlDowload, {
      params: REQUEST_PARAMS,
      responseType: 'arraybuffer'
    });
  }  
}