import { Perfil } from "./../model/Perfil";
import { ErrorHandlerService } from "./../core/error-handler.service";
import { PerfilService } from "./../user-santander/perfil.service";
import { Usuario } from "./../model/Usuario";
import { ConsultaLotacaoService } from "./consulta-lotacao.service";
import { Component, OnInit, ViewChild } from "@angular/core";

import { Validators, FormGroup, FormBuilder } from "@angular/forms";

import { LazyLoadEvent } from "primeng/primeng";
import { ToastyService } from "ng2-toasty";

import { Router } from "@angular/router";
import { UsuarioFiltro } from "../model/UsuarioFiltro";

import { from } from "rxjs";

@Component({
  selector: "app-consulta-lotacao",
  templateUrl: "./consulta-lotacao.component.html",
  styleUrls: ["./consulta-lotacao.component.css"],
})
export class ConsultaLotacaoComponent implements OnInit {
  totalRegistros = 0;
  registerForm: FormGroup;

  @ViewChild("tabela") grid;

  cols: any[];

  usuarios: Usuario[];

  filter = new UsuarioFiltro();

  perfis: Perfil[];
  submitted = false;

  edit = false;

  usuario: Usuario;

  constructor(
    private formBuilder: FormBuilder,
    private consultaLotacaoService: ConsultaLotacaoService,
    private perfilservice: PerfilService,
    private toasty: ToastyService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      nome: ["", Validators.required],
      matricula: ["", Validators.required],
      cpf: ["", [Validators.required]],
      perfil: ["", [Validators.required]],
    });

    this.preencherPerfis();
    this.filter = new UsuarioFiltro();
    this.usuario = new Usuario();
    this.pesquisar();
    this.edit = false;
  }

  pesquisar(pagina = 0) {
    this.filter.page = pagina;
    this.consultaLotacaoService
      .pesquisarAgentes(this.filter)
      .then((resultado) => {
        this.usuarios = resultado.usuarios;
        this.totalRegistros = resultado.total;
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  preencherPerfis() {
    this.perfilservice
      .pesquisar()
      .then((perfis) => (this.perfis = perfis))
      .catch((erro) => this.errorHandler.handle(erro));
  }

  associar(usuario: any) {
    usuario.perfis.forEach((perfil) => {
      usuario.perfilDescricao = perfil.descricao;
    });

    this.router.navigate(["/lotacao-associacao"], { queryParams: usuario });
  }

  limpar() {
    this.filter = new UsuarioFiltro();
    this.edit = false;
    this.pesquisar();
  }

  mudaPagina(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    if (this.edit) {
      this.limpar();
    }
    this.pesquisar(pagina);
  }
}
