import { DetalheRegistroComponent } from "./detalhe-registro/detalhe-registro.component";
import { RegistroRejeitadoComponent } from "./registro-rejeitado/registro-rejeitado.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NgxMaskModule } from "ngx-mask";
import { UserSantanderComponent } from "./user-santander/user-santander.component";
import { BoasVindasComponent } from "./boas-vindas/boas-vindas.component";
import { RhComponent } from "./rh/rh.component";
import { LotacaoAssociacaoComponent } from "./lotacao-associacao/lotacao-associacao.component";
import { ConsultaLotacaoComponent } from "./consulta-lotacao/consulta-lotacao.component";
import { CadastroBannerComponent } from "./cadastro-banner/cadastro-banner.component";
import { RecadastramentoComponent } from "./recadastramento/recadastramento.component";
import { ConsultaRecadastramentoComponent } from "./consulta-recadastramento/consulta-recadastramento.component";
import { FaqComponent } from "./faq/faq.component";
import { TimelineComponent } from "./timeline/timeline.component";
import { CadastroVigenciasComponent } from "./cadastro-vigencias/cadastro-vigencias.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { VisualizaRecadastramentoComponent } from "./visualiza-recadastramento/visualiza-recadastramento.component";
import { ConcluirRecadastramentoComponent } from "./concluir-recadastramento/concluir-recadastramento.component";
import { ESocialComponent } from "./e-social/e-social.component";
import { CepComponent } from "./cep/cep.component";
import { DuplaAprovacaoComponent } from "./dupla-aprovacao/dupla-aprovacao.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "user", component: UserSantanderComponent },
  { path: "boas-vindas", component: BoasVindasComponent },
  { path: "rh", component: RhComponent },
  { path: "lotacao-associacao", component: LotacaoAssociacaoComponent },
  { path: "consulta-lotacao", component: ConsultaLotacaoComponent },
  { path: "cadastro-banner", component: CadastroBannerComponent },
  { path: "recadastramento", component: RecadastramentoComponent },
  {
    path: "consulta-recadastramento",
    component: ConsultaRecadastramentoComponent,
  },
  { path: "faq", component: FaqComponent },
  { path: "timeline", component: TimelineComponent },
  { path: "cadastro-vigencia", component: CadastroVigenciasComponent },
  { path: "dashboard", component: DashboardComponent },
  {
    path: "visualiza-recadastramento",
    component: VisualizaRecadastramentoComponent,
  },
  {
    path: "concluir-recadastramento",
    component: ConcluirRecadastramentoComponent,
  },
  { path: "e-social", component: ESocialComponent },
  { path: "cep", component: CepComponent },
  { path: "duplaaprovacao", component: DuplaAprovacaoComponent },
  { path: "registro-rejeitado", component: RegistroRejeitadoComponent },
  { path: "detalhe-registro", component: DetalheRegistroComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgxMaskModule.forRoot()],
  exports: [RouterModule, NgxMaskModule],
})
export class AppRoutingModule {}
