

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RecadastramentoHttp } from './seguranca/recadastramentoHttp';

@Injectable({
  providedIn: 'root'
})
export class EstadoMunicipioService {

  estadoMunicipioUrl: any;

  constructor(private http: RecadastramentoHttp) {
    this.estadoMunicipioUrl = `${environment.apiUrl}/estadomunicipio`;
  }

  async listaMunicipioPorEstado(uf: String): Promise<any> {
    const res = await this.http.get<any>(`${this.estadoMunicipioUrl}/listar/municipios/` + uf)
      .toPromise();
    return res;
  }

}
