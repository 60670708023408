import { environment } from './../../environments/environment';
import { Perfil } from './../model/Perfil';
import { Injectable } from '@angular/core';
import { RecadastramentoHttp } from '../seguranca/recadastramentoHttp';


@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  perfilUrl: string;

  constructor(private http: RecadastramentoHttp) {
    this.perfilUrl = `${environment.apiUrl}/perfil/listar`;
  }

  async pesquisar() {
    const res = await this.http.get<any>(this.perfilUrl)
      .toPromise();
    const data = (res as Perfil[]);
    return data;
  }
}
