import { CadastroBannerService } from "./../cadastro-banner/cadastro-banner.service";
import { TimelineService } from "./../timeline/timeline.service";
import { LotacaoAssociacaoComponent } from "./../lotacao-associacao/lotacao-associacao.component";
import { ConsultaLotacaoComponent } from "./../consulta-lotacao/consulta-lotacao.component";
import { RhComponent } from "./../rh/rh.component";
import { Title } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FileUploadModule } from "primeng/fileupload";
import { InputTextareaModule } from "primeng/inputtextarea";

import { AutoCompleteModule } from "primeng/autocomplete";

import { ConfirmationService } from "primeng/components/common/api";
import { ConfirmDialogModule } from "primeng/components/confirmdialog/confirmdialog";
import { AuthService } from "../seguranca/auth.service";
import { ErrorHandlerService } from "./error-handler.service";
import { ToastyModule } from "ng2-toasty";
import { NavbarComponent } from "./navbar/navbar.component";
import { PerfilService } from "../user-santander/perfil.service";
import { UserSantanderService } from "../user-santander/user-santander.service";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UserSantanderComponent } from "../user-santander/user-santander.component";
import { BoasVindasComponent } from "../boas-vindas/boas-vindas.component";
import {
  InputTextModule,
  FieldsetModule,
  InputMaskModule,
  DropdownModule,
  TooltipModule,
  MessageModule,
  MessageService,
  DialogModule,
  DataTableModule,
  PaginatorModule,
  ProgressSpinnerModule,
} from "primeng/primeng";
import { RadioButtonModule } from "primeng/radiobutton";
import { TableModule } from "primeng/table";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CheckboxModule } from "primeng/checkbox";
import { AccordionModule } from "primeng/accordion";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { NgxMaskModule } from "ngx-mask";
import { JwtHelperService } from "@auth0/angular-jwt";
import { RecadastramentoHttp } from "../seguranca/recadastramentoHttp";
import { PickListModule } from "primeng/picklist";
import { CadastroBannerComponent } from "./../cadastro-banner/cadastro-banner.component";
import { TieredMenuModule } from "primeng/tieredmenu";
import { MenuModule } from "primeng/menu";
import { PanelModule } from "primeng/panel";

import { registerLocaleData } from "@angular/common";
import br from "@angular/common/locales/br";

import { RecadastramentoComponent } from "../recadastramento/recadastramento.component";
import { CalendarModule } from "primeng/calendar";
import { ConsultaRecadastramentoComponent } from "../consulta-recadastramento/consulta-recadastramento.component";
import { FaqComponent } from "../faq/faq.component";
import { TimelineComponent } from "../timeline/timeline.component";
import { CadastroVigenciasComponent } from "../cadastro-vigencias/cadastro-vigencias.component";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { VisualizaRecadastramentoComponent } from "../visualiza-recadastramento/visualiza-recadastramento.component";
import { CadastroVigenciasService } from "../cadastro-vigencias/cadastro-vigencias.service";
import { ConcluirRecadastramentoComponent } from "../concluir-recadastramento/concluir-recadastramento.component";
import { ESocialComponent } from "../e-social/e-social.component";
import { PrimeiroAcessoComponent } from "../primeiro-acesso/primeiro-acesso.component";
import { CepComponent } from "../cep/cep.component";
import { CepService } from "../cep/cep.service";
import { DuplaAprovacaoComponent } from "../dupla-aprovacao/dupla-aprovacao.component";
import { EstadoMunicipioService } from "../estado-municipio.service";
import {
  MatAutocompleteModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
} from "@angular/material";

library.add(faCoffee);

registerLocaleData(br, "pt-BR");

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ToastyModule.forRoot(),
    ConfirmDialogModule,
    FontAwesomeModule,
    // RouterModule.forRoot(routes),
    NgxMaskModule,
    MenuModule,
    MatProgressSpinnerModule,
    PanelModule,
    FileUploadModule,
    AutoCompleteModule,
    AccordionModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    FieldsetModule,
    MatProgressBarModule,
    InputMaskModule,
    DropdownModule,
    FontAwesomeModule,
    TableModule,
    TooltipModule,
    FormsModule,ProgressSpinnerModule,
    ReactiveFormsModule,
    MessageModule,
    HttpClientModule,
    PickListModule,
    TieredMenuModule,
    RadioButtonModule,
    CalendarModule,
    DialogModule,
    MatFormFieldModule,
    MatInputModule,
    TableModule,
    MatAutocompleteModule,
  ],
  declarations: [
    NavbarComponent,
    UserSantanderComponent,
    LotacaoAssociacaoComponent,
    ConsultaLotacaoComponent,
    BoasVindasComponent,
    CadastroBannerComponent,
    RhComponent,
    RecadastramentoComponent,
    ConsultaRecadastramentoComponent,
    VisualizaRecadastramentoComponent,
    CadastroVigenciasComponent,
    ESocialComponent,
    CepComponent,
  ],

  exports: [NavbarComponent, ToastyModule, ConfirmDialogModule],
  providers: [
    PerfilService,
    UserSantanderService,
    ErrorHandlerService,
    AuthService,
    RecadastramentoHttp,
    ConfirmationService,
    JwtHelperService,
    Title,
    ConfirmationService,
    CadastroBannerService,
    MessageService,
    CadastroVigenciasService,
    TimelineService,
    CepService,
    EstadoMunicipioService,
  ],
})
export class CoreModule {}
