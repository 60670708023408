import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from '../core/error-handler.service';
import { AuthService } from '../seguranca/auth.service';
import { Router } from '@angular/router';
import { CadastroVigenciasService } from '../cadastro-vigencias/cadastro-vigencias.service';
import { Perfil } from './../model/Perfil';
import { ToastyService } from 'ng2-toasty';
import { CombosService } from '../combos.service';

@Component({
  selector: 'app-cadastro-vigencias',
  templateUrl: './cadastro-vigencias.component.html',
  styleUrls: ['cadastro-vigencias.component.css']
})

export class CadastroVigenciasComponent implements OnInit {

  constructor(
    private errorHandler: ErrorHandlerService,
    private cadastroVigenciasService: CadastroVigenciasService,
    private auth: AuthService,
    private router: Router,
    private toasty: ToastyService,
    private combo: CombosService) {
    }

    ptBr: any;
    
    perfis: any;
    perfilRHCentral = new Perfil;
    perfilAgenteRH = new Perfil;
    perfilAgentePublico = new Perfil;
    
    

    ngOnInit() {
      this.listarPerfilVigencia();
      this.ptBr = this.combo.ptBr;
      this.hide_logo();
      // this.validaData();
      // this.validaData2();
      // this.validaData3();
    }

    hide_logo() {
    
      var el = document.getElementsByClassName('image')[0];
      
      el.setAttribute("style", "background: none;");
    }

    listarPerfilVigencia() {
      this.cadastroVigenciasService.listarPerfilVigencia()
        .then(p => {this.perfis = p
              this.preencherPerfis()
        })
        .catch(erro => this.errorHandler.handle(erro));
    }

    preencherPerfis(){
      this.perfis.forEach(p => {
        if(p.descricao == 'RH_CENTRAL'){
          this.perfilRHCentral = p;
        } else if (p.descricao == 'RH_AGENTE'){
          this.perfilAgenteRH = p;
        } else if (p.descricao == 'ATIVO'){
          this.perfilAgentePublico = p;
        }
      })
    }

    public getDate(data: string) {
      if(!data){
        return "";
      }
      let minhaData = data.split("/");
      let retorno = (minhaData[2] + '-' + minhaData[1] + '-' + minhaData[0]);
      return retorno;
    }

    validaData(){
      if(this.perfilRHCentral.dataInicioVigencia && this.perfilRHCentral.dataFimVigencia ){
        let erro: boolean = false;
      if (this.getDate(this.perfilRHCentral.dataInicioVigencia) > this.getDate(this.perfilRHCentral.dataFimVigencia)){
            this.toasty.error('Data de Inicio não pode ser maior que a Data Final');
            this.perfilRHCentral.dataInicioVigencia = null;
            this.perfilRHCentral.dataFimVigencia = null;
            erro = true;
        } 
      }
    }

    
    validaData2(){
      if(this.perfilAgenteRH.dataInicioVigencia && this.perfilAgenteRH.dataFimVigencia ){
        let erro: boolean = false;
      if (this.getDate(this.perfilAgenteRH.dataInicioVigencia) > this.getDate(this.perfilAgenteRH.dataFimVigencia)){
            this.toasty.error('Data de Inicio não pode ser maior que a Data Final');
            this.perfilAgenteRH.dataInicioVigencia = null;
            this.perfilAgenteRH.dataFimVigencia = null;
            erro = true;
        } 
      }
    }
    validaData3(){
      if(this.perfilAgentePublico.dataInicioVigencia && this.perfilAgentePublico.dataFimVigencia ){
        let erro: boolean = false;
      if (this.getDate(this.perfilAgentePublico.dataInicioVigencia) > this.getDate(this.perfilAgentePublico.dataFimVigencia)){
            this.toasty.error('Data de Inicio não pode ser maior que a Data Final');
            this.perfilAgentePublico.dataInicioVigencia = null;
            this.perfilAgentePublico.dataFimVigencia = null;
            erro = true;
        } 
      }
    }

    salvar(){
     this.cadastroVigenciasService.salvar(this.perfis)
        .then(() => {
          this.toasty.success('Cadastro de vigencia efetuado com sucesso');
          this.ngOnInit();
        })
        .catch(erro => this.errorHandler.handle(erro));
    }

}
