import { ToastyService } from "ng2-toasty";
import { Component, OnInit } from "@angular/core";

import { AuthService } from "./../auth.service";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["login-form.component.css"],
})
export class LoginFormComponent {
  isLoad: boolean;

  constructor(
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private toasty: ToastyService
  ) {}

  ngOnInit() {
    // this.show_logoTime();
  }


  login(usuario: string, senha: string) {
    this.isLoad = true;
   //this.auth
    //  .login(usuario, senha)
    const senhaEncoded = encodeURIComponent(senha);
    this.auth.login(usuario, senhaEncoded)
      .then(() => {
        this.router.navigate(["/boas-vindas"]);
        this.isLoad = false;
      })
      .catch((erro) => {
        if (erro.status == 500) {
          this.toasty.error("Falha ao realizar login");
        } else {
          this.errorHandler.handle(erro);
        }
        this.isLoad = false;
      });
  }
  associar() {
    this.router.navigate(["/faq"]);
  }
}
