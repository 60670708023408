import { CadastroBannerService } from './cadastro-banner.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Banner } from '../model/Banner';
import { ToastyService } from 'ng2-toasty';
import { ErrorHandlerService } from '../core/error-handler.service';
import { ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { JsonMessage } from '../model/JsonMessage';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';


@Component({
  selector: 'app-cadastro-banner',
  templateUrl: './cadastro-banner.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['cadastro-banner.component.css'],

  animations: [
    trigger('animation', [
      state('visible', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('void => *', [
        style({ transform: 'translateX(50%)', opacity: 0 }),
        animate('300ms ease-out')
      ]),
      transition('* => void', [
        animate(('250ms ease-in'), style({
          height: 0,
          opacity: 0,
          transform: 'translateX(50%)'
        }))
      ])
    ])
  ],

})
export class CadastroBannerComponent implements OnInit {

  @ViewChild('tabela') grid;
  banner: Banner;
  jsonMessage: JsonMessage = new JsonMessage();
  bannerForm: FormGroup;
  bannerList: any;

  // tslint:disable-next-line:no-shadowed-variable
  constructor(private cadastroBannerService: CadastroBannerService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private fb: FormBuilder) {

  }

  ngOnInit() {
    this.banner = new Banner();
    this.bannerForm = this.fb.group({
      banners: this.fb.array([this.fb.group({ id: '', urlBanner: '' })])
    });
    this.getAllData();
  }

  async getAllData() {
    this.bannerList = await this.pesquisar();
    this.carregaTela();
  }

  get banners() {
    return this.bannerForm.get('banners') as FormArray;
  }

  // hide_logo() {
  //   var el = document.getElementsByClassName('image')[0];

  //   el.setAttribute("style", "background: none;");
  // }

  salvar(event, uploadBanner) {
    if (this.banner.urlBanner != null && this.banner.urlBanner != '') {
      this.cadastroBannerService.salvarAnexo(event, this.banner.urlBanner, 'S')
        .then(resultado => {
          this.banner.id = resultado.idBanner;
          this.toasty.success('Banner default salvo com sucesso!');
        })
        .catch(erro => this.errorHandler.handle(erro));
      uploadBanner.clear();
    } else {
      this.toasty.error('Link do Banner Obrigatorio!');
    }

  }

  salvarBanner(event, uploadBanner, index) {
    let b = this.bannerForm.get('banners') as FormArray

    if (b.at(index).value.urlBanner!= null && b.at(index).value.urlBanner != '') {
    this.cadastroBannerService.salvarAnexo(event, b.at(index).value.urlBanner, 'N')
      .then(resultado => {
        b.at(index).setValue({ id: resultado.idBanner, urlBanner: b.at(index).value.urlBanner });
        this.toasty.success('Banner salvo com sucesso!');
      })
      .catch(erro => this.errorHandler.handle(erro));
    uploadBanner.clear();
    } else {
      this.toasty.error('Link do Banner Obrigatorio!');
    }
  }

  addBanners() {
    this.banners.push(this.fb.group({ id: '', urlBanner: '' }));
  }

  deleteBanners(index) {
    let b = this.bannerForm.get('banners') as FormArray
    if (b.at(index).value.id != null && b.at(index).value.id != '') {
      this.cadastroBannerService.deletar(b.at(index).value.id);
      this.toasty.success('Banner Deletado com sucesso!');
    }
    this.banners.removeAt(index);
  }

  pesquisar() {
    let listBanner: any;

    listBanner = this.cadastroBannerService.pesquisar().toPromise();

    return listBanner;
  }

  carregaTela() {
    let b = this.bannerForm.get('banners') as FormArray
    let tamanho = b.length;
    if (this.bannerList != null && this.bannerList != undefined && this.bannerList.length > 0) {
      this.bannerList.forEach(p => {
        if (p.padrao == 'S') {
          this.banner.id = p.id;
          this.banner.urlBanner = p.urlBanner;
        } else {
          if (tamanho == 2) {
            this.bannerForm = this.fb.group({
              banners: this.fb.array([this.fb.group({ id: p.id, urlBanner: p.urlBanner })])
            });
          } else {
            this.banners.push(this.fb.group({ id: p.id, urlBanner: p.urlBanner }));
          }
        }
        tamanho = tamanho + 1;
      })
    } else {
      this.bannerForm = this.fb.group({
        banners: this.fb.array([this.fb.group({ id: '', urlBanner: '' })])
      });
    }
    this.banners.push(this.fb.group({ id: '', urlBanner: '' }));
  }

  cadastrarCampanha(event, uploadCampanha) {
    let b = this.bannerForm.get('banners') as FormArray

    this.cadastroBannerService.cadastrarCampanha(event)
      .then(() => {
        this.toasty.success('Campanha salva com sucesso!');
      })
    uploadCampanha.clear();
  }
}


