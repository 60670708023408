import { RecadastramentoHttp } from './../seguranca/recadastramentoHttp';
import { JsonMessage } from './../model/JsonMessage';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Banner } from '../model/Banner';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CadastroBannerService {

  cadastroBannerUrl: string;

  constructor(private http: RecadastramentoHttp) {

    this.cadastroBannerUrl = `${environment.apiUrl}/banner`;

  }

  salvarAnexo(event, url_banner: string, padrao: string): Promise<JsonMessage> {
    const formData: FormData = new FormData();

    for (let file of event.files) {
      formData.append('anexo', file, file.name.toLowerCase());
    }

    formData.append('urlBanner', url_banner);
    formData.append('padrao', padrao);

    return this.http.post<JsonMessage>(`${this.cadastroBannerUrl}/anexo`, formData)
      .toPromise();
  }

   pesquisar() {
  
    return this.http.get<any>(`${this.cadastroBannerUrl}/listar`);
  }  
  
  async cadastrarCampanha(event): Promise<void> {
    const formData: FormData = new FormData();
    for (let file of event.files) {
      formData.append('anexo', file, file.name.toLowerCase());
    }  
    
    await this.http.post(this.cadastroBannerUrl + '/campanha/',  formData )
      .toPromise();  
  }

  async deletar(id: number): Promise<any> {
    await this.http.delete(`${this.cadastroBannerUrl}/` + id)
      .toPromise();
    return '';
  }
}



