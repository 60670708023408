import { Perfil } from './Perfil';

export class UsuarioFilter {
  id: number;
  nome: string;
  login: string;
  cpf: string;
  perfis: Perfil[];
  status: string;
  matricula01: string;
  matricula02: string;
  rhMaster: boolean;
  rhCentral: boolean;
  agenteRh: boolean;
  perfil: Perfil;
  page = 0;
  itensPorPagina = 50;
  isServidor: string;
  termo: string;
}
