export class TituloEleitoral {
  id: number;
  docTitEleitorFrente: string;
  docTitEleitorVerso: string;
  zonaEleitoral: string;
  secao: string;
  uf: string;
  numTitulo: string;
  imgTituloEleitoralFrente: string;
  imgTituloEleitoralVerso: string;
}
