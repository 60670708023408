export class Pageable {
  page: number;
  size: number;
  total: number;

  constructor(page, size) {
    this.page = page;
    this.size = size;
  }

}
