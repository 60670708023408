import { RegistroGeralDependente } from "./RegistroGeralDependente";
import { CertidaoDependente } from "./CertidaoDependente";
import { EnderecoDependente } from "./EnderecoDependente";

export class Dependente {
  id: number;
  origemDados: string;
  empresa: string;
  tipoDepend: string;
  cpf: string;
  imgDependenteCpfFrente: string;
  imgDependenteCpfVerso: string;

  cpfAtualizado: string;
  cpfTitular: string;
  docCpfAtualizado: string;

  docCpfFrente: string;
  docCpfVerso: string;
  imgDepCpfFrente: string;
  imgDepCpfVerso: string;

  nome: string;

  docNomeAttFrente: string;
  docNomeAttVerso: string;
  imgDepNomeAttFrente: string;
  imgDepNomeAttVerso: string;

  sexo: string;
  docSexoAttFrente: string;
  docSexoAttVerso: string;
  imgDepSexoAttFrente: string;
  imgDepSexoAttVerso: string;


  // DEFICIENTE
  defFisica: string;
  docDeficienciaFisicaFrente: string;
  docDeficienciaFisicaVerso: string;
  imgDefFisicaFrente: string;
  imgDefFisicaVerso: string;

  defVisual: string;
  docDeficienciaVisualFrente: string;
  docDeficienciaVisualVerso: string;
  imgDeficienciaVisualFrente: string;
  imgDeficienciaVisualVerso: string;

  defAuditiva: string;
  docDeficienciaAuditivaFrente: string;
  docDeficienciaAuditivaVerso: string;
  imgDeficienciaAuditivaFrente: string;
  imgDeficienciaAuditivaVerso: string;

  defMental: string;
  docDeficienciaMentalFrente: string;
  docDeficienciaMentalVerso: string;
  imgDeficienciaMentalFrente: string;
  imgDeficienciaMentalVerso: string;

  defIntelectual: string;
  docDeficienciaIntelectualFrente: string;
  docDeficienciaIntelectualVerso: string;
  imgDeficienciaIntelectualFrente: string;
  imgDeficienciaIntelectualVerso: string;
  // DEFICIENTE

  dataNasc: string;
  estadoCivil: string;
  numeroDependente: number;
  planoPriv: string;
  irpf: string;
  operacao: string;
  regGeralDependente = new RegistroGeralDependente();
  certidao = new CertidaoDependente();
  endDependente = new EnderecoDependente();
  editar: boolean = false;
  incapaz: string;
}
