export class CertidaoDependente {
  estadoCivil: string;
  tipoCertid: string;
  numCertid: string;
  docEstCivilFrente: string;
  docEstCivilVerso: string;
  livro: string;
  folha: string;
  cartorio: string;
  uf: string;
  cidade: string;
}
