import { Aposentadoria } from "./Aposentadoria";
import { CarteiraDeHabitacao } from "./CarteiraDeHabitacao";
import { CarteiraProfissional } from "./CarteiraProfissional";
import { Certidao } from "./Certidao";
import { DadosGeraisAprovacao } from "./DadosGeraisAprovacao";
import { Dependente } from "./Dependente";
import { Endereco } from "./Endereco";
import { EndExtrangeiro } from "./EndExtrangeiro";
import { Formacao } from "./Formacao";
import { IdentidadeProfissional } from "./IdentidadeProfissional";
import { InformacoesAdicionais } from "./InformacoesAdicionais";
import { RegistroGeral } from "./RegistroGeral";
import { RegNacionalExtrang } from "./RegNacionalExtrang";
import { Reservista } from "./Reservista";
import { Ric } from "./Ric";
import { TituloEleitoral } from "./TituloEleitoral";

export class DadosGeraisAprovacaoDTO1 {
  id: number;
  cpf: string;
  matricula01: string;
  cargoEfetivo01: string;
  lotacao01: string;
  status: number;
  ip: string;
  operacao: string;
  registroGeral: RegistroGeral;
  tituloEleitoral: TituloEleitoral;
  carteiraDeHabilitacao: CarteiraDeHabitacao;
  carteiraProfissional: CarteiraProfissional;
  reservista: Reservista;
  identidadeProfissional: IdentidadeProfissional;
  certidao: Certidao;
  endereco: Endereco;
  ric: Ric;
  regNacionalExtrang: RegNacionalExtrang;
  infoAdicionais: InformacoesAdicionais;
  endExtrangeiro: EndExtrangeiro;
  aposentadoria: Aposentadoria;
  dependentes: Dependente[] = [];
  formacoes: Formacao[] = [];
  cqc: boolean;

  mensagem: string;
  visualiza: boolean;

  constructor(dadosGeraisAprovacao: DadosGeraisAprovacao) {
    this.id = dadosGeraisAprovacao.id;
    this.cpf = dadosGeraisAprovacao.cpf;
    this.matricula01 = dadosGeraisAprovacao.matricula01;
    this.cargoEfetivo01 = dadosGeraisAprovacao.cargoEfetivo01;
    this.lotacao01 = dadosGeraisAprovacao.lotacao01;
    this.status = dadosGeraisAprovacao.status;
    this.operacao = dadosGeraisAprovacao.operacao;
    this.registroGeral = dadosGeraisAprovacao.registroGeral;

    if(this.registroGeral != null) {
      let rg = this.registroGeral;
      if(rg.rg == null && rg.uf == null && rg.emissao == null && rg.orgao == null) {
        this.registroGeral = null;
      } 
    }


    this.tituloEleitoral = dadosGeraisAprovacao.tituloEleitoral;
    if(this.tituloEleitoral != null) {
      let titu = this.tituloEleitoral;
      if(titu.uf == null 
        && titu.numTitulo == null
        && titu.secao == null
        && titu.zonaEleitoral == null
        ) {
          this.tituloEleitoral = null;
        }

    } 

    this.carteiraDeHabilitacao = dadosGeraisAprovacao.carteiraDeHabilitacao;
    this.carteiraProfissional = dadosGeraisAprovacao.carteiraProfissional;
    this.reservista = dadosGeraisAprovacao.reservista;
    this.identidadeProfissional = dadosGeraisAprovacao.identidadeProfissional;
    this.certidao = dadosGeraisAprovacao.certidao;
    this.endereco = dadosGeraisAprovacao.endereco;
    this.ric = dadosGeraisAprovacao.ric;
    this.regNacionalExtrang = dadosGeraisAprovacao.regNacionalExtrang;
    this.infoAdicionais = dadosGeraisAprovacao.infoAdicionais;
    this.endExtrangeiro = dadosGeraisAprovacao.endExtrangeiro;
    this.aposentadoria = dadosGeraisAprovacao.aposentadoria;
    this.dependentes = dadosGeraisAprovacao.dependentes;
    this.formacoes = dadosGeraisAprovacao.formacoes;
    this.cqc = dadosGeraisAprovacao.cqc;

    this.mensagem = dadosGeraisAprovacao.mensagem;
    this.visualiza = dadosGeraisAprovacao.visualiza;
    this.ip = dadosGeraisAprovacao.ip;
  }
}
