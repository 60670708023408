import { Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class CombosService {
  racaecor: SelectItem[];
  grauinstrucoes: SelectItem[];
  paises: SelectItem[];
  municipios: SelectItem[];
  lstMunicipios: SelectItem[];
  ufs: SelectItem[];
  estadosCivis: SelectItem[];
  idGenero: SelectItem[];
  grupoSanguineo: SelectItem[];
  tiposCertidao: SelectItem[];
  tiposCursos: SelectItem[];
  ptBr: any;
  tipoLogradouros: SelectItem[];
  tiposClassificacao: SelectItem[];
  tiposDependentes: SelectItem[];
  paisesExt: SelectItem[];
  categoriaCnh: SelectItem[];
  tipoSanguineo: SelectItem[];

  constructor() {
    this.tiposDependentes = [
      { label: "<<selecione>>", value: "" },
      { label: "Cônjuge", value: "01" },
      {
        label:
          "Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos ou possua Declaração de União Estável",
        value: "02",
      },
      { label: "Filho(a)", value: "03" },
      { label: "Enteado(a)", value: "14" },
      {
        label:
          "Enteado(a), universitário(a) ou cursando escola técnica de 2º grau ",
        value: "13",
      },
      {
        label:
          "Filho(a), universitário(a) ou cursando escola técnica de 2º grau",
        value: "04",
      },
      {
        label:
          "Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a) qual detenha a guarda judicial",
        value: "06",
      },
      {
        label:
          "Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, universitário(a) ou cursando escola técnica de 2° grau, do(a) qual detenha a guarda judicial",
        value: "07",
      },
      { label: "Pais, avós e bisavós", value: "09" },
      { label: "Menor pobre do qual detenha a guarda judicial", value: "10" },
      {
        label: "A pessoa absolutamente incapaz, da qual seja tutor ou curador",
        value: "11",
      },
      { label: "Ex-cônjuge", value: "12" },
      { label: "Agregado/Outros", value: "99" },
    ];

    //Correção do Ticket 160
    this.tipoSanguineo = [
      { label: "<<selecione>>", value: "" },
      { label: "A+", value: "A+" },
      { label: "A-", value: "A-" },
      { label: "B+", value: "B+" },
      { label: "B-", value: "B-" },
      { label: "AB+", value: "AB+" },
      { label: "AB-", value: "AB-" },
      { label: "O+", value: "O+" },
      { label: "O-", value: "O-" },
    ];

    this.tiposCertidao = [
      { label: "<<selecione>>", value: "" },
      { label: "Casamento", value: "C" },
      { label: "Nascimento", value: "N" },
      { label: "Averbação", value: "A" },
    ];
    this.tiposClassificacao = [
      { label: "<<selecione>>", value: "" },
      { label: "Refugiado", value: "1" },
      { label: "Solicitante de Refúgio", value: "2" },
      {
        label: "Permanência no Brasil em razão de reunião familiar",
        value: "3",
      },
      { label: "Beneficiado pelo acordo entre países do Mercosul", value: "4" },
      {
        label:
          "Dependente de agente diplomático e/ou consular de países que mantêm acordo de reciprocidade para o exercício de atividade remunerada no Brasil",
        value: "5",
      },
      {
        label:
          "Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brasil e a República Portuguesa",
        value: "6",
      },
      { label: "Outra condição", value: "7" },
    ];

    this.tipoLogradouros = [
      { label: "<<selecione>>", value: "" },
      { value: "A ", label: "Área " },
      { value: "AC ", label: "Acesso " },
      { value: "ACA ", label: "Acampamento " },
      { value: "ACL ", label: "Acesso Local " },
      { value: "AD ", label: "Adro " },
      { value: "AE ", label: "Área Especial " },
      { value: "AER ", label: "Aeroporto " },
      { value: "AL ", label: "Alameda " },
      { value: "AMD ", label: "Avenida Marginal Direita " },
      { value: "AME ", label: "Avenida Marginal Esquerda " },
      { value: "AN ", label: "Anel Viário " },
      { value: "ANT ", label: "Antiga Estrada " },
      { value: "ART ", label: "Artéria " },
      { value: "AT ", label: "Alto " },
      { value: "ATL ", label: "Atalho " },
      { value: "A V ", label: "Área Verde " },
      { value: "AV ", label: "Avenida " },
      { value: "AVC ", label: "Avenida Contorno " },
      { value: "AVM ", label: "Avenida Marginal " },
      { value: "AVV ", label: "Avenida Velha " },
      { value: "BAL ", label: "Balneário " },
      { value: "BC ", label: "Beco " },
      { value: "BCO ", label: "Buraco " },
      { value: "BEL ", label: "Belvedere " },
      { value: "BL ", label: "Bloco " },
      { value: "BLO ", label: "Balão " },
      { value: "BLS ", label: "Blocos " },
      { value: "BLV ", label: "Bulevar " },
      { value: "BSQ ", label: "Bosque " },
      { value: "BVD ", label: "Boulevard " },
      { value: "BX ", label: "Baixa " },
      { value: "C ", label: "Cais " },
      { value: "CAL ", label: "Calçada " },
      { value: "CAM ", label: "Caminho " },
      { value: "CAN ", label: "Canal " },
      { value: "CH ", label: "Chácara " },
      { value: "CHA ", label: "Chapadão " },
      { value: "CIC ", label: "Ciclovia " },
      { value: "CIR ", label: "Circular " },
      { value: "CJ ", label: "Conjunto " },
      { value: "CJM ", label: "Conjunto Mutirão " },
      { value: "CMP ", label: "Complexo Viário " },
      { value: "COL ", label: "Colônia " },
      { value: "COM ", label: "Comunidade " },
      { value: "CON ", label: "Condomínio " },
      { value: "COR ", label: "Corredor " },
      { value: "CPO ", label: "Campo " },
      { value: "CRG ", label: "Córrego " },
      { value: "CTN ", label: "Contorno " },
      { value: "DSC ", label: "Descida " },
      { value: "DSV ", label: "Desvio " },
      { value: "DT ", label: "Distrito " },
      { value: "EB ", label: "Entre Bloco " },
      { value: "EIM ", label: "Estrada Intermunicipal " },
      { value: "ENS ", label: "Enseada " },
      { value: "ENT ", label: "Entrada Particular " },
      { value: "EQ ", label: "Entre Quadra " },
      { value: "ESC ", label: "Escada " },
      { value: "ESD ", label: "Escadaria " },
      { value: "ESE ", label: "Estrada Estadual " },
      { value: "ESI ", label: "Estrada Vicinal " },
      { value: "ESL ", label: "Estrada de Ligação " },
      { value: "ESM ", label: "Estrada Municipal " },
      { value: "ESP ", label: "Esplanada " },
      { value: "ESS ", label: "Estrada de Servidão " },
      { value: "EST ", label: "Estrada " },
      { value: "ESV ", label: "Estrada Velha " },
      { value: "ETA ", label: "Estrada Antiga " },
      { value: "ETC ", label: "Estação " },
      { value: "ETD ", label: "Estádio " },
      { value: "ETN ", label: "Estância " },
      { value: "ETP ", label: "Estrada Particular " },
      { value: "ETT ", label: "Estacionamento " },
      { value: "EVA ", label: "Evangélica " },
      { value: "EVD ", label: "Elevada " },
      { value: "EX ", label: "Eixo Industrial " },
      { value: "FAV ", label: "Favela " },
      { value: "FAZ ", label: "Fazenda " },
      { value: "FER ", label: "Ferrovia " },
      { value: "FNT ", label: "Fonte " },
      { value: "FRA ", label: "Feira " },
      { value: "FTE ", label: "Forte " },
      { value: "GAL ", label: "Galeria " },
      { value: "GJA ", label: "Granja " },
      { value: "HAB ", label: "Núcleo Habitacional " },
      { value: "IA ", label: "Ilha " },
      { value: "IND ", label: "Indeterminado " },
      { value: "IOA ", label: "Ilhota " },
      { value: "JD ", label: "Jardim " },
      { value: "JDE ", label: "Jardinete " },
      { value: "LD ", label: "Ladeira " },
      { value: "LGA ", label: "Lagoa " },
      { value: "LGO ", label: "Lago " },
      { value: "LOT ", label: "Loteamento " },
      { value: "LRG ", label: "Largo " },
      { value: "LT ", label: "Lote " },
      { value: "MER ", label: "Mercado " },
      { value: "MNA ", label: "Marina " },
      { value: "MOD ", label: "Modulo " },
      { value: "MRG ", label: "Projeção " },
      { value: "MRO ", label: "Morro " },
      { value: "MTE ", label: "Monte " },
      { value: "NUC ", label: "Núcleo " },
      { value: "NUR ", label: "Núcleo Rural " },
      { value: "O ", label: "Outros " },
      { value: "OUT ", label: "Outeiro " },
      { value: "PAR ", label: "Paralela " },
      { value: "PAS ", label: "Passeio " },
      { value: "PAT ", label: "Pátio " },
      { value: "PC ", label: "Praça " },
      { value: "PCE ", label: "Praça de Esportes " },
      { value: "PDA ", label: "Parada " },
      { value: "PDO ", label: "Paradouro " },
      { value: "PNT ", label: "Ponta " },
      { value: "PR ", label: "Praia " },
      { value: "PRL ", label: "Prolongamento " },
      { value: "PRM ", label: "Parque Municipal " },
      { value: "PRQ ", label: "Parque " },
      { value: "PRR ", label: "Parque Residencial " },
      { value: "PSA ", label: "Passarela " },
      { value: "PSG ", label: "Passagem " },
      { value: "PSP ", label: "Passagem de Pedestre " },
      { value: "PSS ", label: "Passagem Subterrânea " },
      { value: "PTE ", label: "Ponte " },
      { value: "PTO ", label: "Porto " },
      { value: "Q ", label: "Quadra " },
      { value: "QTA ", label: "Quinta " },
      { value: "QTS ", label: "Quintas " },
      { value: "R ", label: "Rua " },
      { value: "R I ", label: "Rua Integração " },
      { value: "R L ", label: "Rua de Ligação " },
      { value: "R P ", label: "Rua Particular " },
      { value: "R V ", label: "Rua Velha " },
      { value: "RAM ", label: "Ramal " },
      { value: "RCR ", label: "Recreio " },
      { value: "REC ", label: "Recanto " },
      { value: "RER ", label: "Retiro " },
      { value: "RES ", label: "Residencial " },
      { value: "RET ", label: "Reta " },
      { value: "RLA ", label: "Ruela " },
      { value: "RMP ", label: "Rampa " },
      { value: "ROA ", label: "Rodo Anel " },
      { value: "ROD ", label: "Rodovia " },
      { value: "ROT ", label: "Rotula " },
      { value: "RPE ", label: "Rua de Pedestre " },
      { value: "RPR ", label: "Margem " },
      { value: "RTN ", label: "Retorno " },
      { value: "RTT ", label: "Rotatória " },
      { value: "SEG ", label: "Segunda Avenida " },
      { value: "SIT ", label: "Sitio " },
      { value: "SRV ", label: "Servidão " },
      { value: "ST ", label: "Setor " },
      { value: "SUB ", label: "Subida " },
      { value: "TCH ", label: "Trincheira " },
      { value: "TER ", label: "Terminal " },
      { value: "TR ", label: "Trecho " },
      { value: "TRV ", label: "Trevo " },
      { value: "TUN ", label: "Túnel " },
      { value: "TV ", label: "Travessa " },
      { value: "TVP ", label: "Travessa Particular " },
      { value: "TVV ", label: "Travessa Velha " },
      { value: "UNI ", label: "Unidade " },
      { value: "V ", label: "Via " },
      { value: "V C ", label: "Via Coletora " },
      { value: "V L ", label: "Via Local " },
      { value: "VAC ", label: "Via de Acesso " },
      { value: "VAL ", label: "Vala " },
      { value: "VCO ", label: "Via Costeira " },
      { value: "VD ", label: "Viaduto " },
      { value: "V-E ", label: "Via Expressa " },
      { value: "VER ", label: "Vereda " },
      { value: "VEV ", label: "Via Elevado " },
      { value: "VL ", label: "Vila " },
      { value: "VLA ", label: "Viela " },
      { value: "VLE ", label: "Vale " },
      { value: "VLT ", label: "Via Litorânea " },
      { value: "VPE ", label: "Via de Pedestre " },
      { value: "VRT ", label: "Variante " },
      { value: "ZIG ", label: "Zigue-Zague " },
    ];

    this.racaecor = [
      { label: "<<selecione>>", value: "" },
      { label: "Branca", value: "1" },
      { label: "Preta", value: "2" },
      { label: "Parda", value: "3" },
      { label: "Amarela", value: "4" },
      { label: "Indígena", value: "5" },
    ];

    this.categoriaCnh = [
      { label: "<<selecione>>", value: "" },
      { label: "A", value: "A" },
      { label: "B", value: "B" },
      { label: "C", value: "C" },
      { label: "D", value: "D" },
      { label: "E", value: "E" },
      { label: "AB", value: "AB" },
      { label: "AC", value: "AC" },
      { label: "AD", value: "AD" },
      { label: "AE", value: "AE" },
    ];

    this.grauinstrucoes = [
      { label: "<<selecione>>", value: "" },
      {
        value: "01",
        label:
          "Analfabeto, inclusive o que, embora tenha recebido instrução, não se alfabetizou.",
      },
      {
        value: "02",
        label:
          "Até o 5º ano incompleto do Ensino fundamental (antiga 4ª série) ou que se tenha alfabetizado sem ter frequentado escola regular.",
      },
      { value: "03", label: "5º ano completo do Ensino Fundamental." },
      {
        value: "04",
        label:
          "Do 6º ao 9º ano do Ensino Fundamental Incompleto (antiga 5ª a 8ª série).",
      },
      { value: "05", label: "Ensino Fundamental Completo." },
      { value: "06", label: "Ensino Médio Incompleto." },
      { value: "07", label: "Ensino Médio Completo." },
      { value: "08", label: "Educação Superior Incompleto." },
      { value: "09", label: "Educação Superior Completo." },
      { value: "10", label: "Pós-Graduação completa." },
      { value: "11", label: "Mestrado completo" },
      { value: "12", label: "Doutorado completo" },
    ];

    this.ptBr = {
      firstDayOfWeek: 0,
      dayNames: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sabado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Marco",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Clear",
      dateFormat: "mm/dd/yy",
      locale: "pt-BR",
    };
    this.paises = [
      { label: "<<selecione>>", value: "" },
      { value: "008", label: "Abu	Dhabi	" },
      { value: "013", label: "Afeganistao	" },
      { value: "756", label: "Africa	Do	Sul	" },
      { value: "017", label: "Albania,	Republica	Da	" },
      { value: "020", label: "Alboran-Perejil,Ilhas	" },
      { value: "023", label: "Alemanha	" },
      { value: "025", label: "Alemanha,	Republica	Democratica	" },
      { value: "037", label: "Andorra	" },
      { value: "040", label: "Angola	" },
      { value: "041", label: "Anguilla	" },
      { value: "043", label: "Antigua	E	Barbuda	" },
      { value: "047", label: "Antilhas	Holandesas	" },
      { value: "053", label: "Arabia	Saudita	" },
      { value: "059", label: "Argelia	" },
      { value: "063", label: "Argentina	" },
      { value: "064", label: "Armenia,	Republica	Da	" },
      { value: "065", label: "Aruba	" },
      { value: "069", label: "Australia	" },
      { value: "072", label: "Austria	" },
      { value: "073", label: "Azerbaijao,	Republica	Do	" },
      { value: "077", label: "Bahamas,	Ilhas	" },
      { value: "080", label: "Bahrein,	Ilhas	" },
      { value: "081", label: "Bangladesh	" },
      { value: "083", label: "Barbados	" },
      { value: "085", label: "Belarus,	Republica	Da	" },
      { value: "087", label: "Belgica	" },
      { value: "088", label: "Belize	" },
      { value: "229", label: "Benin	" },
      { value: "090", label: "Bermudas	" },
      { value: "097", label: "Bolivia,	Estado	Plurinacional	Da	" },
      { value: "098", label: "Bosnia-Herzegovina	(REPUBLICA	Da)	" },
      { value: "101", label: "Botsuana	" },
      { value: "105", label: "Brasil	" },
      { value: "108", label: "Brunei	" },
      { value: "111", label: "Bulgaria,	Republica	Da	" },
      { value: "031", label: "Burkina	Faso	" },
      { value: "115", label: "Burundi	" },
      { value: "119", label: "Butao	" },
      { value: "127", label: "Cabo	Verde,	Republica	De	" },
      { value: "131", label: "Cachemira	" },
      { value: "145", label: "Camaroes	" },
      { value: "141", label: "Camboja	" },
      { value: "149", label: "Canada	" },
      { value: "152", label: "Canal,Ilhas	" },
      { value: "151", label: "Canarias,	Ilhas	" },
      { value: "154", label: "Catar	" },
      { value: "137", label: "Cayman,	Ilhas	" },
      { value: "153", label: "Cazaquistao,	Republica	Do	" },
      { value: "788", label: "Chade	" },
      { value: "158", label: "Chile	" },
      { value: "160", label: "China,	Republica	Popular	" },
      { value: "163", label: "Chipre	" },
      { value: "511", label: "Christmas,Ilha	(NAVIDAD)	" },
      { value: "741", label: "Cingapura	" },
      { value: "165", label: "Cocos(Keeling),Ilhas	" },
      { value: "169", label: "Colombia	" },
      { value: "173", label: "Comores,	Ilhas	" },
      { value: "177", label: "Congo	" },
      { value: "888", label: "Congo,	Republica	Democratica	Do	" },
      { value: "183", label: "Cook,	Ilhas	" },
      { value: "187", label: "Coreia	(DO	Norte),	Rep.Pop.Democratica	" },
      { value: "190", label: "Coreia	(DO	Sul),	Republica	Da	" },
      { value: "193", label: "Costa	Do	Marfim	" },
      { value: "196", label: "Costa	Rica	" },
      { value: "198", label: "Coveite	" },
      { value: "195", label: "Croacia	(REPUBLICA	Da)	" },
      { value: "199", label: "Cuba	" },
      { value: "232", label: "Dinamarca	" },
      { value: "009", label: "Dirce	" },
      { value: "783", label: "Djibuti	" },
      { value: "235", label: "Dominica,Ilha	" },
      { value: "237", label: "Dubai	" },
      { value: "240", label: "Egito	" },
      { value: "687", label: "El	Salvador	" },
      { value: "244", label: "Emirados	Arabes	Unidos	" },
      { value: "239", label: "Equador	" },
      { value: "243", label: "Eritreia	" },
      { value: "247", label: "Eslovaca,	Republica	" },
      { value: "246", label: "Eslovenia,	Republica	Da	" },
      { value: "245", label: "Espanha	" },
      { value: "249", label: "Estados	Unidos	" },
      { value: "251", label: "Estonia,	Republica	Da	" },
      { value: "253", label: "Etiopia	" },
      { value: "255", label: "Falkland	(ILHAS	Malvinas)	" },
      { value: "259", label: "Feroe,	Ilhas	" },
      { value: "263", label: "Fezzan	" },
      { value: "870", label: "Fiji	" },
      { value: "267", label: "Filipinas	" },
      { value: "271", label: "Finlandia	" },
      { value: "161", label: "Formosa	(TAIWAN)	" },
      { value: "275", label: "Franca	" },
      { value: "106", label: "Fretado	P/Brasil	" },
      { value: "281", label: "Gabao	" },
      { value: "285", label: "Gambia	" },
      { value: "289", label: "Gana	" },
      { value: "291", label: "Georgia,	Republica	Da	" },
      { value: "293", label: "Gibraltar	" },
      { value: "297", label: "Granada	" },
      { value: "301", label: "Grecia	" },
      { value: "305", label: "Groenlandia	" },
      { value: "309", label: "Guadalupe	" },
      { value: "313", label: "Guam	" },
      { value: "317", label: "Guatemala	" },
      { value: "325", label: "Guiana	Francesa	" },
      { value: "337", label: "Guiana	" },
      { value: "329", label: "Guine	" },
      { value: "334", label: "Guine-Bissau	" },
      { value: "331", label: "Guine-Equatorial	" },
      { value: "341", label: "Haiti	" },
      { value: "345", label: "Honduras	" },
      { value: "351", label: "Hong	Kong	" },
      { value: "355", label: "Hungria,	Republica	Da	" },
      { value: "358", label: "Iemem	Do	Sul	" },
      { value: "357", label: "Iemen	" },
      { value: "452", label: "Ilha	Da	Madeira	" },
      { value: "361", label: "India	" },
      { value: "365", label: "Indonesia	" },
      { value: "367", label: "Inglaterra	" },
      { value: "100", label: "Int.Z.F.Manaus	" },
      { value: "372", label: "Ira,	Republica	Islamica	Do	" },
      { value: "369", label: "Iraque	" },
      { value: "375", label: "Irlanda	" },
      { value: "379", label: "Islandia	" },
      { value: "383", label: "Israel	" },
      { value: "386", label: "Italia	" },
      { value: "391", label: "Jamaica	" },
      { value: "395", label: "Jammu	" },
      { value: "399", label: "Japao	" },
      { value: "150", label: "Jersey,	Ilha	Do	Canal	" },
      { value: "396", label: "Johnston,	Ilhas	" },
      { value: "403", label: "Jordania	" },
      { value: "411", label: "Kiribati	" },
      { value: "420", label: "Laos,	Rep.Pop.Democr.Do	" },
      { value: "423", label: "Lebuan,Ilhas	" },
      { value: "426", label: "Lesoto	" },
      { value: "427", label: "Letonia,	Republica	Da	" },
      { value: "431", label: "Libano	" },
      { value: "434", label: "Liberia	" },
      { value: "438", label: "Libia	" },
      { value: "440", label: "Liechtenstein	" },
      { value: "442", label: "Lituania,	Republica	Da	" },
      { value: "445", label: "Luxemburgo	" },
      { value: "447", label: "Macau	" },
      { value: "449", label: "Macedonia,	Ant.Rep.Iugoslava	" },
      { value: "450", label: "Madagascar	" },
      { value: "455", label: "Malasia	" },
      { value: "458", label: "Malavi	" },
      { value: "461", label: "Maldivas	" },
      { value: "464", label: "Mali	" },
      { value: "467", label: "Malta	" },
      { value: "359", label: "Man,	Ilha	De	" },
      { value: "472", label: "Marianas	Do	Norte	" },
      { value: "474", label: "Marrocos	" },
      { value: "476", label: "Marshall,Ilhas	" },
      { value: "477", label: "Martinica	" },
      { value: "485", label: "Mauricio	" },
      { value: "488", label: "Mauritania	" },
      { value: "493", label: "Mexico	" },
      { value: "093", label: "Mianmar	(BIRMANIA)	" },
      { value: "499", label: "Micronesia	" },
      { value: "490", label: "Midway,	Ilhas	" },
      { value: "505", label: "Mocambique	" },
      { value: "494", label: "Moldavia,	Republica	Da	" },
      { value: "495", label: "Monaco	" },
      { value: "497", label: "Mongolia	" },
      { value: "501", label: "Montserrat,Ilhas	" },
      { value: "507", label: "Namibia	" },
      { value: "508", label: "Nauru	" },
      { value: "517", label: "Nepal	" },
      { value: "521", label: "Nicaragua	" },
      { value: "525", label: "Niger	" },
      { value: "528", label: "Nigeria	" },
      { value: "531", label: "Niue,Ilha	" },
      { value: "535", label: "Norfolk,Ilha	" },
      { value: "538", label: "Noruega	" },
      { value: "542", label: "Nova	Caledonia	" },
      { value: "548", label: "Nova	Zelandia	" },
      { value: "556", label: "Oma	" },
      { value: "563", label: "Pacifico,Ilhas	Do	(ADMINISTRACAO	Dos	Eua)	" },
      { value: "566", label: "Pacifico,Ilhas	Do	(POSSESSAO	Dos	Eua)	" },
      { value: "569", label: "Pacifico,Ilhas	Do	(TERRITORIO	Em	Fideicomisso	Dos	" },
      { value: "573", label: "Paises	Baixos	(HOLANDA)	" },
      { value: "575", label: "Palau	" },
      { value: "578", label: "Palestina	" },
      { value: "580", label: "Panama	" },
      { value: "545", label: "Papua	Nova	Guine	" },
      { value: "583", label: "Papua	Nova	Guiné	" },
      { value: "576", label: "Paquistao	" },
      { value: "586", label: "Paraguai	" },
      { value: "589", label: "Peru	" },
      { value: "593", label: "Pitcairn,Ilha	" },
      { value: "599", label: "Polinesia	Francesa	" },
      { value: "603", label: "Polonia,	Republica	Da	" },
      { value: "611", label: "Porto	Rico	" },
      { value: "607", label: "Portugal	" },
      { value: "623", label: "Quenia	" },
      { value: "625", label: "Quirguiz,	Republica	" },
      { value: "628", label: "Reino	Unido	" },
      { value: "640", label: "Republica	Centro-Africana	" },
      { value: "647", label: "Republica	Dominicana	" },
      { value: "660", label: "Reuniao,	Ilha	" },
      { value: "670", label: "Romenia	" },
      { value: "675", label: "Ruanda	" },
      { value: "676", label: "Russia,	Federacao	Da	" },
      { value: "685", label: "Saara	Ocidental	" },
      { value: "678", label: "Saint	Kitts	E	Nevis	" },
      { value: "677", label: "Salomao,	Ilhas	" },
      { value: "690", label: "Samoa	" },
      { value: "691", label: "Samoa	Americana	" },
      { value: "697", label: "San	Marino	" },
      { value: "710", label: "Santa	Helena	" },
      { value: "715", label: "Santa	Lucia	" },
      { value: "695", label: "Sao	Cristovao	E	Neves,Ilhas	" },
      { value: "700", label: "Sao	Pedro	E	Miquelon	" },
      { value: "705", label: "Sao	Vicente	E	Granadinas	" },
      { value: "720", label: "Sao	Tome	E	Principe,	Ilhas	" },
      { value: "728", label: "Senegal	" },
      { value: "735", label: "Serra	Leoa	" },
      { value: "388", label: "Servia	E	Montenegro	" },
      { value: "731", label: "Seychelles	" },
      { value: "738", label: "Sikkim	" },
      { value: "744", label: "Siria,	Republica	Arabe	Da	" },
      { value: "748", label: "Somalia	" },
      { value: "750", label: "Sri	Lanka	" },
      { value: "754", label: "Suazilandia	" },
      { value: "759", label: "Sudao	" },
      { value: "764", label: "Suecia	" },
      { value: "767", label: "Suica	" },
      { value: "770", label: "Suriname	" },
      { value: "772", label: "Tadjiquistao,	Republica	Do	" },
      { value: "776", label: "Tailandia	" },
      { value: "780", label: "Tanzania,	Rep.Unida	Da	" },
      { value: "791", label: "Tcheca,	Republica	" },
      { value: "790", label: "Tchecoslovaquia	" },
      { value: "782", label: "Territorio	Brit.Oc.Indico	" },
      { value: "785", label: "Territorio	da	Alta	Comissao	do	Pacifico	Ocidental	" },
      { value: "795", label: "Timor	Leste	" },
      { value: "800", label: "Togo	" },
      { value: "810", label: "Tonga	" },
      { value: "805", label: "Toquelau,Ilhas	" },
      { value: "815", label: "Trinidad	E	Tobago	" },
      { value: "820", label: "Tunisia	" },
      { value: "823", label: "Turcas	E	Caicos,Ilhas	" },
      { value: "824", label: "Turcomenistao,	Republica	Do	" },
      { value: "827", label: "Turquia	" },
      { value: "828", label: "Tuvalu	" },
      { value: "831", label: "Ucrania	" },
      { value: "833", label: "Uganda	" },
      { value: "840", label: "Uniao	Das	Republicas	Socialistas	Sovieticas	" },
      { value: "845", label: "Uruguai	" },
      { value: "847", label: "Uzbequistao,	Republica	Do	" },
      { value: "551", label: "Vanuatu	" },
      { value: "848", label: "Vaticano,	Est.Da	Cidade	Do	" },
      { value: "850", label: "Venezuela	" },
      { value: "858", label: "Vietna	" },
      { value: "855", label: "Vietname	Norte	" },
      { value: "863", label: "Virgens,Ilhas	(BRITANICAS)	" },
      { value: "866", label: "Virgens,Ilhas	(E.U.A.)	" },
      { value: "873", label: "Wake,	Ilha	" },
      { value: "875", label: "Wallis	E	Futuna,	Ilhas	" },
      { value: "890", label: "Zambia	" },
      { value: "665", label: "Zimbabue	" },
    ];

    this.paisesExt = [
      { label: "<<selecione>>", value: "" },
      { value: "008", label: "Abu	Dhabi	" },
      { value: "013", label: "Afeganistao	" },
      { value: "756", label: "Africa	Do	Sul	" },
      { value: "017", label: "Albania,	Republica	Da	" },
      { value: "020", label: "Alboran-Perejil,Ilhas	" },
      { value: "023", label: "Alemanha	" },
      { value: "025", label: "Alemanha,	Republica	Democratica	" },
      { value: "037", label: "Andorra	" },
      { value: "040", label: "Angola	" },
      { value: "041", label: "Anguilla	" },
      { value: "043", label: "Antigua	E	Barbuda	" },
      { value: "047", label: "Antilhas	Holandesas	" },
      { value: "053", label: "Arabia	Saudita	" },
      { value: "059", label: "Argelia	" },
      { value: "063", label: "Argentina	" },
      { value: "064", label: "Armenia,	Republica	Da	" },
      { value: "065", label: "Aruba	" },
      { value: "069", label: "Australia	" },
      { value: "072", label: "Austria	" },
      { value: "073", label: "Azerbaijao,	Republica	Do	" },
      { value: "077", label: "Bahamas,	Ilhas	" },
      { value: "080", label: "Bahrein,	Ilhas	" },
      { value: "081", label: "Bangladesh	" },
      { value: "083", label: "Barbados	" },
      { value: "085", label: "Belarus,	Republica	Da	" },
      { value: "087", label: "Belgica	" },
      { value: "088", label: "Belize	" },
      { value: "229", label: "Benin	" },
      { value: "090", label: "Bermudas	" },
      { value: "097", label: "Bolivia,	Estado	Plurinacional	Da	" },
      { value: "098", label: "Bosnia-Herzegovina	(REPUBLICA	Da)	" },
      { value: "101", label: "Botsuana	" },
      { value: "108", label: "Brunei	" },
      { value: "111", label: "Bulgaria,	Republica	Da	" },
      { value: "031", label: "Burkina	Faso	" },
      { value: "115", label: "Burundi	" },
      { value: "119", label: "Butao	" },
      { value: "127", label: "Cabo	Verde,	Republica	De	" },
      { value: "131", label: "Cachemira	" },
      { value: "145", label: "Camaroes	" },
      { value: "141", label: "Camboja	" },
      { value: "149", label: "Canada	" },
      { value: "152", label: "Canal,Ilhas	" },
      { value: "151", label: "Canarias,	Ilhas	" },
      { value: "154", label: "Catar	" },
      { value: "137", label: "Cayman,	Ilhas	" },
      { value: "153", label: "Cazaquistao,	Republica	Do	" },
      { value: "788", label: "Chade	" },
      { value: "158", label: "Chile	" },
      { value: "160", label: "China,	Republica	Popular	" },
      { value: "163", label: "Chipre	" },
      { value: "511", label: "Christmas,Ilha	(NAVIDAD)	" },
      { value: "741", label: "Cingapura	" },
      { value: "165", label: "Cocos(Keeling),Ilhas	" },
      { value: "169", label: "Colombia	" },
      { value: "173", label: "Comores,	Ilhas	" },
      { value: "177", label: "Congo	" },
      { value: "888", label: "Congo,	Republica	Democratica	Do	" },
      { value: "183", label: "Cook,	Ilhas	" },
      { value: "187", label: "Coreia	(DO	Norte),	Rep.Pop.Democratica	" },
      { value: "190", label: "Coreia	(DO	Sul),	Republica	Da	" },
      { value: "193", label: "Costa	Do	Marfim	" },
      { value: "196", label: "Costa	Rica	" },
      { value: "198", label: "Coveite	" },
      { value: "195", label: "Croacia	(REPUBLICA	Da)	" },
      { value: "199", label: "Cuba	" },
      { value: "232", label: "Dinamarca	" },
      { value: "009", label: "Dirce	" },
      { value: "783", label: "Djibuti	" },
      { value: "235", label: "Dominica,Ilha	" },
      { value: "237", label: "Dubai	" },
      { value: "240", label: "Egito	" },
      { value: "687", label: "El	Salvador	" },
      { value: "244", label: "Emirados	Arabes	Unidos	" },
      { value: "239", label: "Equador	" },
      { value: "243", label: "Eritreia	" },
      { value: "247", label: "Eslovaca,	Republica	" },
      { value: "246", label: "Eslovenia,	Republica	Da	" },
      { value: "245", label: "Espanha	" },
      { value: "249", label: "Estados	Unidos	" },
      { value: "251", label: "Estonia,	Republica	Da	" },
      { value: "253", label: "Etiopia	" },
      { value: "255", label: "Falkland	(ILHAS	Malvinas)	" },
      { value: "259", label: "Feroe,	Ilhas	" },
      { value: "263", label: "Fezzan	" },
      { value: "870", label: "Fiji	" },
      { value: "267", label: "Filipinas	" },
      { value: "271", label: "Finlandia	" },
      { value: "161", label: "Formosa	(TAIWAN)	" },
      { value: "275", label: "Franca	" },
      { value: "106", label: "Fretado	P/Brasil	" },
      { value: "281", label: "Gabao	" },
      { value: "285", label: "Gambia	" },
      { value: "289", label: "Gana	" },
      { value: "291", label: "Georgia,	Republica	Da	" },
      { value: "293", label: "Gibraltar	" },
      { value: "297", label: "Granada	" },
      { value: "301", label: "Grecia	" },
      { value: "305", label: "Groenlandia	" },
      { value: "309", label: "Guadalupe	" },
      { value: "313", label: "Guam	" },
      { value: "317", label: "Guatemala	" },
      { value: "325", label: "Guiana	Francesa	" },
      { value: "337", label: "Guiana	" },
      { value: "329", label: "Guine	" },
      { value: "334", label: "Guine-Bissau	" },
      { value: "331", label: "Guine-Equatorial	" },
      { value: "341", label: "Haiti	" },
      { value: "345", label: "Honduras	" },
      { value: "351", label: "Hong	Kong	" },
      { value: "355", label: "Hungria,	Republica	Da	" },
      { value: "358", label: "Iemem	Do	Sul	" },
      { value: "357", label: "Iemen	" },
      { value: "452", label: "Ilha	Da	Madeira	" },
      { value: "361", label: "India	" },
      { value: "365", label: "Indonesia	" },
      { value: "367", label: "Inglaterra	" },
      { value: "100", label: "Int.Z.F.Manaus	" },
      { value: "372", label: "Ira,	Republica	Islamica	Do	" },
      { value: "369", label: "Iraque	" },
      { value: "375", label: "Irlanda	" },
      { value: "379", label: "Islandia	" },
      { value: "383", label: "Israel	" },
      { value: "386", label: "Italia	" },
      { value: "391", label: "Jamaica	" },
      { value: "395", label: "Jammu	" },
      { value: "399", label: "Japao	" },
      { value: "150", label: "Jersey,	Ilha	Do	Canal	" },
      { value: "396", label: "Johnston,	Ilhas	" },
      { value: "403", label: "Jordania	" },
      { value: "411", label: "Kiribati	" },
      { value: "420", label: "Laos,	Rep.Pop.Democr.Do	" },
      { value: "423", label: "Lebuan,Ilhas	" },
      { value: "426", label: "Lesoto	" },
      { value: "427", label: "Letonia,	Republica	Da	" },
      { value: "431", label: "Libano	" },
      { value: "434", label: "Liberia	" },
      { value: "438", label: "Libia	" },
      { value: "440", label: "Liechtenstein	" },
      { value: "442", label: "Lituania,	Republica	Da	" },
      { value: "445", label: "Luxemburgo	" },
      { value: "447", label: "Macau	" },
      { value: "449", label: "Macedonia,	Ant.Rep.Iugoslava	" },
      { value: "450", label: "Madagascar	" },
      { value: "455", label: "Malasia	" },
      { value: "458", label: "Malavi	" },
      { value: "461", label: "Maldivas	" },
      { value: "464", label: "Mali	" },
      { value: "467", label: "Malta	" },
      { value: "359", label: "Man,	Ilha	De	" },
      { value: "472", label: "Marianas	Do	Norte	" },
      { value: "474", label: "Marrocos	" },
      { value: "476", label: "Marshall,Ilhas	" },
      { value: "477", label: "Martinica	" },
      { value: "485", label: "Mauricio	" },
      { value: "488", label: "Mauritania	" },
      { value: "493", label: "Mexico	" },
      { value: "093", label: "Mianmar	(BIRMANIA)	" },
      { value: "499", label: "Micronesia	" },
      { value: "490", label: "Midway,	Ilhas	" },
      { value: "505", label: "Mocambique	" },
      { value: "494", label: "Moldavia,	Republica	Da	" },
      { value: "495", label: "Monaco	" },
      { value: "497", label: "Mongolia	" },
      { value: "501", label: "Montserrat,Ilhas	" },
      { value: "507", label: "Namibia	" },
      { value: "508", label: "Nauru	" },
      { value: "517", label: "Nepal	" },
      { value: "521", label: "Nicaragua	" },
      { value: "525", label: "Niger	" },
      { value: "528", label: "Nigeria	" },
      { value: "531", label: "Niue,Ilha	" },
      { value: "535", label: "Norfolk,Ilha	" },
      { value: "538", label: "Noruega	" },
      { value: "542", label: "Nova	Caledonia	" },
      { value: "548", label: "Nova	Zelandia	" },
      { value: "556", label: "Oma	" },
      { value: "563", label: "Pacifico,Ilhas	Do	(ADMINISTRACAO	Dos	Eua)	" },
      { value: "566", label: "Pacifico,Ilhas	Do	(POSSESSAO	Dos	Eua)	" },
      { value: "569", label: "Pacifico,Ilhas	Do	(TERRITORIO	Em	Fideicomisso	Dos	" },
      { value: "573", label: "Paises	Baixos	(HOLANDA)	" },
      { value: "575", label: "Palau	" },
      { value: "578", label: "Palestina	" },
      { value: "580", label: "Panama	" },
      { value: "545", label: "Papua	Nova	Guine	" },
      { value: "583", label: "Papua	Nova	Guiné	" },
      { value: "576", label: "Paquistao	" },
      { value: "586", label: "Paraguai	" },
      { value: "589", label: "Peru	" },
      { value: "593", label: "Pitcairn,Ilha	" },
      { value: "599", label: "Polinesia	Francesa	" },
      { value: "603", label: "Polonia,	Republica	Da	" },
      { value: "611", label: "Porto	Rico	" },
      { value: "607", label: "Portugal	" },
      { value: "623", label: "Quenia	" },
      { value: "625", label: "Quirguiz,	Republica	" },
      { value: "628", label: "Reino	Unido	" },
      { value: "640", label: "Republica	Centro-Africana	" },
      { value: "647", label: "Republica	Dominicana	" },
      { value: "660", label: "Reuniao,	Ilha	" },
      { value: "670", label: "Romenia	" },
      { value: "675", label: "Ruanda	" },
      { value: "676", label: "Russia,	Federacao	Da	" },
      { value: "685", label: "Saara	Ocidental	" },
      { value: "678", label: "Saint	Kitts	E	Nevis	" },
      { value: "677", label: "Salomao,	Ilhas	" },
      { value: "690", label: "Samoa	" },
      { value: "691", label: "Samoa	Americana	" },
      { value: "697", label: "San	Marino	" },
      { value: "710", label: "Santa	Helena	" },
      { value: "715", label: "Santa	Lucia	" },
      { value: "695", label: "Sao	Cristovao	E	Neves,Ilhas	" },
      { value: "700", label: "Sao	Pedro	E	Miquelon	" },
      { value: "705", label: "Sao	Vicente	E	Granadinas	" },
      { value: "720", label: "Sao	Tome	E	Principe,	Ilhas	" },
      { value: "728", label: "Senegal	" },
      { value: "735", label: "Serra	Leoa	" },
      { value: "388", label: "Servia	E	Montenegro	" },
      { value: "731", label: "Seychelles	" },
      { value: "738", label: "Sikkim	" },
      { value: "744", label: "Siria,	Republica	Arabe	Da	" },
      { value: "748", label: "Somalia	" },
      { value: "750", label: "Sri	Lanka	" },
      { value: "754", label: "Suazilandia	" },
      { value: "759", label: "Sudao	" },
      { value: "764", label: "Suecia	" },
      { value: "767", label: "Suica	" },
      { value: "770", label: "Suriname	" },
      { value: "772", label: "Tadjiquistao,	Republica	Do	" },
      { value: "776", label: "Tailandia	" },
      { value: "780", label: "Tanzania,	Rep.Unida	Da	" },
      { value: "791", label: "Tcheca,	Republica	" },
      { value: "790", label: "Tchecoslovaquia	" },
      { value: "782", label: "Territorio	Brit.Oc.Indico	" },
      { value: "785", label: "Territorio	da	Alta	Comissao	do	Pacifico	Ocidental	" },
      { value: "795", label: "Timor	Leste	" },
      { value: "800", label: "Togo	" },
      { value: "810", label: "Tonga	" },
      { value: "805", label: "Toquelau,Ilhas	" },
      { value: "815", label: "Trinidad	E	Tobago	" },
      { value: "820", label: "Tunisia	" },
      { value: "823", label: "Turcas	E	Caicos,Ilhas	" },
      { value: "824", label: "Turcomenistao,	Republica	Do	" },
      { value: "827", label: "Turquia	" },
      { value: "828", label: "Tuvalu	" },
      { value: "831", label: "Ucrania	" },
      { value: "833", label: "Uganda	" },
      { value: "840", label: "Uniao	Das	Republicas	Socialistas	Sovieticas	" },
      { value: "845", label: "Uruguai	" },
      { value: "847", label: "Uzbequistao,	Republica	Do	" },
      { value: "551", label: "Vanuatu	" },
      { value: "848", label: "Vaticano,	Est.Da	Cidade	Do	" },
      { value: "850", label: "Venezuela	" },
      { value: "858", label: "Vietna	" },
      { value: "855", label: "Vietname	Norte	" },
      { value: "863", label: "Virgens,Ilhas	(BRITANICAS)	" },
      { value: "866", label: "Virgens,Ilhas	(E.U.A.)	" },
      { value: "873", label: "Wake,	Ilha	" },
      { value: "875", label: "Wallis	E	Futuna,	Ilhas	" },
      { value: "890", label: "Zambia	" },
      { value: "665", label: "Zimbabue	" },
    ];

    this.ufs = [
      { label: "<<selecione>>", value: "" },
      { label: "AC", value: "AC" },
      { label: "AL", value: "AL" },
      { label: "AM", value: "AM" },
      { label: "AP", value: "AP" },
      { label: "BA", value: "BA" },
      { label: "CE", value: "CE" },
      { label: "DF", value: "DF" },
      { label: "ES", value: "ES" },
      { label: "GO", value: "GO" },
      { label: "MA", value: "MA" },
      { label: "MT", value: "MT" },
      { label: "MS", value: "MS" },
      { label: "MG", value: "MG" },
      { label: "PA", value: "PA" },
      { label: "PB", value: "PB" },
      { label: "PR", value: "PR" },
      { label: "PE", value: "PE" },
      { label: "PI", value: "PI" },
      { label: "RJ", value: "RJ" },
      { label: "RN", value: "RN" },
      { label: "RS", value: "RS" },
      { label: "RO", value: "RO" },
      { label: "RR", value: "RR" },
      { label: "SC", value: "SC" },
      { label: "SP", value: "SP" },
      { label: "SE", value: "SE" },
      { label: "TO", value: "TO" },
    ];
    this.estadosCivis = [
      { label: "<<selecione>>", value: "" },
      { label: "Solteiro", value: "1" },
      { label: "Casado", value: "2" },
      { label: "Divorciado", value: "3" },
      { label: "Separado", value: "4" },
      { label: "Viúvo", value: "5" },
    ];

    this.idGenero = [
      { label: "<<selecione>>", value: "" },
      { label: "Mulher Cisgênero", value: "1" },
      { label: "Homem Cisgênero", value: "2" },
      { label: "Homem Transgênero", value: "3" },
      { label: "Mulher Transgênero", value: "4" },
      { label: "Travesti", value: "5" },
      { label: "Transmasculino", value: "6" },
      { label: "Não-binário", value: "7" },
      { label: "Outros", value: "8" },
    ];

    this.grupoSanguineo = [
      { label: "<<selecione>>", value: "" },
      { label: "A+", value: "A+" },
      { label: "A-", value: "A-" },
      { label: "B+", value: "B+" },
      { label: "B-", value: "B-" },
      { label: "AB+", value: "AB+" },
      { label: "AB-", value: "AB-" },
      { label: "O+", value: "O+" },
      { label: "O-", value: "O-" },
    ];

    this.tiposCursos = [
      { value: "", label: "<<selecione>>" },
      { value: "Nível Fundamental", label: "Nível Fundamental" },
      { value: "Nível Médio", label: "Nível Médio" },
      { value: "Nível Médio Técnico", label: "Nível Médio Técnico" },
      { value: "Nível Superior", label: "Nível Superior" },
      { value: "Pós-Graduação", label: "Pós-Graduação" },
      { value: "Mestrado", label: "Mestrado" },
      { value: "Doutorado", label: "Doutorado" },
      { value: "Pós-Doutorado", label: "Pós-Doutorado" },
    ];
  }
}
