export class Certidao {
  tipoCertidao: string;
  numCertidao: string;
  livro: string;
  folha: string;
  cartorio: string;
  uf: string;
  cidade: string;
  uniaoEstavel: string;
  tipoUniao: string;

  docEstCivilFrente: string;
  docEstCivilVerso: string;
  imgEstCivilFrente: string;
  imgEstCivilVerso: string;

  docCertidaoFrente: string;
  docCertidaoVerso: string;
  imgCertidaoFrente: string;
  imgCertidaoVerso: string;

  docCertCasamentoFrente: string;
  docCertCasamentoVerso: string;
  imgCertCasamentoFrente: string;
  imgCertCasamentoVerso: string;

  docUniaoEstavelFrente: string;
  docUniaoEstavelVerso: string;
  imgUniaoEstavelFrente: string;
  imgUniaoEstavelVerso: string;
}
