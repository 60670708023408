export class CarteiraDeHabitacao {
  id: number;
  numeroCnh: string;
  docCnhFrente: string;
  docCnhVerso: string;
  categoria: string;
  validade: string;
  uf: string;
  dataEmissao: string;
  primeiraHabilitacao: string;
  imgCnhFrente: string;
  imgCnhVerso: string;
}
