import { Perfil } from './../model/Perfil';
import { ErrorHandlerService } from './../core/error-handler.service';
import { Usuario } from './../model/Usuario';
import { Component, OnInit, ViewChild } from '@angular/core';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/primeng';
import { ToastyService } from 'ng2-toasty';
import { UserSantanderService } from '../user-santander/user-santander.service';
import { UsuarioFilter } from '../model/UsuarioFilter';

library.add(fas, far);

@Component({
  selector: 'app-rh',
  templateUrl: './rh.component.html',
  styleUrls: ['./rh.component.css']
})
export class RhComponent implements OnInit {

  totalRegistros = 0;

  @ViewChild('tabela') grid;

  cols: any[];

  usuarios: UsuarioFilter[];

  filter = new UsuarioFilter();

  perfis: Perfil[];

  edit = false;

  users: Usuario;

  usuario = new Usuario();

  alterarPerfil = false;

  constructor(private userSantanderService: UserSantanderService,
              private toasty: ToastyService,
              private errorHandler: ErrorHandlerService) { }

  ngOnInit() {
    this.filter = new UsuarioFilter();
    this.users = new Usuario();
    this.pesquisar();
  }

   pesquisar(pagina = 0) {
    this.filter.page = pagina;
    this.filter.isServidor = 'true';
    this.userSantanderService.pesquisar(this.filter)
      .then(resultado => {
        this.usuarios = resultado.usuarios;
        this.totalRegistros = resultado.total;
        this.usuarios.forEach(u => {
          if (u.perfis[0].id == '2') {
            u.rhMaster = true;
            u.rhCentral = false;
            u.agenteRh = false;
          } else if (u.perfis[0].id == '3') {
            u.rhMaster = false;
            u.rhCentral = true;
            u.agenteRh = false;
          } else if (u.perfis[0].id == '4') {
            u.rhMaster = false;
            u.rhCentral = false;
            u.agenteRh = true;
          } else {
            u.rhMaster = false;
            u.rhCentral = false;
            u.agenteRh = false;
          }
        });
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  limpar() {
    this.filter = new UsuarioFilter();
    this.pesquisar();
  }

  mudaPagina(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    if (this.edit) {
      this.limpar();
    }
    this.pesquisar(pagina);
  }

  filterToUsuario(usuarioOld: UsuarioFilter) {
    this.users = new Usuario();
    this.users.id = usuarioOld.id;
    this.users.login = usuarioOld.login;
    this.users.nome = usuarioOld.nome;
    this.users.cpf = usuarioOld.cpf;
    this.users.matricula01 = usuarioOld.matricula01;
    this.users.matricula02 = usuarioOld.matricula02;
    this.users.status = '1';
    this.users.termo = usuarioOld.termo;

    const perfs: Perfil[] = [{ id: usuarioOld.perfil.id, descricao: null , dataInicioVigencia: null, dataFimVigencia: null}];

    this.users.perfis = perfs;
    return this.users;
  }

  alterarRHConfirm(usu: UsuarioFilter, rh: any) {

    usu.perfil = new Perfil();
    if (rh.name == 'rhMaster' && rh.checked) {
      usu.rhCentral = false;
      usu.agenteRh = false;
      usu.perfil.id = '2';
    } else if (rh.name == 'rhCentral' && rh.checked) {
      usu.rhMaster = false;
      usu.agenteRh = false;
      usu.perfil.id = '3';
    } else if (rh.name == 'agenteRh' && rh.checked) {
      usu.rhCentral = false;
      usu.rhMaster = false;
      usu.perfil.id = '4';
    } else {
      usu.rhCentral = false;
      usu.rhMaster = false;
      usu.agenteRh = false;
      usu.perfil.id = '5';
    }
    this.userSantanderService.salvar(this.filterToUsuario(usu))
    .then(() => {
      this.toasty.success('Perfil RH alterado com sucesso!');
      this.ngOnInit();
    })
    .catch(erro => this.errorHandler.handle(erro));
    this.pesquisar();
    this.alterarPerfil = false;
  }

  alterarRHReject(rh: any) {

    if (rh.checked) {
      rh.checked = false;
    } else {
      rh.checked = true;
    }
    this.alterarPerfil = false;
  }
}
