import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '../seguranca/auth.service';
import { UserSantanderService } from '../user-santander/user-santander.service';
import { ErrorHandlerService } from '../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';


@Component({
  selector: 'app-boas-vindas',
  templateUrl: './boas-vindas.component.html',
  styleUrls: ['./boas-vindas.component.css']
})
export class BoasVindasComponent implements OnInit {
  checked: boolean = true;

  myCheckbox: FormControl = new FormControl();

  display: boolean;

  constructor(private auth: AuthService,
    private userSantanderService: UserSantanderService,
    private toasty: ToastyService,
    private errorHandler: ErrorHandlerService) {
    this.myCheckbox.valueChanges.subscribe(value => {
      this.hide_checkBox();
      this.show_menu();
    })
  }

  ngOnInit() {
    if (this.auth.jwtPayload.termo != 'S') {
      this.hide_menu();
    } else {
      this.hide_checkBox();
      
      alert('Essa sessão expira após 1 hora de Acesso, lembre-se de salvar parcialmente caso tenha que deixar seu computador por um tempo');
    }
  }
  


  hide_checkBox() {
    var el = document.getElementsByClassName('checked')[0];
    el.setAttribute("style", "display: none");

    if (this.auth.jwtPayload.termo != 'S') {
      this.aceitaTermo();
      this.auth.jwtPayload.termo = 'S';
    }
  }

  hide_menu() {
    var el = document.getElementsByClassName('menu')[0];

    el.setAttribute("style", "display: none;");

  }

  show_menu() {
    var el = document.getElementsByClassName('menu')[0];

    el.setAttribute("style", "display: true;");

  }

  aceitaTermo() {
    this.userSantanderService.aceitaTermo(this.auth.jwtPayload.cpf)
      .catch(erro => this.errorHandler.handle(erro));
  }
}


