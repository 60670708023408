export class Formacao {
    id:number;
    operacao: string;
    origemDados:string;
    empresa: string;
    modalidade: string;
    cursoFormacao: string;
    entidade: string;
    inicioCurso: string;
    conclusaoCurso: string;
    cpfTitular: string;
    sequencial: number;
    editar:boolean = false;
    key:number;
    
}