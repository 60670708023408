import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NOMEM } from "dns";
import { DadosGeraisAprovacao } from "../model/DadosGeraisAprovacao";
import { IRegistros } from "./../model/RegistrosRejeitados";
import { AuthService } from "./../seguranca/auth.service";
import { RegistroRejeitadoService } from "./registro-rejeitado.service";

@Component({
  selector: "app-registro-rejeitado",
  templateUrl: "./registro-rejeitado.component.html",
  styleUrls: ["./registro-rejeitado.component.css"],
})
export class RegistroRejeitadoComponent implements OnInit {
  registrosRejeitados: any;
  reg: [] = [];
  dadosGeraisAprovacao: DadosGeraisAprovacao = new DadosGeraisAprovacao();

  filter = new IRegistros();
  constructor(
    private router: Router,
    public auth: AuthService,
    private rejeitadoService: RegistroRejeitadoService
  ) {}

  ngOnInit() {
    this.all();
  }

  visualizar(serv: any) {
    serv.visualiza = true;
    this.router.navigate(["./detalhe-registro"], {
      queryParams: {
        cpf: serv.cpfTitularErro,
        erro: serv.descricaoErro,
        visualiza: true,
      },
    });
  }

  all() {
    this.rejeitadoService.all().then((e) => {
      this.registrosRejeitados = e;
    });
  }

  reenvio(serv: any) {
    serv.visualiza = false;
    this.router.navigate(["./detalhe-registro"], {
      queryParams: {
        cpf: serv.cpfTitularErro,
        erro: serv.descricaoErro,
        visualiza: false,
      },
    });
  }
}
