export class RegNacionalExtrang {
  id: number;
  numero: string;
  orgao: string;
  emissao: string;
  dataChegada: string;
  dataNatural: string;
  casamentoBras: string;
  filhoBras: string;
  classiPerm: string;
  regNacionalMigratorioFrente: string;
  regNacionalMigratorioVerso: string;
  imgRegNacionalMigratorioFrente: string;
  imgRegNacionalMigratorioVerso: string;
}
