export class IdentidadeProfissional {
  id:number;
  numInscric: string;
  numInscricaoFrente: string;
  numInscricaoVerso: string;
  imgNumInscricaoFrente: string;
  imgNumInscricaoVerso: string;
  uf: string;
  orgao: string;
  emissao: string;
  validade: string;
}
