import { Pageable } from "./../core/shared/Pageable";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { LazyLoadEvent } from "primeng/api";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AppComponent } from "../app.component";
import { ErrorHandlerService } from "../core/error-handler.service";
import { LotacaoAssociacaoService } from "../lotacao-associacao/lotacao-associacao.service";
import { DadosGeraisAprovacao } from "../model/DadosGeraisAprovacao";
import { ServidorFilter } from "../model/ServidorFilter";
import { RecadastramentoService } from "../recadastramento/recadastramento.service";
import { AuthService } from "../seguranca/auth.service";
import { Lotacao } from "./../model/Lotacao";

@Component({
  selector: "app-consulta-recadastramento",
  templateUrl: "./consulta-recadastramento.component.html",
  styleUrls: ["./consulta-recadastramento.component.css"],
})
export class ConsultaRecadastramentoComponent implements OnInit {
  combo = new FormControl();
  options: string[];
  filteredOptions: Observable<Lotacao[]>;
  pageable = new Pageable(0, 20);
  output: String[];

  results: String[];

  rolesSantander: string[] = ["SANTANDER_ADM", "SANTANDER_AGE"];
  totalRegistros = 0;
  @ViewChild("tabela") grid;

  cols: any[];

  headers = ["Nome", "Matrícula 01", "Matrícula 01", "CPF", "Excluir"];

  rolesRegistroRejeitado: string[] = [
    "RH_MASTER",
    "RH_CENTRAL",
    "RH_AGENTE",
    "SANTANDER_ADM",
    "ADM",
    "SANTANDER_AGE",
  ];

  servidores: DadosGeraisAprovacao[];

  filter = new ServidorFilter();

  servidor: DadosGeraisAprovacao;

  lotacoes: Lotacao[];

  temPermissao: boolean;
  isLoad: boolean;

  userSuporte: boolean = false;

  lotacao: string;
  filtro: string;

  constructor(
    public app: AppComponent,
    private errorHandler: ErrorHandlerService,
    private recadastramentoService: RecadastramentoService,
    private lotacaoService: LotacaoAssociacaoService,
    private auth: AuthService,
    private toasty: ToastyService,
    private router: Router
  ) {}

  ngOnInit() {
    this.temPermissao = this.auth.temPermissao(this.rolesSantander);
    this.preencherLotacoes();
    this.carregaMenu();
  }

  mudaPagina(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.pesquisarAprovacoes(pagina);
  }

  reabrir(serv: any) {
    this.recadastramentoService.reabrir(serv).then((resultado) => {
      if (resultado === true) {
        this.toasty.success("Reabertura concluída com sucesso!");
        this.pesquisarAprovacoes(0);
      } else {
        this.toasty.error("Reabertura sem sucesso!");
      }
    });
  }

  recadastrar(serv: any) {
    if (this.temPermissao) {
      this.router.navigate(["./duplaaprovacao"], {
        queryParams: { cpf: serv.cpf },
      });
    } else {
      this.router.navigate(["./recadastramento"], {
        queryParams: { cpf: serv.cpf },
      });
    }
  }

  visualizar(serv: any) {
    serv.visualiza = true;
    this.router.navigate(["./visualiza-recadastramento"], {
      queryParams: { cpf: serv.cpf, visualiza: true },
    });
  }

  aprovar(serv: any) {
    serv.visualiza = false;
    this.router.navigate(["./visualiza-recadastramento"], {
      queryParams: { cpf: serv.cpf, visualiza: false },
    });
  }

  status(serv: any) {
    this.router.navigate(["./timeline"], { queryParams: { cpf: serv.cpf } });
  }

  exportarArquivoCsv() {
    this.recadastramentoService.exportarCSV().then((resultado) => {
      if (resultado === true) {
        this.toasty.success("Exportação concluída com sucesso!");
      }
    });
  }

  // filtra o autocomplete de lotações
  private _filter(descricao: string): Lotacao[] {
    const filterValue = descricao.toLowerCase();
    return this.lotacoes.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
  }

  // recupera as lotações e popula o painel de opções do autocomplete
  preencherLotacoes() {
    this.lotacaoService
      .findAll()
      .then((lotacoes) => {
        this.lotacoes = lotacoes;
      })
      .then(() => {
        this.filteredOptions = this.combo.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  // recupera a lotação selecionada e adiciona ela no filtro
  selecionouLotacao(event) {
    let lotacaoSelecionada = event.option.value;
    this.pesquisarAprovacoes(0);
  }

  // recupera o valor digitado e verifica se é uma lotação válida antes de adicionar ela no filtro
  escrevendoLotacao(lotacaoInput: string) {
    this.filter.lotacao = lotacaoInput;
    this.pesquisarAprovacoes(0);
  }

  // verifica se o usuário digitou a lotação errada e avisa
  filtrar(desc: string) {
    this.pesquisarAprovacoes(0);
  }

  pesquisarAprovacoes(pagina = 0) {
    this.isLoad = true;
    this.pageable.page = pagina;

    this.recadastramentoService
      .pesquisaAprovacoes(this.auth.jwtPayload.cpf, this.filter, this.pageable)
      .then((resultado) => {
        this.isLoad = false;

        this.servidores = resultado.servidores;
        this.totalRegistros = resultado.total;
      })
      .catch((erro) => {
        this.isLoad = false;
        this.errorHandler.handle(erro);
      });
  }

  // limpa os campos e o filtro além de listar novamente os servidores
  limpar() {
    this.filter = new ServidorFilter();
    this.combo.setValue("");
    this.pesquisarAprovacoes(0);
  }

  carregaMenu() {
    if (this.auth.temPermissao(this.rolesRegistroRejeitado)) {
      this.userSuporte = true;
    }
  }
}
