import { DadosGeraisAprovacao } from "./DadosGeraisAprovacao";

export class DadosGeraisAprovacaoDTO2 {
  id: number;
  cpf: string;
  operacao: string;
  origemDados: string;
  empresa: string;
  matricula02: string;
  cargoEfetivo02: string;
  lotacao02: string;
  rhResponsavel: string;
  cargo: string;
  cpfAtualizado: any;
  docCpfAtualizado: string;
  // imgCpfAtualizado: string;
  nome: string;
  docNomeAttFrente: string;
  // imgNomeAttFrente: string;
  docNomeAttVerso: string;
  // imgNomeAttVerso: string;
  nomeSocial: string;
  docNomeSocialAttFrente: string;
  // imgNomeSocialAttFrente: string;
  docNomeSocialAttVerso: string;
  // imgNomeSocialAttVerso: string;
  ramal: string;
  sexo: string;
  docSexoAttFrente: string;
  // imgSexoAttFrente: string;
  docSexoAttVerso: string;
  // imgSexoAttVerso: string;
  docNascFrente: string;
  // imgNascFrente: string;
  docNascVerso: string;
  // imgNascVerso: string;
  grupoSanguineo: string;
  racaCor: string;
  deficienciaFisica: string;
  docCertNascFrente: String;
  docCertNascVerso: String;
  docDeficienciaFisicaFrente: string;
  docDeficienciaFisicaVerso: string;
  deficienciaVisual: string;
  docDeficienciaVisualFrente: string;
  docDeficienciaVisualVerso: string;
  deficienciaAuditiva: string;
  docDeficienciaAuditivaFrente: string;
  docDeficienciaAuditivaVerso: string;
  deficienciaMental: string;
  docDeficienciaMentalFrente: string;
  docDeficienciaMentalVerso: string;
  deficienciaIntelectual: string;
  docDeficienciaIntelectualFrente: string;
  docDeficienciaIntelectualVerso: string;
  docNacionalidadeFrente: string;
  // imgNacionalidadeFrente: string;
  docNacionalidadeVerso: string;
  // imgNacionalidadeVerso: string;
  reabilitado: string;
  cota: string;
  dataNascimento: string;
  paisNacionalidade: string;
  municipioNascimento: string;
  ufNascimento: string;
  nomePai: string;
  nomeMae: string;
  estadoCivil: string;
  grauInstrucao: string;
  primeiroEmprego: string;
  ufEmpregoAnterior: string;
  numeroNis: string;
  docNumeroNisFrente: string;
  docNumeroNisVerso: string;
  numeroDependentes: string;
  fotoAgente: string;
  nacionalidade: string;
  modalidade: string;
  cursoFormacao: string;
  entidade: string;
  inicioCurso: string;
  conclusaoCurso: string;
  arquivoInformacao: string;
  identidadeGenero: string;
  loginUsuario: string;
  loginSantander: string;
  loginRh: string;


  constructor(dadosGeraisAprovacao: DadosGeraisAprovacao) {
    this.cpf = dadosGeraisAprovacao.cpf;
    this.operacao = dadosGeraisAprovacao.operacao;
    this.origemDados = dadosGeraisAprovacao.origemDados;
    this.empresa = dadosGeraisAprovacao.empresa;
    this.matricula02 = dadosGeraisAprovacao.matricula02;
    this.cargoEfetivo02 = dadosGeraisAprovacao.cargoEfetivo02;
    this.lotacao02 = dadosGeraisAprovacao.lotacao02;
    this.rhResponsavel = dadosGeraisAprovacao.rhResponsavel;
    this.cargo = dadosGeraisAprovacao.cargo;
    this.cpfAtualizado = dadosGeraisAprovacao.cpfAtualizado;
    this.docCpfAtualizado = dadosGeraisAprovacao.docCpfAtualizado;
    this.nome = dadosGeraisAprovacao.nome;
    this.docNomeAttFrente = dadosGeraisAprovacao.docNomeAttFrente;
    this.docNomeAttVerso = dadosGeraisAprovacao.docNomeAttVerso;
    this.nomeSocial = dadosGeraisAprovacao.nomeSocial;
    this.docNomeSocialAttFrente = dadosGeraisAprovacao.docNomeSocialAttFrente;
    this.docNomeSocialAttVerso = dadosGeraisAprovacao.docNomeSocialAttVerso;
    this.ramal = dadosGeraisAprovacao.ramal;
    this.sexo = dadosGeraisAprovacao.sexo;
    this.docSexoAttFrente = dadosGeraisAprovacao.docSexoAttFrente;
    this.docSexoAttVerso = dadosGeraisAprovacao.docSexoAttVerso;
    this.docNascFrente = dadosGeraisAprovacao.docNascFrente;
    this.docNascVerso = dadosGeraisAprovacao.docNascVerso;
    this.grupoSanguineo = dadosGeraisAprovacao.grupoSanguineo;
    this.racaCor = dadosGeraisAprovacao.racaCor;
    this.deficienciaFisica = dadosGeraisAprovacao.deficienciaFisica;
    this.docCertNascFrente = dadosGeraisAprovacao.docCertNascFrente;
    this.docCertNascVerso = dadosGeraisAprovacao.docCertNascVerso;
    this.docDeficienciaFisicaFrente =
      dadosGeraisAprovacao.docDeficienciaFisicaFrente;
    this.docDeficienciaFisicaVerso =
      dadosGeraisAprovacao.docDeficienciaFisicaVerso;
    this.deficienciaVisual = dadosGeraisAprovacao.deficienciaVisual;
    this.docDeficienciaVisualFrente =
      dadosGeraisAprovacao.docDeficienciaVisualFrente;
    this.docDeficienciaVisualVerso =
      dadosGeraisAprovacao.docDeficienciaVisualVerso;
    this.deficienciaAuditiva = dadosGeraisAprovacao.deficienciaAuditiva;
    this.docDeficienciaAuditivaFrente =
      dadosGeraisAprovacao.docDeficienciaAuditivaFrente;
    this.docDeficienciaAuditivaVerso =
      dadosGeraisAprovacao.docDeficienciaAuditivaVerso;
    this.deficienciaMental = dadosGeraisAprovacao.deficienciaMental;
    this.docDeficienciaMentalFrente =
      dadosGeraisAprovacao.docDeficienciaMentalFrente;
    this.docDeficienciaMentalVerso =
      dadosGeraisAprovacao.docDeficienciaMentalVerso;
    this.deficienciaIntelectual = dadosGeraisAprovacao.deficienciaIntelectual;
    this.docDeficienciaIntelectualFrente =
      dadosGeraisAprovacao.docDeficienciaIntelectualFrente;
    this.docDeficienciaIntelectualVerso =
      dadosGeraisAprovacao.docDeficienciaIntelectualVerso;
    this.docNacionalidadeFrente = dadosGeraisAprovacao.docNacionalidadeFrente;
    this.docNacionalidadeVerso = dadosGeraisAprovacao.docNacionalidadeVerso;
    this.reabilitado = dadosGeraisAprovacao.reabilitado;
    this.cota = dadosGeraisAprovacao.cota;
    this.dataNascimento = dadosGeraisAprovacao.dataNascimento;
    this.paisNacionalidade = dadosGeraisAprovacao.paisNacionalidade;
    this.municipioNascimento = dadosGeraisAprovacao.municipioNascimento;
    this.ufNascimento = dadosGeraisAprovacao.ufNascimento;
    this.nomePai = dadosGeraisAprovacao.nomePai;
    this.nomeMae = dadosGeraisAprovacao.nomeMae;
    this.estadoCivil = dadosGeraisAprovacao.estadoCivil;
    this.grauInstrucao = dadosGeraisAprovacao.grauInstrucao;
    this.primeiroEmprego = dadosGeraisAprovacao.primeiroEmprego;
    this.ufEmpregoAnterior = dadosGeraisAprovacao.ufEmpregoAnterior;
    this.numeroNis = dadosGeraisAprovacao.numeroNis;
    this.docNumeroNisFrente = dadosGeraisAprovacao.docNumeroNisFrente;
    this.docNumeroNisVerso = dadosGeraisAprovacao.docNumeroNisVerso;
    this.numeroDependentes = dadosGeraisAprovacao.numeroDependentes;
    this.fotoAgente = dadosGeraisAprovacao.fotoAgente;
    this.nacionalidade = dadosGeraisAprovacao.nacionalidade;
    this.modalidade = dadosGeraisAprovacao.modalidade;
    this.cursoFormacao = dadosGeraisAprovacao.cursoFormacao;
    this.entidade = dadosGeraisAprovacao.entidade;
    this.inicioCurso = dadosGeraisAprovacao.inicioCurso;
    this.conclusaoCurso = dadosGeraisAprovacao.conclusaoCurso;
    this.arquivoInformacao = dadosGeraisAprovacao.arquivoInformacao;
    this.identidadeGenero = dadosGeraisAprovacao.identidadeGenero;

  }
}
