import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ToastyConfig } from "ng2-toasty";
import { Ng2ImgMaxService } from "ng2-img-max";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  mobileMenuActive: boolean;

  constructor(
    private toastyConfig: ToastyConfig,
    private router: Router,
    private ng2ImgMax: Ng2ImgMaxService
  ) {
    this.toastyConfig.theme = "bootstrap";
  }

  exibindoNavbar() {
    return this.router.url !== "/login";
  }

  onMobileMenuButton(event) {
    this.mobileMenuActive = !this.mobileMenuActive;
    event.preventDefault();
  }

  hideMobileMenu(event) {
    this.mobileMenuActive = false;
    event.preventDefault();
  }
}
