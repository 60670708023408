import { Pageable } from "./../core/shared/Pageable";
import { debug } from "console";
import { Endereco } from "./../model/Endereco";
import { Injectable } from "@angular/core";
import { RecadastramentoHttp } from "../seguranca/recadastramentoHttp";
import { environment } from "src/environments/environment";
import { DadosGeraisAprovacao } from "../model/DadosGeraisAprovacao";
import { HttpParams, HttpHeaders } from "@angular/common/http";
import { Lotacao } from "../model/Lotacao";
import { JsonMessage } from "../model/JsonMessage";
import { ServidorFilter } from "../model/ServidorFilter";
import { Timeline } from "../model/Timeline";
import { Dashboard } from "../model/Dashboard";
import { Http } from "@angular/http";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import { Banner } from "../model/Banner";
import { Ng2ImgMaxService } from "ng2-img-max";
import { promise } from "protractor";
import { DadosGeraisAprovacaoDTO1 } from "../model/DadosGeraisAprovacaoDTO1";
import { DadosGeraisAprovacaoDTO2 } from "../model/DadosGeraisAprovacaoDTO2";
import { DadosGeraisAprovacaoDTO3 } from "../model/DadosGeraisAprovacaoDTO3";

@Injectable({
  providedIn: "root",
})
export class RecadastramentoService {
  dadosGeraisUrl: string;
  dadosGeraisAprovacaoUrl: string;
  fileEnvio: File;

  constructor(
    private http: RecadastramentoHttp,
    private https: Http,
    private ng2ImgMax: Ng2ImgMaxService
  ) {
    this.dadosGeraisUrl = `${environment.apiUrl}/dadosgerais`;
    this.dadosGeraisAprovacaoUrl = `${environment.apiUrl}/aprovacao`;
  }

  async pesquisar(cpf: string): Promise<DadosGeraisAprovacao> {
    const response = await this.http
      .get<any>(`${this.dadosGeraisUrl}/` + cpf)
      .toPromise();

    return response;
  }

  async getDetails(cpf: string): Promise<DadosGeraisAprovacao> {
    const response = await this.http
      .get<any>(`${this.dadosGeraisUrl}/details/` + cpf)
      .toPromise();

    return response;
  }

  async pesquisarCargo(cargo: string) {
    return await this.http
      .get<any>(`${this.dadosGeraisAprovacaoUrl}/cargo?cargo=` + cargo)
      .toPromise();
  }

  //Arrumar este getimage para outros processos
  async getImage(caminho: string): Promise<any> {
    return await this.http
      .get<any>(`${this.dadosGeraisAprovacaoUrl}/image?caminho=` + caminho)
      .toPromise();
  }
  /////////////////////////////////////////////

  async getImageAgente(cpf: string): Promise<any> {
    return await this.http
      .get<any>(`${this.dadosGeraisAprovacaoUrl}/image/agente?cpf=` + cpf)
      .toPromise();
  }

  async downloadImagem(body: any): Promise<any> {
    return await this.http.post<any>(`${this.dadosGeraisAprovacaoUrl}/anexo/download`, body).toPromise();
  }

  async getImageCpfAtualizado(cpfAtualizado: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/cpfatualizado?cpfAtualizado=` +
          cpfAtualizado
      )
      .toPromise();
  }

  async getImageNomeAttFrente(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/nomeatualizado/frente?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageNomeAttVerso(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/nomeatualizado/verso?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageNomeSocialFrente(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/nomesocial/frente?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageNomeSocialVerso(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/nomesocial/verso?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageSexoFrente(cpf: string): Promise<any> {
    return await this.http
      .get<any>(`${this.dadosGeraisAprovacaoUrl}/image/sexo/frente?cpf=` + cpf)
      .toPromise();
  }

  async getImageSexoVerso(cpf: string): Promise<any> {
    return await this.http
      .get<any>(`${this.dadosGeraisAprovacaoUrl}/image/sexo/verso?cpf=` + cpf)
      .toPromise();
  }

  async getImageNascFrente(cpf: string): Promise<any> {
    return await this.http
      .get<any>(`${this.dadosGeraisAprovacaoUrl}/image/nasc/frente?cpf=` + cpf)
      .toPromise();
  }
  async getImageNascVerso(cpf: string): Promise<any> {
    return await this.http
      .get<any>(`${this.dadosGeraisAprovacaoUrl}/image/nasc/verso?cpf=` + cpf)
      .toPromise();
  }

  async getImageCertidaoUniaoEstavelFrente(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/certidao/frente?cpf=` +
          cpf
      )
      .toPromise();
  }
  async getImageCertidaoUniaoEstavelVerso(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/certidao/verso?cpf=` +
        cpf
      )
      .toPromise();
  }

  async getImageRneFrente(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/rne/frente?id=` + id
      )
      .toPromise();
  }

  async getImageRneVerso(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/rne/verso?id=` + id
      )
      .toPromise();
  }

  async getImageNacionalidadeFrente(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/nacionalidade/frente?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageNacionalidadeVerso(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/nacionalidade/verso?cpf=` + cpf
      )
      .toPromise();
  }
  /////////
  async getImageFrenteDefFisica(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/deffisica/frente?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageVersoDefFisica(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/deffisica/verso?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageFrenteDefVisual(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/defvisual/frente?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageVersoDefVisual(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/defvisual/verso?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageFrenteDefAuditiva(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/defauditiva/frente?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageVersoDefAuditiva(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/defauditiva/verso?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageFrenteDefMental(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/defmental/frente?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageVersoDefMental(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/defmental/verso?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageFrenteDefIntelectual(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/defintelectual/frente?cpf=` + cpf
      )
      .toPromise();
  }

  async getImageVersoDefIntelectual(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/defintelectual/verso?cpf=` + cpf
      )
      .toPromise();
  }

  ///// -----------------------------------------------------------------

  async getImageDependenteFrenteDefFisica(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/deffisica/frente?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteVersoDefFisica(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/deffisica/verso?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteFrenteDefVisual(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/defvisual/frente?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteVersoDefVisual(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/defvisual/verso?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteFrenteDefAuditiva(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/defauditiva/frente?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteVersoDefAuditiva(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/defauditiva/verso?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteFrenteDefMental(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/defmental/frente?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteVersoDefMental(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/defmental/verso?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteFrenteDefIntelectual(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/defintelectual/frente?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteVersoDefIntelectual(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/defintelectual/verso?cpf=` +
          cpf
      )
      .toPromise();
  }

  ///// -----------------------------------------------------------------

  async getImageCpfFrenteDependente(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/cpffrentedependente?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageCpfVersoDependente(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependente/cpfversodependente?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageFrente(id: number): Promise<any> {
    return await this.http
      .get<any>(`${this.dadosGeraisAprovacaoUrl}/image/frente?id=` + id)
      .toPromise();
  }

  async getImageVerso(id: number): Promise<any> {
    return await this.http
      .get<any>(`${this.dadosGeraisAprovacaoUrl}/image/verso?id=` + id)
      .toPromise();
  }

  async getImageTitEleitorFrente(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/tituloeleitor/frente?id=` +
          id
      )
      .toPromise();
  }

  async getImageTitEleitorVerso(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/tituloeleitor/verso?id=` +
          id
      )
      .toPromise();
  }

  async getImageCnhFrente(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/cnh/frente?id=` +
          id
      )
      .toPromise();
  }

  async getImageCnhVerso(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/cnh/verso?id=` + id
      )
      .toPromise();
  }

  async getImageCtpsFrente(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/ctps/frente?id=` +
          id
      )
      .toPromise();
  }

  async getImageCtpsVerso(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/ctps/verso?id=` +
          id
      )
      .toPromise();
  }

  async getImageNumeroNisFrente(numeroNis: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/numeronis/frente?numeroNis=` +
          numeroNis
      )
      .toPromise();
  }

  async getImageNumeroNisVerso(numeroNis: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/numeronis/verso?numeroNis=` +
          numeroNis
      )
      .toPromise();
  }

  async getImageDocumentoMilitarFrente(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/numeroreservista/frente?id=` +
          id
      )
      .toPromise();
  }

  async getImageDocumentoMilitarVerso(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/numeroreservista/verso?id=` +
          id
      )
      .toPromise();
  }

  async getImageDependenteNomeAttFrente(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependentenomeatt/frente?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteNomeAttVerso(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependentenomeatt/verso?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteSexoAttFrente(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependentesexoatt/frente?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteSexoAttVerso(cpf: string): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/dependentesexoatt/verso?cpf=` +
          cpf
      )
      .toPromise();
  }

  async getImageDependenteRegGeral(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/registrogeral/frente?id=` +
          id
      )
      .toPromise();
  }

  async getImageDependenteRegGeralVerso(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/registrogeral/verso?id=` +
          id
      )
      .toPromise();
  }

  async getImageNumInscricaoFrente(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/numeroinscricao/frente?id=` +
          id
      )
      .toPromise();
  }

  async getImageNumInscricaoVerso(id: number): Promise<any> {
    return await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/image/numeroinscricao/verso?id=` +
          id
      )
      .toPromise();
  }

  salvar(cpf: string, lotacoes: Lotacao[]): Promise<any> {
    return this.http
      .post<Lotacao>(this.dadosGeraisUrl + "/salvar/" + cpf, lotacoes)
      .toPromise();
  }

  reabrir(serv: DadosGeraisAprovacao): Promise<any> {
    return this.http
      .post<DadosGeraisAprovacao>(
        this.dadosGeraisAprovacaoUrl + "/reabrir",
        serv
      )
      .toPromise();
  }

  salvarAnexo(
    event,
    tipo: string,
    campo: string,
    cpf: string,
    cpfDependente: string
  ): Promise<JsonMessage> {
    let formData: FormData = new FormData();
    formData.append("anexo", event, event.name);
    formData.append("cpf", cpf);
    formData.append("tipo", tipo);
    formData.append("campo", campo);
    formData.append("cpfDependente", cpfDependente);

    return this.http
      .post<JsonMessage>(`${this.dadosGeraisAprovacaoUrl}/anexo`, formData)
      .toPromise();
  }

  salvarFotoAgente(
    image,
    tipo: string,
    campo: string,
    cpf: string,
    cpfDependente: string
  ) {
    let formData: FormData = new FormData();

    formData.append("anexo", image, image.name);
    formData.append("cpf", cpf);
    formData.append("tipo", tipo);
    formData.append("campo", campo);
    formData.append("cpfDependente", cpfDependente);

    return this.http
      .post<JsonMessage>(`${this.dadosGeraisAprovacaoUrl}/anexo`, formData)
      .toPromise();
  }

  salvarRecadastramento(
    dadosGeraisAprovacao: DadosGeraisAprovacao
  ): Promise<DadosGeraisAprovacao> {
    return this.http
      .post<DadosGeraisAprovacao>(
        this.dadosGeraisAprovacaoUrl + "/salvar",
        dadosGeraisAprovacao
      )
      .toPromise();
  }

  salvarRecadastramentoParte1(
    dadosGeraisAprovacaoDTO1: DadosGeraisAprovacaoDTO1
  ): Promise<number> {
    console.info(dadosGeraisAprovacaoDTO1)
    return this.http
      .post<number>(
        this.dadosGeraisAprovacaoUrl + "/salvar/parte1",
        dadosGeraisAprovacaoDTO1
      )
      .toPromise();
  }

  salvarRecadastramentoParte2(
    dadosGeraisAprovacaoDTO2: DadosGeraisAprovacaoDTO2
  ): Promise<DadosGeraisAprovacaoDTO2> {
    return this.http
      .post<DadosGeraisAprovacaoDTO2>(
        this.dadosGeraisAprovacaoUrl + "/salvar/parte2",
        dadosGeraisAprovacaoDTO2
      )
      .toPromise();
  }

  salvarRecadastramentoParte3(
    dadosGeraisAprovacaoDTO3: DadosGeraisAprovacaoDTO3
  ): Promise<DadosGeraisAprovacaoDTO3> {
    return this.http
      .post<DadosGeraisAprovacaoDTO3>(
        this.dadosGeraisAprovacaoUrl + "/salvar/parte3",
        dadosGeraisAprovacaoDTO3
      )
      .toPromise();
  }

  salvarReferenciaDaFoto(
    idDadosGeraisAprovacao: number,
    nomeFoto: string
  ): Promise<number> {
    return this.http
      .post<number>(
        this.dadosGeraisAprovacaoUrl +
          `/salvar/referenciaFoto/${idDadosGeraisAprovacao}`,
        nomeFoto
      )
      .toPromise();
  }

  async pesquisaAprovacoes(
    cpfLogado: string,
    filtro: ServidorFilter,
    pageable: Pageable
  ): Promise<any> {
    let usuarioFilter;

    const { nome, cpf, lotacao } = filtro;
    usuarioFilter = { nome, cpf, lotacao };

    const response = await this.http
      .post<any>(
        `${this.dadosGeraisAprovacaoUrl}/listar/` +
          cpfLogado +
          `?page=${pageable.page}&size=${pageable.size}`,
        usuarioFilter
      )
      .toPromise();
    const servidores = response.content;
    const resultado = {
      servidores,
      total: response.totalElements,
    };
    return resultado;
  }

  async listarTimeline(cpf: string): Promise<Timeline> {
    const response = await this.http
      .get<Timeline>(`${this.dadosGeraisAprovacaoUrl}/timeline/` + cpf)
      .toPromise();
    return response;
  }

  aprovar(dadosGeraisAprovacao: any): Promise<any> {
    return this.http
      .post<DadosGeraisAprovacao>(
        `${this.dadosGeraisAprovacaoUrl}`,
        dadosGeraisAprovacao
      )
      .toPromise();
  }

  listDashboard(status: string, lotacao: string, perfil: string) {
    let params = new HttpParams();

    if (status != null) {
      params = params.append("status", status);
    }

    if (lotacao != null) {
      params = params.append("lotacao", lotacao);
    }

    if (perfil != null) {
      params = params.append("perfil", perfil);
    }

    return this.http.get<any>(`${this.dadosGeraisUrl}/dashboard/`, { params });
  }

  buscaCep(cep: string) {
    return this.https
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .toPromise()
      .then((response) => this.converterRespostaParaCep(response.json()));
  }

  converterRespostaParaCep(cepNaResposta): Endereco {
    let endereco = new Endereco();
    endereco.cep = cepNaResposta.cep;
    endereco.endereco = cepNaResposta.logradouro;
    endereco.complemento = cepNaResposta.complemento;
    endereco.bairro = cepNaResposta.bairro;
    endereco.municipio = cepNaResposta.localidade;
    endereco.uf = cepNaResposta.uf;
    return endereco;
  }

  converterCargo(response) {
    console.log(response);
    return response;
  }

  async exportarCSV(): Promise<boolean> {
    const response = await this.http
      .get<boolean>(`${this.dadosGeraisAprovacaoUrl}/exportar-arquivo/csv`)
      .toPromise();
    return response;
  }

  async carregarUrlBanner(cpf: string): Promise<Banner> {
    return await this.http
      .get<Banner>(`${this.dadosGeraisAprovacaoUrl}/url-banner/${cpf}`)
      .toPromise();
  }
  async searchFormacao(event) {
    // return this.https.get(`${this.dadosGeraisAprovacaoUrl}/listar/curso?curso=`+event.query)
    //   .toPromise()
    //   .then(res => <any[]> res.json().data)
    //   .then(data => { return data; });
    const resp = await this.http
      .get<any>(
        `${this.dadosGeraisAprovacaoUrl}/listar/curso?curso=` + event.query
      )
      .toPromise();
    return resp;
  }
}
