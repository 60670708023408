import { AuthService } from "./../../seguranca/auth.service";
import { Component, OnInit } from "@angular/core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { MenuItem } from "primeng/api";
import { LogoutService } from "src/app/seguranca/logout.service";
import { ErrorHandlerService } from "../error-handler.service";
import { Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";

library.add(fas, far);

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  rolesSantanderADM: string[] = ["SANTANDER_ADM", "ADM"];
  rolesRH: string[] = ["RH_MASTER", "ADM"];
  rolesRecadastramento: string[] = [
    "RH_MASTER",
    "ATIVO",
    "RH_CENTRAL",
    "RH_AGENTE",
  ];
  rolesAssociacao: string[] = ["RH_MASTER", "ADM"];
  rolesConsultaRecadastramento: string[] = [
    "RH_MASTER",
    "RH_CENTRAL",
    "RH_AGENTE",
    "SANTANDER_ADM",
    "ADM",
    "SANTANDER_AGE",
    "SUPORTE_ACCESSTAGE",
  ];
  rolesRegistroRejeitado: string[] = ["SUPORTE_ACCESSTAGE", "ADM"];

  santanderAdm: boolean = false;
  userRh: boolean = false;
  userRecadastro: boolean = false;
  userAssociacao: boolean = false;
  userSuporte: boolean = false;
  UserConsulta: boolean = false;

  constructor(
    public app: AppComponent,
    public auth: AuthService,
    private logoutService: LogoutService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) {}

  ngOnInit() {
    this.carregaMenu();
  }

  logout() {
    this.logoutService
      .logout()
      .then(() => {
        this.router.navigate(["/login"]);
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  carregaMenu() {
    if (this.auth.temPermissao(this.rolesRecadastramento)) {
      this.userRecadastro = true;
    }

    if (this.auth.temPermissao(this.rolesSantanderADM)) {
      this.santanderAdm = true;
    }

    if (this.auth.temPermissao(this.rolesRH)) {
      this.userRh = true;
    }

    if (this.auth.temPermissao(this.rolesAssociacao)) {
      this.userAssociacao = true;
    }

    if (this.auth.temPermissao(this.rolesConsultaRecadastramento)) {
      this.UserConsulta = true;
    }

    if (this.auth.temPermissao(this.rolesRegistroRejeitado)) {
      this.userSuporte = true;
    }
  }
}
