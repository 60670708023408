import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ButtonModule } from "primeng/components/button/button";
import { InputTextModule } from "primeng/components/inputtext/inputtext";

import { SegurancaRoutingModule } from "./seguranca-routing.module";
import { LoginFormComponent } from "./login-form/login-form.component";
import { FieldsetModule, ProgressSpinnerModule } from "primeng/primeng";
import { JwtModule } from "@auth0/angular-jwt";
import { environment } from "src/environments/environment";
import { LogoutService } from "./logout.service";
import { MatProgressSpinnerModule } from "@angular/material";

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FieldsetModule,
    MatProgressSpinnerModule,

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: environment.tokenWhiteListedDomains,
        blacklistedRoutes: environment.tokenBlackListedRoutes,
      },
    }),

    InputTextModule,
    ButtonModule,

    SegurancaRoutingModule,
  ],
  declarations: [LoginFormComponent],
  providers: [LogoutService],
})
export class SegurancaModule {}
