import { Component, OnInit } from '@angular/core';
import { AuthService } from '../seguranca/auth.service';
import { ErrorHandlerService } from '../core/error-handler.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserSantanderService } from '../user-santander/user-santander.service';

@Component({
  selector: 'app-dupla-aprovacao',
  templateUrl: './dupla-aprovacao.component.html',
  styleUrls: ['./dupla-aprovacao.component.css']
})
export class DuplaAprovacaoComponent implements OnInit {
  cpf: any;

  constructor(
    private usuarioService: UserSantanderService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private activatedRoute: ActivatedRoute

  ) { this.cpf = activatedRoute.snapshot.queryParams; }

  ngOnInit() {
  }

  duplaAprovacao(usuario: string, senha: string) {
    if (usuario != this.cpf.cpf) {
      this.errorHandler.handle('Usuario e senha invalidos!');
    } else {
      this.usuarioService.duplaAprovacao(usuario, senha)
        .then(resultado => {
          this.router.navigate(['./recadastramento'],
            { queryParams: { cpf: this.cpf.cpf } });
        })
        .catch(erro => this.errorHandler.handle('Usuario e senha invalidos!'));
    }
  }
}
