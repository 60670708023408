export class RegistroGeral {
  id: number;
  rg: string;
  docNuRgFrente: string;
  docNuRgVerso: string;
  orgao: string;
  uf: string;
  emissao: string;
  anexoImagemFrente: string;
  anexoImagemVerso: string;
}
