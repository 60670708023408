import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {

    this.hide_menu();

  }

  hide_menu(){
    var el = document.getElementsByClassName('menu')[0];

    el.setAttribute("style", "display: none;");

  }
  voltaPag() {
    this.router.navigate(['/login']);
  }
  
  

}

