import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import 'rxjs/add/operator/toPromise';

import { environment } from './../../environments/environment';
import { RecadastramentoHttp } from '../seguranca/recadastramentoHttp';
import { Usuario } from '../model/Usuario';
import { UsuarioFilter } from '../model/UsuarioFilter';

@Injectable({
  providedIn: 'root'
})
export class UserSantanderService {

  usuariosUrl: string;

  constructor(private http: RecadastramentoHttp) {
    this.usuariosUrl = `${environment.apiUrl}/usuario`;
  }

  async pesquisar(filtro: UsuarioFilter): Promise<any> {
    let params = new HttpParams();

    params = params.append('page', filtro.page.toString());
    params = params.append('size', filtro.itensPorPagina.toString());
    params = params.append('isServidor', filtro.isServidor);

    if (filtro.nome != null) {
      params = params.append('nome', filtro.nome);
    }

    if (filtro.login != null) {
      params = params.append('login', filtro.login);
    }

    if (filtro.cpf != null) {
      params = params.append('cpf', filtro.cpf);
    }

    if (filtro.perfil != null) {
      params = params.append('perfil', filtro.perfil.toString());
    }

    const response = await this.http.get<any>(`${this.usuariosUrl}/listar`, { params })
      .toPromise();
    const usuarios = response.content;
    const resultado = {
      usuarios,
      total: response.totalElements
    };
    return resultado;
  }

  async inativar(id: number): Promise<void> {
    const headers = new HttpHeaders();

    await this.http.post(this.usuariosUrl + '/inativar/' + id, { headers })
      .toPromise();
    return null;
  }

  salvar(usuario: Usuario): Promise<Usuario> {
    return this.http.post<Usuario>(this.usuariosUrl + '/salvar/', usuario)
      .toPromise();
  }

  async aceitaTermo(cpf: string): Promise<void> {
    const headers = new HttpHeaders();

    await this.http.put(this.usuariosUrl + '/aceitatermo/' + cpf, { headers })
      .toPromise();
    return null;
  }

  async duplaAprovacao(login: string, senha: string): Promise<any> {
    let params = new HttpParams();

    params = params.append('login', login);
    params = params.append('senha', senha);

    const response = await this.http.get<any>(`${this.usuariosUrl}/duplaaprovacao`, { params })
      .toPromise();
    return response;
  }

  async mostraCompartilhamento(login: string): Promise<any> {
    const response = await this.http.get<any>(`${this.usuariosUrl}/mostraCompartilhar/` + login)
      .toPromise();
    return response;
  }

  async compartilhaInfo(cpf: string, compartilhaInfo: Boolean): Promise<void> {
    const headers = new HttpHeaders();
    
    await this.http.put(this.usuariosUrl + '/compartilhaInfo/' + cpf + '/' + compartilhaInfo, { headers })
    .toPromise();
    return null;
    }
}
