import { DadosGeraisAprovacao } from "./DadosGeraisAprovacao";

export class DadosGeraisAprovacaoDTO3 {
  id: number;
  cpf: string; 
  imgCpfAtualizado: string;
  imgNomeAttFrente: string;
  imgNomeAttVerso: string;
  imgNomeSocialAttFrente: string;
  imgNomeSocialAttVerso: string;
  imgSexoAttFrente: string;
  imgSexoAttVerso: string;
  imgNascFrente: string;
  imgNascVerso: string;  
  imgNacionalidadeFrente: string;
  imgNacionalidadeVerso: string;  
  imgNumeroNisFrente: string;
  imgNumeroNisVerso: string;

  defFisicaFrente: string;
  defFisicaVerso: string;
  defVisualFrente: string;
  defVisualVerso: string;
  defAuditivaFrente: string;
  defAuditivaVerso: string;
  defMentalFrente: string;
  defMentalVerso: string;
  defIntelectualFrente: string;
  defIntelectualVerso: string;

  loginUsuario: string;
  loginSantander: string;
  loginRh: string;

  imagemAgente: string;

  

  constructor(dadosGeraisAprovacao: DadosGeraisAprovacao) {
    this.cpf = dadosGeraisAprovacao.cpf;    
    this.imgCpfAtualizado = dadosGeraisAprovacao.imgCpfAtualizado;
    this.imgNomeAttFrente = dadosGeraisAprovacao.imgNomeAttFrente;
    this.imgNomeAttVerso = dadosGeraisAprovacao.imgNomeAttVerso;
    this.imgNomeSocialAttFrente = dadosGeraisAprovacao.imgNomeSocialAttFrente;
    this.imgNomeSocialAttVerso = dadosGeraisAprovacao.imgNomeSocialAttVerso;
    this.imgSexoAttFrente = dadosGeraisAprovacao.imgSexoAttFrente;
    this.imgSexoAttVerso = dadosGeraisAprovacao.imgSexoAttVerso;
    this.imgNascFrente = dadosGeraisAprovacao.imgNascFrente;
    this.imgNascVerso = dadosGeraisAprovacao.imgNascVerso;    
    this.imgNacionalidadeFrente = dadosGeraisAprovacao.imgNacionalidadeFrente;
    this.imgNacionalidadeVerso = dadosGeraisAprovacao.imgNacionalidadeVerso;   
    this.imgNumeroNisFrente = dadosGeraisAprovacao.imgNumeroNisFrente;
    this.imgNumeroNisVerso = dadosGeraisAprovacao.imgNumeroNisVerso;
    this.defFisicaFrente = dadosGeraisAprovacao.defFisicaFrente;
    this.defFisicaVerso = dadosGeraisAprovacao.defFisicaVerso;
    this.defVisualFrente = dadosGeraisAprovacao.defVisualFrente;
    this.defVisualVerso = dadosGeraisAprovacao.defVisualVerso;
    this.defAuditivaFrente = dadosGeraisAprovacao.defAuditivaFrente;
    this.defAuditivaVerso = dadosGeraisAprovacao.defAuditivaVerso;
    this.defMentalFrente = dadosGeraisAprovacao.defMentalFrente;
    this.defMentalVerso = dadosGeraisAprovacao.defMentalVerso;
    this.defIntelectualFrente = dadosGeraisAprovacao.defIntelectualFrente;
    this.defIntelectualVerso = dadosGeraisAprovacao.defIntelectualVerso;
    this.imagemAgente = dadosGeraisAprovacao.imagemAgente;

   
  }
}
