import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { Endereco } from '../model/Endereco';
import { DadosGeraisAprovacao } from '../model/DadosGeraisAprovacao';
 
@Injectable({
  providedIn: 'root'
})
export class CepService {
  
  constructor(private http:Http) { }

  buscaCep(cep:string){
   return this.http.get(`https://viacep.com.br/ws/${cep}/json/`)
    .toPromise()
    .then(response => this.converterRespostaParaCep(response.json()));
    
  }

  converterRespostaParaCep(cepNaResposta):Endereco{
    
    let endereco = new Endereco();
    endereco.cep = cepNaResposta.cep;
    endereco.endereco = cepNaResposta.logradouro;
    endereco.complemento = cepNaResposta.complemento;
    endereco.bairro = cepNaResposta.bairro;
    endereco.municipio = cepNaResposta.localidade;
    endereco.uf = cepNaResposta.uf;
    return endereco;

  }
}
