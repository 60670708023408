import { Component, OnInit } from '@angular/core';
import { CepService } from './cep.service';
import { Endereco } from '../model/Endereco';
import { DadosGeraisAprovacao } from '../model/DadosGeraisAprovacao';
import { ToastyService } from 'ng2-toasty';


@Component({
  selector: 'app-cep',
  templateUrl: './cep.component.html',
  styleUrls: ['./cep.component.css']
})
export class CepComponent implements OnInit {

  endereco = new Endereco();
  dadosGeraisAprovacao: DadosGeraisAprovacao = new DadosGeraisAprovacao();
  
  constructor(private cepService:CepService,
    private toasty: ToastyService
    ) { }

  ngOnInit() {
  }

  buscar(){
    this.cepService.buscaCep(this.dadosGeraisAprovacao.endereco.cep)
     .then((endereco: Endereco) => this.dadosGeraisAprovacao.endereco = endereco)
     .catch(() => {
      //  let endereco = this.dadosGeraisAprovacao.endereco.cep;
      //  this.endereco = new Endereco();
      //  this.dadosGeraisAprovacao.endereco.cep = endereco;
       this.toasty.error('Verifique o CEP informado');
     })
  }

}
