import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-social',
  templateUrl: './e-social.component.html',
  styleUrls: ['/e-social.component.css']
})
export class ESocialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
