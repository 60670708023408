import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { SelectItem } from 'primeng/primeng';
import { environment } from 'src/environments/environment';

import { CombosService } from '../combos.service';
import { ErrorHandlerService } from '../core/error-handler.service';
import { Aposentadoria } from '../model/Aposentadoria';
import { CarteiraDeHabitacao } from '../model/CarteiraDeHabitacao';
import { CertidaoDependente } from '../model/CertidaoDependente';
import { DadosGeraisAprovacao } from '../model/DadosGeraisAprovacao';
import { DadosGeraisAprovacaoDetails } from '../model/DadosGeraisAprovacaoDetails';
import { Dependente } from '../model/Dependente';
import { Endereco } from '../model/Endereco';
import { EnderecoDependente } from '../model/EnderecoDependente';
import { EndExtrangeiro } from '../model/EndExtrangeiro';
import { Formacao } from '../model/Formacao';
import { IdentidadeProfissional } from '../model/IdentidadeProfissional';
import { InformacoesAdicionais } from '../model/InformacoesAdicionais';
import { RegistroGeralDependente } from '../model/RegistroGeralDependente';
import { RegNacionalExtrang } from '../model/RegNacionalExtrang';
import { Reservista } from '../model/Reservista';
import { Ric } from '../model/Ric';
import { TituloEleitoral } from '../model/TituloEleitoral';
import { RecadastramentoService } from '../recadastramento/recadastramento.service';
import { AuthService } from '../seguranca/auth.service';
import { FileDownloadService } from '../visualiza-recadastramento/file-download.service';
import { CarteiraProfissional } from './../model/CarteiraProfissional';
import { Certidao } from './../model/Certidao';
import { RegistroGeral } from './../model/RegistroGeral';

const MIME_TYPES = {
  jpg: "image/jpg",
  png: "image/png",
  pdf: "application/pdf",
};

@Component({
  selector: "app-detalhe-registro",
  templateUrl: "./detalhe-registro.component.html",
  styleUrls: ["detalhe-registro.component.css"],
})
export class DetalheRegistroComponent implements OnInit {
  dadosGeraisAprovacao: DadosGeraisAprovacao = new DadosGeraisAprovacao();
  dependente: Dependente;
  mensagem: string;
  cpf: string;
  visualiza: boolean;
  racaecor: SelectItem[];
  grauinstrucoes: SelectItem[];
  paises: SelectItem[];
  municipios: SelectItem[];
  ufs: SelectItem[];
  estadosCivis: SelectItem[];
  idGenero: SelectItem[];

  grupoSanguineo: SelectItem[];
  tiposCursos: SelectItem[];

  ptBr: any;
  tiposCertidao: SelectItem[];
  logradouros: SelectItem[];
  classificacaoes: SelectItem[];
  tiposDependentes: SelectItem[];
  bytearrayimgteste: any;
  erroDetalhe: any;
  temFoto: boolean = false;
  columns: number[];
  url: string;

  tipoDeUniao = new FormGroup({
    myRadioUniao: new FormControl(),
  });

  constructor(
    private recadastramentoService: RecadastramentoService,
    public auth: AuthService,
    private toasty: ToastyService,
    private errorHandler: ErrorHandlerService,
    private combo: CombosService,

    private activatedRoute: ActivatedRoute,
    private service: FileDownloadService,
    private _sanitizer: DomSanitizer
  ) {
    this.activatedRoute.queryParams.subscribe((parametros) => {
      if (parametros["cpf"]) {
        this.cpf = parametros["cpf"];
      }
      if (parametros["visualiza"]) {
        this.visualiza = parametros["visualiza"];
      }
    });

    this.erroDetalhe = activatedRoute.snapshot.queryParams.erro;
  }

  ngOnInit() {
    this.pesquisar();
    this.carregarCombos();
    this.dependente = new Dependente();
    this.columns = [];
  }

  addColumn() {
    this.columns.push(this.columns.length);
  }

  removeColumn() {
    this.columns.splice(-1, 1);
  }

  ngAfterViewChecked() {
    var chechbutton = document.getElementsByClassName("background-download");
    if (chechbutton.length !== 0) {
      var el = document.getElementsByClassName("background-download")[0]
        .parentElement.parentElement.parentElement.parentElement.parentElement
        .firstElementChild;
      el.setAttribute("style", "background: #ADD8E6 ");
    }
  }

  changeCss() {
    this.temFoto = false;
  }

  pesquisar() {
    this.recadastramentoService
      .pesquisar(this.cpf)
      .then((resultado) => {
        this.dadosGeraisAprovacao = resultado;
        if (
          resultado.fotoAgente != null &&
          resultado.fotoAgente != "" &&
          resultado.fotoAgente != undefined
        ) {
          let urlImg = resultado.cpf + "/" + resultado.fotoAgente;
          this.getimgurl(urlImg);
        }
        this.dadosGeraisAprovacao.fotoAgente = resultado.fotoAgente;
        this.dadosGeraisAprovacao.registroGeral =
          this.dadosGeraisAprovacao.registroGeral != null
            ? this.dadosGeraisAprovacao.registroGeral
            : new RegistroGeral();
        this.dadosGeraisAprovacao.aposentadoria =
          this.dadosGeraisAprovacao.aposentadoria != null
            ? this.dadosGeraisAprovacao.aposentadoria
            : new Aposentadoria();
        this.dadosGeraisAprovacao.carteiraDeHabilitacao =
          this.dadosGeraisAprovacao.carteiraDeHabilitacao != null
            ? this.dadosGeraisAprovacao.carteiraDeHabilitacao
            : new CarteiraDeHabitacao();
        this.dadosGeraisAprovacao.carteiraProfissional =
          this.dadosGeraisAprovacao.carteiraProfissional != null
            ? this.dadosGeraisAprovacao.carteiraProfissional
            : new CarteiraProfissional();
        this.dadosGeraisAprovacao.certidao =
          this.dadosGeraisAprovacao.certidao != null
            ? this.dadosGeraisAprovacao.certidao
            : new Certidao();
        this.dadosGeraisAprovacao.endereco =
          this.dadosGeraisAprovacao.endereco != null
            ? this.dadosGeraisAprovacao.endereco
            : new Endereco();
        this.dadosGeraisAprovacao.endExtrangeiro =
          this.dadosGeraisAprovacao.endExtrangeiro != null
            ? this.dadosGeraisAprovacao.endExtrangeiro
            : new EndExtrangeiro();
        this.dadosGeraisAprovacao.identidadeProfissional =
          this.dadosGeraisAprovacao.identidadeProfissional != null
            ? this.dadosGeraisAprovacao.identidadeProfissional
            : new IdentidadeProfissional();
        this.dadosGeraisAprovacao.infoAdicionais =
          this.dadosGeraisAprovacao.infoAdicionais != null
            ? this.dadosGeraisAprovacao.infoAdicionais
            : new InformacoesAdicionais();
        this.dadosGeraisAprovacao.regNacionalExtrang =
          this.dadosGeraisAprovacao.regNacionalExtrang != null
            ? this.dadosGeraisAprovacao.regNacionalExtrang
            : new RegNacionalExtrang();
        this.dadosGeraisAprovacao.reservista =
          this.dadosGeraisAprovacao.reservista != null
            ? this.dadosGeraisAprovacao.reservista
            : new Reservista();
        this.dadosGeraisAprovacao.tituloEleitoral =
          this.dadosGeraisAprovacao.tituloEleitoral != null
            ? this.dadosGeraisAprovacao.tituloEleitoral
            : new TituloEleitoral();
        this.dadosGeraisAprovacao.dependentes =
          this.dadosGeraisAprovacao.dependentes != null
            ? this.dadosGeraisAprovacao.dependentes
            : ([] as Dependente[]);
        this.dadosGeraisAprovacao.formacoes =
          this.dadosGeraisAprovacao.formacoes != null
            ? this.dadosGeraisAprovacao.formacoes
            : ([] as Formacao[]);
        this.dadosGeraisAprovacao.visualiza = this.visualiza;
        this.dadosGeraisAprovacao.ric =
          this.dadosGeraisAprovacao.ric != null
            ? this.dadosGeraisAprovacao.ric
            : new Ric();
        if (
          this.dadosGeraisAprovacao.arquivoInformacao == undefined ||
          this.dadosGeraisAprovacao.arquivoInformacao == "" ||
          this.dadosGeraisAprovacao.arquivoInformacao != "N"
        ) {
          this.dadosGeraisAprovacao.arquivoInformacao = "S";
        }
        if (this.dadosGeraisAprovacao.dependentes.length > 0) {
          this.dependente = this.dadosGeraisAprovacao.dependentes[0];
          if (
            this.dependente.regGeralDependente == null ||
            this.dependente.regGeralDependente == undefined
          ) {
            this.dependente.regGeralDependente = new RegistroGeralDependente();
          }
          if (
            this.dependente.certidao == null ||
            this.dependente.certidao == undefined
          ) {
            this.dependente.certidao = new CertidaoDependente();
          }
          if (
            this.dependente.endDependente == null ||
            this.dependente.endDependente == undefined
          ) {
            this.dependente.endDependente = new EnderecoDependente();
          }
        }
        this.loadDetails(this.cpf);

      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  loadDetails(cpf: string) {
    this.recadastramentoService.getDetails(cpf).then((res: DadosGeraisAprovacaoDetails) => {
        this.dadosGeraisAprovacao.registroGeral = res.registroGeral || new RegistroGeral();
        this.dadosGeraisAprovacao.tituloEleitoral = res.tituloEleitoral || new TituloEleitoral();
        this.dadosGeraisAprovacao.carteiraDeHabilitacao = res.carteiraDeHabilitacao || new CarteiraDeHabitacao();
        this.dadosGeraisAprovacao.carteiraProfissional = res.carteiraProfissional || new CarteiraProfissional();
        this.dadosGeraisAprovacao.reservista = res.reservista || new Reservista();
        this.dadosGeraisAprovacao.identidadeProfissional = res.identidadeProfissional || new IdentidadeProfissional();
        this.dadosGeraisAprovacao.certidao = res.certidao || new Certidao();
        this.dadosGeraisAprovacao.endereco = res.endereco || new Endereco();
        this.dadosGeraisAprovacao.ric = res.ric || new Ric();
        this.dadosGeraisAprovacao.regNacionalExtrang = res.regNacionalExtrang || new RegNacionalExtrang();
        this.dadosGeraisAprovacao.infoAdicionais = res.infoAdicionais || new InformacoesAdicionais();
        this.dadosGeraisAprovacao.endExtrangeiro = res.endExtrangeiro || new EndExtrangeiro();
        this.dadosGeraisAprovacao.aposentadoria = res.aposentadoria || new Aposentadoria();
        this.dadosGeraisAprovacao.dependentes = res.dependentes;
        this.dadosGeraisAprovacao.formacoes = res.formacoes;
        this.toasty.success('Todas as informações foram carregadas');
    })
}

  getimgurl(caminho: string) {
    this.recadastramentoService
      .getImage(caminho)
      .then((resultado) => {
        this.temFoto = true;
        this.bytearrayimgteste = this._sanitizer.bypassSecurityTrustResourceUrl(
          "data:image/jpg;base64," + resultado.message
        );
        //this.bytearrayimgteste = resultado.message;
        //document.getElementsByClassName("ui-fileupload-content")[0].insertAdjacentHTML('beforeend',"<img id='imgOld' style=\"width:126%;height:111%;margin-left: -15px;margin-top: -11px; overflow: hidden;background-size: cover;background-position: center;background-image:url('data:image/png;base64,"+this.bytearrayimgteste+"')\"></img>");

        // document.getElementsByClassName("ui-fileupload-content")[0].insertAdjacentHTML('beforeend',"<img id='imgOld' [src]='\"this.bytearrayimgteste\"' style=\"width:126%;height:111%;margin-left: -15px;margin-top: -11px; overflow: hidden;background-size: cover;background-position: center;\"></img>");
      })
      .catch((erro) => {
        this.errorHandler.handle(erro);
      });
  }

  carregarCombos() {
    this.racaecor = this.combo.racaecor;
    this.grauinstrucoes = this.combo.grauinstrucoes;
    this.paises = this.combo.paises;
    this.municipios = this.combo.municipios;
    this.ufs = this.combo.ufs;
    this.estadosCivis = this.combo.estadosCivis;
    this.tiposCursos = this.combo.tiposCursos;
    this.grupoSanguineo = this.combo.grupoSanguineo;
    this.ptBr = this.combo.ptBr;
    this.tiposCertidao = this.combo.tiposCertidao;
    this.logradouros = this.combo.tipoLogradouros;
    this.classificacaoes = this.combo.tiposClassificacao;
    this.tiposDependentes = this.combo.tiposDependentes;
    this.idGenero = this.combo.idGenero;
  }

  editarDep(dependente: any) {
    this.dependente = JSON.parse(JSON.stringify(dependente));
    if (this.dependente.regGeralDependente == null) {
      this.dependente.regGeralDependente = new RegistroGeralDependente();
    }
    if (this.dependente.certidao == null) {
      this.dependente.certidao = new CertidaoDependente();
    }
  }

  downloadFile(fileName) {
    const fileNameDowload =
      environment.anexos + this.dadosGeraisAprovacao.cpf + "/" + fileName;
    this.getimgurlTeste(this.dadosGeraisAprovacao.cpf + "/" + fileName);
    // let extension = fileNameDowload.substr(fileNameDowload.lastIndexOf('.') + 1)
    // if(extension == "jpeg"){
    //   extension = "jpg";
    // }
    // this.service.downloadFile({ 'fileName': fileNameDowload })
    //   .subscribe(data => {
    //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //       window.navigator.msSaveOrOpenBlob(new Blob([data], { type: MIME_TYPES[extension] }), fileName);
    //     } else {
    //       window.open(URL.createObjectURL(new Blob([data], { type: MIME_TYPES[extension] })));
    //     }
    //   })
  }

  getimgurlTeste(caminho: string) {
    this.recadastramentoService
      .getImage(caminho)
      .then((resultado) => {
        let teste = this._sanitizer.sanitize(
          SecurityContext.RESOURCE_URL,
          this._sanitizer.bypassSecurityTrustResourceUrl(
            "data:image/jpg;base64," + resultado.message
          )
        );
        var w = window.open("about:blank");

        setTimeout(function () {
          //FireFox seems to require a setTimeout for this to work.
          w.document.body.appendChild(w.document.createElement("iframe")).src =
            teste;
          w.document.getElementsByTagName("iframe")[0].style.width = "100%";

          w.document.getElementsByTagName("iframe")[0].style.height = "100%";
        }, 0);
        window.open(teste);
      })
      .catch((erro) => {
        this.errorHandler.handle(erro);
      });
  }

  reenviar(status) {
    this.dadosGeraisAprovacao.status = status;
    this.dadosGeraisAprovacao.loginRh = this.auth.jwtPayload.cpf;
    this.recadastramentoService
      .aprovar(this.dadosGeraisAprovacao)
      .then(() => {
        if (status == 2) {
          this.toasty.success("Aprovação realizada com sucesso!");
        } else {
          this.toasty.success("Reprovação realizada com sucesso!");
        }
        this.dadosGeraisAprovacao.visualiza = true;
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  rgFrenteUpload(event, upload: any) {
    if (this.dadosGeraisAprovacao.docNascFrente == null) {
      this.recadastramentoService
        .salvarAnexo(
          event,
          "_FRENTE",
          "_CERTNASC",
          this.dadosGeraisAprovacao.docNascFrente,
          null
        )
        .then((resultado) => {
          this.dadosGeraisAprovacao.docNascFrente = resultado.message;
          upload.chooseLabel = this.dadosGeraisAprovacao.docNascFrente;
        })
        .catch((erro) => this.errorHandler.handle(erro));
    } else {
      this.dadosGeraisAprovacao.docNascFrente = null;
      upload.chooseLabel = "Doc RG Frente";
      upload.clear();
    }
  }

  rgVersoUpload(event, upload: any) {
    if (this.dadosGeraisAprovacao.docNascVerso == null) {
      this.recadastramentoService
        .salvarAnexo(
          event,
          "_VERSO",
          "_CERTNASC",
          this.dadosGeraisAprovacao.docNascVerso,
          null
        )
        .then((resultado) => {
          this.dadosGeraisAprovacao.docNascVerso = resultado.message;
          upload.chooseLabel = this.dadosGeraisAprovacao.docNascVerso;
        })
        .catch((erro) => this.errorHandler.handle(erro));
    } else {
      this.dadosGeraisAprovacao.docNascVerso = null;
      upload.chooseLabel = "Doc RG Verso";
      upload.clear();
    }
  }

  visualizarDependente(dep: Dependente) {
    this.dependente = dep;
    if (
      this.dependente.regGeralDependente == null ||
      this.dependente.regGeralDependente == undefined
    ) {
      this.dependente.regGeralDependente = new RegistroGeralDependente();
    }
    if (
      this.dependente.certidao == null ||
      this.dependente.certidao == undefined
    ) {
      this.dependente.certidao = new CertidaoDependente();
    }
    if (
      this.dependente.endDependente == null ||
      this.dependente.endDependente == undefined
    ) {
      this.dependente.endDependente = new EnderecoDependente();
    }
  }
}
