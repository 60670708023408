import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import {
  MatAutocompleteModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
} from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { GoogleChartsModule } from "angular-google-charts";
import { MglTimelineModule } from "angular-mgl-timeline";
import { ChartsModule } from "ng2-charts";
import { Ng2ImgMaxModule } from "ng2-img-max";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MultiSelectModule } from "primeng/multiselect";
import {
  AccordionModule,
  DataTableModule,
  DropdownModule,
  FieldsetModule,
  FileUploadModule,
  InputTextModule,
  PaginatorModule,
  PanelModule,
  ProgressSpinnerModule,
} from "primeng/primeng";
import { RadioButtonModule } from "primeng/radiobutton";
import { TableModule } from "primeng/table";
import { TooltipModule } from "primeng/tooltip";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ConcluirRecadastramentoComponent } from "./concluir-recadastramento/concluir-recadastramento.component";
import { CoreModule } from "./core/core.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DetalheRegistroComponent } from "./detalhe-registro/detalhe-registro.component";
import { DuplaAprovacaoComponent } from "./dupla-aprovacao/dupla-aprovacao.component";
import { FaqComponent } from "./faq/faq.component";
import { HeaderMenuComponent } from "./header-menu/header-menu.component";
import { RegistroRejeitadoComponent } from "./registro-rejeitado/registro-rejeitado.component";
import { SegurancaModule } from "./seguranca/seguranca.module";
import { TimelineComponent } from "./timeline/timeline.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderMenuComponent,
    FaqComponent,
    TimelineComponent,
    DashboardComponent,
    ConcluirRecadastramentoComponent,
    DuplaAprovacaoComponent,
    DetalheRegistroComponent,
    RegistroRejeitadoComponent,
  ],
  imports: [
    ButtonModule,
    BrowserModule,
    MultiSelectModule,
    BrowserAnimationsModule,
    CoreModule, // <----------------
    AutoCompleteModule,
    CalendarModule,
    SegurancaModule,
    AppRoutingModule,
    DataTableModule,
    PaginatorModule,
    FileUploadModule,
    MatProgressSpinnerModule,
    AccordionModule,
    FieldsetModule,
    ChartModule,
    MglTimelineModule,
    BrowserAnimationsModule,
    GoogleChartsModule,
    CheckboxModule,
    HttpModule,
    FormsModule,
    MatAutocompleteModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    DropdownModule,
    Ng2ImgMaxModule,
    PanelModule,
    RadioButtonModule,
    InputTextModule,
    InputTextareaModule,
    FontAwesomeModule,
    ChartsModule,
    TooltipModule,
    TableModule,
    DialogModule,
    ConfirmDialogModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
