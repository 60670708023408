import { IRegistros } from "./../model/RegistrosRejeitados";
import { RecadastramentoHttp } from "./../seguranca/recadastramentoHttp";
import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RegistroRejeitadoService {
  lotacaoUrl: string;
  usuariosUrl: any;

  constructor(private http: RecadastramentoHttp) {
    this.lotacaoUrl = `${environment.apiUrl}/relatorio/xml`;
  }

  async all(): Promise<any> {
    const resp = await this.http
      .get<any>(`${this.lotacaoUrl}/consulta`)
      .toPromise();
    const data = resp as IRegistros[];
    return data;
  }
}
