export class IRegistros {
  nomeArquivo: string;
  cpf: IErro;
  descricao: IErro;
}

export class IErro {
  descricaoErro: string;
  cpfTitularErro: string;
}
