export class Endereco {
  tipoLograd: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  municipio: string;
  uf: string;
  cep: string;
  telPrincip: string;
  telAlter: string;
  emailPrincip: string;
  emailAlter: string;
}
