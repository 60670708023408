import { NotificationService } from './notification.service';
import 'rxjs/add/operator/map';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Http } from '@angular/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ToastyService } from 'ng2-toasty';
import { MessageService, SelectItem } from 'primeng/primeng';
import { environment } from 'src/environments/environment';

import { CombosService } from '../combos.service';
import { ErrorHandlerService } from '../core/error-handler.service';
import { EstadoMunicipioService } from '../estado-municipio.service';
import { Aposentadoria } from '../model/Aposentadoria';
import { CarteiraDeHabitacao } from '../model/CarteiraDeHabitacao';
import { CertidaoDependente } from '../model/CertidaoDependente';
import { DadosGeraisAprovacao } from '../model/DadosGeraisAprovacao';
import { DadosGeraisAprovacaoDetails } from '../model/DadosGeraisAprovacaoDetails';
import { DadosGeraisAprovacaoDTO1 } from '../model/DadosGeraisAprovacaoDTO1';
import { DadosGeraisAprovacaoDTO2 } from '../model/DadosGeraisAprovacaoDTO2';
import { DadosGeraisAprovacaoDTO3 } from '../model/DadosGeraisAprovacaoDTO3';
import { Dependente } from '../model/Dependente';
import { Endereco } from '../model/Endereco';
import { EnderecoDependente } from '../model/EnderecoDependente';
import { EndExtrangeiro } from '../model/EndExtrangeiro';
import { Formacao } from '../model/Formacao';
import { IdentidadeProfissional } from '../model/IdentidadeProfissional';
import { InformacoesAdicionais } from '../model/InformacoesAdicionais';
import { RegNacionalExtrang } from '../model/RegNacionalExtrang';
import { Reservista } from '../model/Reservista';
import { Ric } from '../model/Ric';
import { TituloEleitoral } from '../model/TituloEleitoral';
import { AuthService } from '../seguranca/auth.service';
import { UserSantanderService } from '../user-santander/user-santander.service';
import { CarteiraProfissional } from './../model/CarteiraProfissional';
import { Certidao } from './../model/Certidao';
import { RegistroGeral } from './../model/RegistroGeral';
import { RegistroGeralDependente } from './../model/RegistroGeralDependente';
import { RecadastramentoService } from './recadastramento.service';
import { JsonMessage } from '../model/JsonMessage';
import Swal from "sweetalert2";


@Component({
  selector: "app-recadastramento",
  templateUrl: "./recadastramento.component.html",
  styleUrls: ["./recadastramento.component.css"],
  animations: [
    trigger("animation", [
      state(
        "visible",
        style({
          transform: "translateX(0)",
          opacity: 1,
        })
      ),
      transition("void => *", [
        style({ transform: "translateX(50%)", opacity: 0 }),
        animate("300ms ease-out"),
      ]),
      transition("* => void", [
        animate(
          "250ms ease-in",
          style({
            height: 0,
            opacity: 0,
            transform: "translateX(50%)",
          })
        ),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RecadastramentoComponent implements OnInit {
  //Modalidades permitidas para Ativar o curso de formação
  private modalidadesComCursoFormacao = [
    "Nível Médio Técnico",
    "Nível Superior",
    "Pós-Graduação",
    "Mestrado",
    "Doutorado",
    "Pós-Doutorado",
  ];

  myCheckbox: FormControl = new FormControl();
  ativo: boolean;

  dadosGeraisAprovacao: DadosGeraisAprovacao = new DadosGeraisAprovacao();
  dadosGeraisOld: DadosGeraisAprovacao = new DadosGeraisAprovacao();
  dependente: Dependente;
  formacao: Formacao;
  formacaoForEdit = new Formacao();
  dependenteOld = new Dependente();
  formacaoOld = new Formacao();
  registroGeralDependente: RegistroGeralDependente;
  isLoad: boolean;
  dadosGeraisDto2: DadosGeraisAprovacaoDTO2;
  userIP: any;


  oldDataNas: any;
  oldNis: any;
  oldNome: any;
  servidor: any;
  fotoForm: FormGroup;
  estadoCivil: any;
  grupoSanguineo: SelectItem[];
  isCarregandoDados: boolean = true;


  tipoDeUniao = new FormGroup({
    myRadioUniao: new FormControl(),
  });

  tipoSanguineo: SelectItem[];

  racaecor: SelectItem[];
  grauinstrucoes: SelectItem[];
  paises: SelectItem[];
  municipios: SelectItem[];
  municipiosDoc: SelectItem[];
  ufs: SelectItem[];
  estadosCivis: SelectItem[];
  idGenero: SelectItem[];
  ptBr: any;
  tiposCertidao: SelectItem[];
  tiposCertidaoDependente: SelectItem[];
  tiposCursos: SelectItem[];
  logradouros: SelectItem[];
  classificacaoes: SelectItem[];
  tiposDependentes: SelectItem[];
  paisesExt: SelectItem[];
  categoriaCnh: SelectItem[];
  results: any[] = [];

  showUpTiPoSanguineto: boolean = false;

  showUpNome: boolean = false;
  exibirAnexosNome: boolean = true;

  showUpCpf: boolean = false;
  exibirAnexosCpf: boolean = true;

  showUpNomeSocial: boolean = false;
  exibirAnexosNomeSocial: boolean = true;

  showUpDefFisica: boolean = false;
  exibirAnexosDefFisica: boolean = true;

  showUpDefVisual: boolean = false;
  exibirAnexosDefVisual: boolean = true;

  showUpDefAuditiva: boolean = false;
  exibirAnexosDefAuditiva: boolean = true;

  showUpDefMental: boolean = false;
  exibirAnexosDefMental: boolean = true;

  showUpDefIntelectual: boolean = false;
  exibirAnexosDefIntelectual: boolean = true;

  showUpSexo: boolean = false;
  exibirAnexosSexo: boolean = true;

  showUpNumNis: boolean = false;
  exibirAnexosNumNis: boolean = true;

  showUpRG: boolean = false;
  exibirAnexosRG: boolean = true;

  showUpTitulo: boolean = false;
  exibirAnexosTitulo: boolean = true;

  showUpCnh: boolean = false;
  exibirAnexosCnh: boolean = true;

  showUpCtps: boolean = false;
  exibirAnexosCtps: boolean = true;

  showUpReservista: boolean = false;
  exibirAnexosReservista: boolean = true;

  showUpIp: boolean = false;
  exibirAnexosIp: boolean = true;

  showUpCertidao: boolean = false;
  exibirAnexosCertidao: boolean = true;

  showUpTipodeUniao: boolean = false;
  exibirAnexosTipodeUniao: boolean = true;

  showUpRne: boolean = false;
  exibirAnexosRne: boolean = true;

  showUpPais: boolean = false;
  exibirAnexosPais: boolean = true;

  showUpNomeDep: boolean = false;
  exibirAnexosNomeDep: boolean = true;

  showUpCpfDep: boolean = false;
  exibirAnexosCpfDep: boolean = true;

  showDocNasc: boolean = false;
  exibirAnexosDocNasc: boolean = true;

  showDocCertidao: boolean = false;
  exibirAnexosDocCertidao: boolean = true;

  showUpDepSexo: boolean = false;
  exibirAnexosDepSexo: boolean = true;

  showUpDefFisicaDep: boolean = false;
  exibirAnexosDefFisicaDep: boolean = true;

  showUpDefVisualDep: boolean = false;
  exibirAnexosDefVisualDep: boolean = true;

  showUpDefAuditivaDep: boolean = false;
  exibirAnexosDefAuditivaDep: boolean = true;

  showUpDefMentalDep: boolean = false;
  exibirAnexosDefMentalDep: boolean = true;

  showUpDefIntelectualDep: boolean = false;
  exibirAnexosDefIntelectualDep: boolean = true;

  showUpRGDep: boolean = false;
  exibirAnexosRGDep: boolean = true;

  showUpCertidaoDep: boolean = false;
  exibirAnexosCertidaoDep: boolean = true;

  showUpCurso: boolean = false;


  read: boolean = false;
  display: boolean = false;
  displayNome: boolean = false;
  salvarButton: boolean = true;
  id: String;

  depCivil: String;
  endereco = new Endereco();
  desativaBotaoParcialmente: boolean;
  desativaBotaoConcluir: boolean;

  uploadedFiles: any[] = [];
  columns: number[];
  imageSrc: any;
  disabledEnd: boolean = false;
  campoForm: boolean = false;
  uniaoEstavel: boolean = false;
  checked: boolean = false;
  dadosGeraisAprovacaoUrl: string;
  urlImg: string;
  bytearrayimgteste: any;
  bytearrayimgbanner: any;
  temFoto: boolean = false;
  cssClass: boolean = false;
  docCargoObg: boolean = false;

  urlBanner: string = "http://localhost:4200/assets/image/logopositivo.png";

  displayModal: boolean;
  imgSet: String;
  idade: number;
  imgPreview: string | ArrayBuffer;
  imagemComprimida: Event;
  cpfAtualizado = new FormControl("1", Validators.pattern("^[a-zA-Z0-9.-]+$"));

  cursoFormacaoDisabled: boolean = true;
  block = false;
  display2: boolean;
  display3: boolean;
  base64textStringFrente: string;
  base64textStringVerso: string;
  base64textStringAgente: string;

  base64textStringdefFisicaFrente: string;
  base64textStringdefFisicaVerso: string;
  base64textStringdefVisualFrente: string;
  base64textStringdefVisualVerso: string;
  base64textStringdefAuditivaFrente: string;
  base64textStringdefAuditivaVerso: string;
  base64textStringdefMentalFrente: string;
  base64textStringdefMentalVerso: string;
  base64textStringdefIntelectualFrente: string;
  base64textStringdefIntelectualVerso: string;
  base64textStringTituloFrente: string;
  base64textStringTituloVerso: string;
  base64textStringCnhFrente: string;
  base64textStringCnhVerso: string;
  base64textStringCpfAtualizado: string;
  base64textStringNomeFrenteUpload: string;
  base64textStringNomeVersoUpload: string;
  base64textStringNacionalidadeFrente: string;
  base64textStringNacionalidadeVerso: string;
  base64textStringCtpsFrente: string;
  base64textStringCtpsVerso: string;
  base64textStringNisFrente: string;
  base64textStringNisVerso: string;
  base64textStringReservistaFrente: string;
  base64textStringReservistaVerso: string;
  base64textStringNomeSocialFrente: string;
  base64textStringNomeSocialVerso: string;
  base64textStringSexoFrente: string;
  base64textStringSexoVerso: string;
  base64textStringDadosNascFrente: string;
  base64textStringDefFisicaFrenteDep: string;
  base64textStringRegExteriorFrente: string;
  base64textStringRegNacionalEstrangeiroFrente: string;
  base64textStringRegNacionalEstrangeiroVerso: string;
  base64textStringUniaoEstavelFrente: string;
  base64textStringCertidaoVerso: string;
  base64textStringuniaoEstavelFrenteUpload: string;
  base64textStringuniaoEstavelVersoUpload: string;
  base64textStringNomeDepFrenteUpload: string;
  base64textStringNomeDepVersoUpload: string;
  base64textStringIpFrente: string;
  base64textStringIpVerso: string;
  base64textStringCpfDepFrente: string;
  base64textStringCpfDepVerso: string;
  base64textStringDadosNascVerso: string;
  base64textStringDefFisicaVersoDep: string;
  base64textStringDefVisualFrenteDep: string;
  base64textStringDefVisualVersoDep: string;
  base64textStringDefAuditivoFrenteDep: string;
  base64textStringDefAuditivoVersoDep: string;
  base64textStringDefMentalFrenteDep: string;
  base64textStringDefMentalVersoDep: string;
  base64textStringDefIntelectualFrenteDep: string;
  base64textStringDefIntelectualVersoDep: string;
  base64textStringRgDepFrente: string;
  base64textStringRgDepVerso: string;
  isLoadParcial: boolean;
  isLoadFinal: boolean;

  base64textStringSexoDepVersoUpload: string;
  base64textStringSexoDepFrenteUpload: string;
  showDataNascimento: boolean;
  showMunicipioNascimento: boolean;
  showNomePai: boolean;
  showNomeMae: boolean;
  showUFNascimento: boolean;
  isLoadImagem: boolean;

  constructor(
    private recadastramentoService: RecadastramentoService,
    private userSantanderService: UserSantanderService,
    public auth: AuthService,
    private toasty: ToastyService,
    private errorHandler: ErrorHandlerService,
    private combo: CombosService,
    private estadoMunicipio: EstadoMunicipioService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private http: Http,
    private httpcliente: HttpClient,
    private cs: FormBuilder,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private el: ElementRef,
    private ng2ImgMax: Ng2ImgMaxService,
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) {
    this.servidor = activatedRoute.snapshot.queryParams;
    this.dadosGeraisAprovacaoUrl = `${environment.apiUrl}/aprovacao/salvar/image/anexo`;
    this.imgSet =
      "http://localhost:8080/api/recadastrario/image/anexo/3745420748/TIT_3745420748_FOTO.jpeg";
    this.ativo = false;

    // Verifica se a chk dos termos está ativada ou desativada
    this.myCheckbox.valueChanges.subscribe((value) => {
      this.ativo = this.myCheckbox.value;
      this.hide_button(value);
    });
  }

  ngOnInit() {
    this.mostraCompartilhamento();
    this.loadUserInfo();
  }

  showModalDialog() {
    this.displayModal = true;
  }



  // Registra o IP de quem está cadastrando
  loadUserInfo() {
    this.httpcliente.get<{ ip: string }>('https://api.ipify.org/?format=json')
      .subscribe(data => {
        this.userIP = data.ip;
      });
  }

  async mostraCompartilhamento() {
    await this.userSantanderService
      .mostraCompartilhamento(this.auth.jwtPayload.cpf)
      .then((resultado) => {
        if (resultado == true) {
          this.display2 = true;
        } else if (resultado == false) {
          this.carregarComponentes();
        }
      });
  }

  carregarComponentes() {
    this.pesquisar();
    this.carregarCombos();
    this.dependente = new Dependente();
    this.formacao = new Formacao();
    this.dependenteOld = new Dependente();
    this.formacaoOld = new Formacao();
    this.columns = [0];
    this.municipios = [];
  }

  // Carrega os dados do servidor para recadastro
  aceitar() {
    if (this.ativo) {
      this.userSantanderService.compartilhaInfo(this.auth.jwtPayload.cpf, true);
      this.display2 = false;
      this.carregarComponentes();
    }
  }

  // Chama a tela de recusa dos termos
  cancelar() {
    this.display2 = false;
    this.display3 = true;
  }

  // Chama a tela de consulta novamente ao recusar os termos
  avisoTermos() {
    this.display3 = false;
    this.router.navigate(["./consulta-recadastramento"]);
  }

  addColumn() {
    this.columns.push(this.columns.length);
  }

  removeColumn() {
    this.columns.splice(-1, 1);
  }
  setImageBackground() {
    let url = this.dadosGeraisAprovacaoUrl + "/" + this.urlImg;
  }

  buscaFormacao(event) {
    this.results = [];
    this.recadastramentoService
      .searchFormacao(event)
      .then((x) => {
        this.results = this.filterCurso(event.query, x);
        console.log("buscaFormacao.results: " + this.results);
      })
      .catch((erro) => console.log("erro " + erro));
  }

  filterCurso(query, cursos: any[]): string[] {
    let filtered: string[] = [];
    for (let i = 0; i < cursos.length; i++) {
      let curso = cursos[i];
      if (curso.nomeCurso.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(curso.nomeCurso);
      }
    }
    return filtered;
  }

  changeCss() {
    this.temFoto = false;
  }

  getimgurlAgente(cpf: string) {
    let status = this.dadosGeraisAprovacao.status;

    if (status == 0) {
      this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "", campo: "FOTO" })
        .then((resultado) => {
          if (resultado.message != "Arquivo não localizado no servidor") {
            this.temFoto = true;
            this.bytearrayimgteste =
              this._sanitizer.bypassSecurityTrustResourceUrl(
                "data:image/jpg;base64," + resultado.message
              );
            document
              .getElementsByClassName("ui-fileupload-content")[0]
              .insertAdjacentHTML(
                "beforeend",
                "<img id='imgOld' style=\"width:126%;height:111%;margin-left: -15px;margin-top: -11px; overflow: hidden;background-size: cover;background-position: center;background-image:url('data:image/png;base64," +
                resultado.message +
                "')\"></img>"
              );
          } else {
            this.toasty.info("Arquivo não localizado no servidor:  ");
          }
        })
        .catch((erro) => {
          this.errorHandler.handle(erro);
        });
    }
  }

  // Carrega as informações do banco de dados no front para exibir as opções de cadastro
  pesquisar() {
    this.isLoad = true;
    this.recadastramentoService
      .pesquisar(
        this.servidor != null && this.servidor.cpf != undefined
          ? this.servidor.cpf
          : this.auth.jwtPayload.cpf
      )
      .then((resultado) => {
        this.dadosGeraisAprovacao = resultado;
        this.loadDetails(this.servidor != null && this.servidor.cpf != undefined
          ? this.servidor.cpf
          : this.auth.jwtPayload.cpf);
        this.carregarCombos();

        //Carrega o banner caso o status seja igual a 1 ou igual a 2
        if (
          this.dadosGeraisAprovacao.status == 1 ||
          this.dadosGeraisAprovacao.status == 2
        ) {
          this.carregarBanner();
        }

        if (
          resultado.fotoAgente != null &&
          resultado.fotoAgente != "" &&
          resultado.fotoAgente != undefined
        ) {
          let urlImg = resultado.cpf;
          this.getimgurlAgente(urlImg);
        }

        this.recadastramentoService
          .pesquisarCargo(this.dadosGeraisAprovacao.cargoEfetivo01)
          .then((x) => {
            var contCargo = x;
            if (x > 0) {
              this.docCargoObg = true;
            }
          })
          .catch((erro) => {
            console.log("cargo inexistente");
          });

        this.docCargoObg;

        this.dadosGeraisAprovacao.registroGeral =
          this.dadosGeraisAprovacao.registroGeral != null
            ? this.dadosGeraisAprovacao.registroGeral
            : new RegistroGeral();
        this.dadosGeraisAprovacao.aposentadoria =
          this.dadosGeraisAprovacao.aposentadoria != null
            ? this.dadosGeraisAprovacao.aposentadoria
            : new Aposentadoria();
        this.dadosGeraisAprovacao.carteiraDeHabilitacao =
          this.dadosGeraisAprovacao.carteiraDeHabilitacao != null
            ? this.dadosGeraisAprovacao.carteiraDeHabilitacao
            : new CarteiraDeHabitacao();
        this.dadosGeraisAprovacao.carteiraProfissional =
          this.dadosGeraisAprovacao.carteiraProfissional != null
            ? this.dadosGeraisAprovacao.carteiraProfissional
            : new CarteiraProfissional();
        this.dadosGeraisAprovacao.certidao =
          this.dadosGeraisAprovacao.certidao != null
            ? this.dadosGeraisAprovacao.certidao
            : new Certidao();
        this.dadosGeraisAprovacao.endereco =
          this.dadosGeraisAprovacao.endereco != null
            ? this.dadosGeraisAprovacao.endereco
            : new Endereco();
        this.dadosGeraisAprovacao.endExtrangeiro =
          this.dadosGeraisAprovacao.endExtrangeiro != null
            ? this.dadosGeraisAprovacao.endExtrangeiro
            : new EndExtrangeiro();
        this.dadosGeraisAprovacao.identidadeProfissional =
          this.dadosGeraisAprovacao.identidadeProfissional != null
            ? this.dadosGeraisAprovacao.identidadeProfissional
            : new IdentidadeProfissional();
        this.dadosGeraisAprovacao.infoAdicionais =
          this.dadosGeraisAprovacao.infoAdicionais != null
            ? this.dadosGeraisAprovacao.infoAdicionais
            : new InformacoesAdicionais();
        this.dadosGeraisAprovacao.regNacionalExtrang =
          this.dadosGeraisAprovacao.regNacionalExtrang != null
            ? this.dadosGeraisAprovacao.regNacionalExtrang
            : new RegNacionalExtrang();
        this.dadosGeraisAprovacao.reservista =
          this.dadosGeraisAprovacao.reservista != null
            ? this.dadosGeraisAprovacao.reservista
            : new Reservista();
        this.dadosGeraisAprovacao.tituloEleitoral =
          this.dadosGeraisAprovacao.tituloEleitoral != null
            ? this.dadosGeraisAprovacao.tituloEleitoral
            : new TituloEleitoral();
        this.dadosGeraisAprovacao.dependentes =
          this.dadosGeraisAprovacao.dependentes != null
            ? this.dadosGeraisAprovacao.dependentes
            : ([] as Dependente[]);
        this.dadosGeraisAprovacao.formacoes =
          this.dadosGeraisAprovacao.formacoes != null
            ? this.dadosGeraisAprovacao.formacoes
            : ([] as Formacao[]);
        this.dadosGeraisAprovacao.ric =
          this.dadosGeraisAprovacao.ric != null
            ? this.dadosGeraisAprovacao.ric
            : new Ric();

        if (
          this.dependente.defFisica == undefined ||
          this.dependente.defFisica == "" ||
          this.dependente.defFisica != "S"
        ) {
          this.dependente.defFisica = "N";
        }
        if (
          this.dependente.defVisual == undefined ||
          this.dependente.defVisual == "" ||
          this.dependente.defVisual != "S"
        ) {
          this.dependente.defVisual = "N";
        }
        if (
          this.dependente.defAuditiva == undefined ||
          this.dependente.defAuditiva == "" ||
          this.dependente.defAuditiva != "S"
        ) {
          this.dependente.defAuditiva = "N";
        }
        if (
          this.dependente.defMental == undefined ||
          this.dependente.defMental == "" ||
          this.dependente.defMental != "S"
        ) {
          this.dependente.defMental = "N";
        }
        if (
          this.dependente.defIntelectual == undefined ||
          this.dependente.defIntelectual == "" ||
          this.dependente.defIntelectual != "S"
        ) {
          this.dependente.defIntelectual = "N";
        }

        this.dadosGeraisAprovacao.dependentes.forEach((dp) => {
          if (dp.endDependente == null || dp.endDependente == undefined) {
            dp.endDependente = new EnderecoDependente();
          }
          if (
            dp.regGeralDependente == null ||
            dp.regGeralDependente == undefined
          ) {
            dp.regGeralDependente = new RegistroGeralDependente();
          }
          dp.cpfAtualizado = dp.cpf;
        });

        this.mudouCertidaoInicio();
        this.oldDataNas = this.dadosGeraisAprovacao.dataNascimento;
        this.oldNis = this.dadosGeraisAprovacao.numeroNis;
        this.oldNome = this.dadosGeraisAprovacao.nome;
        // this.isLoad = false;

      })
      .catch((erro) => {
        this.isLoad = false;
        this.errorHandler.handle(erro);
      });
  }
  loadDetails(cpf: string) {
    let allInfoLoaded = false;
    this.recadastramentoService.getDetails(cpf).then((res: DadosGeraisAprovacaoDetails) => {
      this.dadosGeraisAprovacao.registroGeral = res.registroGeral || this.dadosGeraisAprovacao.registroGeral;
      this.dadosGeraisAprovacao.tituloEleitoral = res.tituloEleitoral || this.dadosGeraisAprovacao.tituloEleitoral;
      this.dadosGeraisAprovacao.carteiraDeHabilitacao = res.carteiraDeHabilitacao || this.dadosGeraisAprovacao.carteiraDeHabilitacao;
      this.dadosGeraisAprovacao.carteiraProfissional = res.carteiraProfissional || this.dadosGeraisAprovacao.carteiraProfissional;
      this.dadosGeraisAprovacao.reservista = res.reservista || this.dadosGeraisAprovacao.reservista;
      this.dadosGeraisAprovacao.identidadeProfissional = res.identidadeProfissional || this.dadosGeraisAprovacao.identidadeProfissional;
      this.dadosGeraisAprovacao.certidao = res.certidao || this.dadosGeraisAprovacao.certidao;
      this.dadosGeraisAprovacao.endereco = res.endereco || this.dadosGeraisAprovacao.endereco;
      this.dadosGeraisAprovacao.ric = res.ric || this.dadosGeraisAprovacao.ric;
      this.dadosGeraisAprovacao.regNacionalExtrang = res.regNacionalExtrang || this.dadosGeraisAprovacao.regNacionalExtrang;
      this.dadosGeraisAprovacao.infoAdicionais = res.infoAdicionais || this.dadosGeraisAprovacao.infoAdicionais;
      this.dadosGeraisAprovacao.endExtrangeiro = res.endExtrangeiro || this.dadosGeraisAprovacao.endExtrangeiro;
      this.dadosGeraisAprovacao.aposentadoria = res.aposentadoria || this.dadosGeraisAprovacao.aposentadoria;
      allInfoLoaded = true;

      if (res.dependentes.length > 0) {
        this.dadosGeraisAprovacao.dependentes = res.dependentes || this.dadosGeraisAprovacao.dependentes;
      }
      this.dadosGeraisAprovacao.formacoes = res.formacoes || this.dadosGeraisAprovacao.formacoes;

      if (allInfoLoaded) {
        this.toasty.success('Todas as informações foram carregadas.');
        this.isCarregandoDados = false;
        this.isLoad = false;

      }
      this.setOld();
    })
      .catch((erro) => {
        this.toasty.error('Erro ao carregar as informações.');
        this.isCarregandoDados = false;
      });
  }


  setOld(): void {
    this.dadosGeraisOld = Object.assign({}, this.dadosGeraisAprovacao);
    this.dadosGeraisOld.registroGeral = Object.assign(
      {},
      this.dadosGeraisAprovacao.registroGeral
    );
    this.dadosGeraisOld.aposentadoria = Object.assign(
      {},
      this.dadosGeraisAprovacao.aposentadoria
    );
    this.dadosGeraisOld.carteiraDeHabilitacao = Object.assign(
      {},
      this.dadosGeraisAprovacao.carteiraDeHabilitacao
    );
    this.dadosGeraisOld.carteiraProfissional = Object.assign(
      {},
      this.dadosGeraisAprovacao.carteiraProfissional
    );
    this.dadosGeraisOld.certidao = Object.assign(
      {},
      this.dadosGeraisAprovacao.certidao
    );
    this.dadosGeraisOld.endereco = Object.assign(
      {},
      this.dadosGeraisAprovacao.endereco
    );
    this.dadosGeraisOld.endExtrangeiro = Object.assign(
      {},
      this.dadosGeraisAprovacao.endExtrangeiro
    );
    this.dadosGeraisOld.identidadeProfissional = Object.assign(
      {},
      this.dadosGeraisAprovacao.identidadeProfissional
    );
    this.dadosGeraisOld.infoAdicionais = Object.assign(
      {},
      this.dadosGeraisAprovacao.infoAdicionais
    );
    this.dadosGeraisOld.regNacionalExtrang = Object.assign(
      {},
      this.dadosGeraisAprovacao.regNacionalExtrang
    );
    this.dadosGeraisOld.reservista = Object.assign(
      {},
      this.dadosGeraisAprovacao.reservista
    );
    this.dadosGeraisOld.tituloEleitoral = Object.assign(
      {},
      this.dadosGeraisAprovacao.tituloEleitoral
    );

    this.dadosGeraisOld.dependentes = Object.assign(
      [],
      this.dadosGeraisAprovacao.dependentes
    );
  }

  carregarBanner() {
    this.recadastramentoService
      .carregarUrlBanner(this.dadosGeraisAprovacao.cpf)
      .then((x) => {
        console.log("Banner", x)
        this.temFoto = true;

        this.recadastramentoService.getImage(x.descricao).then((resultado) => {
          this.bytearrayimgbanner =
            this._sanitizer.bypassSecurityTrustResourceUrl(
              "data:image/jpg;base64," + resultado.message
            );
          document
            .getElementsByClassName("ui-fileupload-content")[0]
            .insertAdjacentHTML(
              "beforeend",
              "<img id='imgOld' style=\"width:126%;height:111%;margin-left: -15px;margin-top: -11px; overflow: hidden;background-size: cover;background-position: center;background-image:url('data:image/png;base64," +
              resultado.message +
              "')\"></img>"
            );
        }, (erro) => {
          console.error(erro)
        });

        this.urlBanner = x.urlBanner;
      });
  }
  getBackground(image: string) {
    return this._sanitizer.bypassSecurityTrustStyle(
      `linear-gradient( rgba(255,255,255,.8) 0%,rgba(255,255,255,.8) 100%), url(${image})`
    );
  }
  getBackgroundSize() {
    return this._sanitizer.bypassSecurityTrustStyle(`800px 600px`);
  }
  getBackgroundRepeat() {
    return this._sanitizer.bypassSecurityTrustStyle(`no-repeat`);
  }
  getBackgroundPosition() {
    return this._sanitizer.bypassSecurityTrustStyle(`0%`);
  }
  getPaddingBottom() {
    return this._sanitizer.bypassSecurityTrustStyle(`47%`);
  }
  getPaddingTop() {
    return this._sanitizer.bypassSecurityTrustStyle(`0%`);
  }

  Validate() {
    var charactersOnly = (<HTMLInputElement>(
      document.getElementById("testeonlynumber")
    )).value;
    if (charactersOnly.search(/^[a-zA-Z]+$/) === -1) {
      (<HTMLInputElement>document.getElementById("testeonlynumber")).value = (<
        HTMLInputElement
        >document.getElementById("testeonlynumber")).value.substring(
          0,
          (<HTMLInputElement>document.getElementById("testeonlynumber")).value
            .length - 1
        );
      return (<HTMLInputElement>document.getElementById("testeonlynumber"))
        .value;
    }
  }
  saveImg() {
    this.recadastramentoService
      .salvarReferenciaDaFoto(
        this.dadosGeraisAprovacao.id,
        this.dadosGeraisAprovacao.fotoAgente
      )
      .then(() => {
        console.log("Imagem Salva !");
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  salvar() {
    this.isLoadParcial = true;
    let allInfoLoaded = false;

    let parte1SalvaComSucesso = false;
    let parte2SalvaComSucesso = false;
    let parte3SalvaComSucesso = false;

    this.dadosGeraisAprovacao.status = 0;
    let dadosGeraisAprovacaoDTO1 = new DadosGeraisAprovacaoDTO1(this.dadosGeraisAprovacao);
    dadosGeraisAprovacaoDTO1.ip = this.userIP;
    this.desativaBotaoParcialmente = true;

    this.recadastramentoService.salvarRecadastramentoParte1(dadosGeraisAprovacaoDTO1)
      .then((id) => {
        console.log("Salvando parte1");
        parte1SalvaComSucesso = true;

        let dadosGeraisAprovacaoDTO2 = new DadosGeraisAprovacaoDTO2(this.dadosGeraisAprovacao);
        dadosGeraisAprovacaoDTO2.loginUsuario = this.auth.jwtPayload.cpf;
        dadosGeraisAprovacaoDTO2.id = id;

       this.recadastramentoService.salvarRecadastramentoParte2(dadosGeraisAprovacaoDTO2)

      .then(() => {
        console.log("Salvando parte2");
        parte2SalvaComSucesso = true;

        let dadosGeraisAprovacaoDTO3 = new DadosGeraisAprovacaoDTO3(this.dadosGeraisAprovacao);
            dadosGeraisAprovacaoDTO3.loginUsuario = this.auth.jwtPayload.cpf;
            dadosGeraisAprovacaoDTO3.id = id;

         this.recadastramentoService.salvarRecadastramentoParte3(dadosGeraisAprovacaoDTO3);
      })
      .then(() => {
        console.log("Salvando parte3");
        parte3SalvaComSucesso = true;
        // Todas as operações foram concluídas com sucesso
        if (parte1SalvaComSucesso && parte2SalvaComSucesso && parte3SalvaComSucesso) {
          this.desativaBotaoParcialmente = false;
          this.isLoadParcial = false;
          allInfoLoaded = true;
          this.toasty.success("As informações foram salvas parcialmente com sucesso!");
          this.isCarregandoDados = false;
        }
        //this.desativaBotaoParcialmente = false;
        // this.isLoadParcial = false;
        // allInfoLoaded = true;
        // this.toasty.success("Imagens salvas parcialmente!");
        // this.isCarregandoDados = false;
      })
      .catch((erro) => {

        this.desativaBotaoParcialmente = false;
        this.isLoadParcial = false;
        this.errorHandler.handle(erro);
        this.toasty.success("Erro, as informações não foram salvas parcialmente.");
        this.isCarregandoDados = false;
      });

    })
  }




  async onSubmit($event) {
    $event.preventDefault();

    if (this.validar() == false && this.validaUploads() == false) {
      this.dadosGeraisAprovacao.status = 1;

      let dadosGeraisAprovacaoDTO = new DadosGeraisAprovacaoDTO1(
        this.dadosGeraisAprovacao
      );
      dadosGeraisAprovacaoDTO.ip = this.userIP;

      if (this.block) {
        return;
      }

      if (this.validar()) {
        return;
      }

      if (this.validaUploads()) {
        return;
      }

      this.desativaBotaoConcluir = true;

      if (
        (this.dadosGeraisAprovacao.cpfAtualizado != null &&
          this.dadosGeraisAprovacao.cpfAtualizado != "") ||
        this.dadosGeraisAprovacao.dataNascimento != this.oldDataNas ||
        this.dadosGeraisAprovacao.numeroNis != this.oldNis ||
        this.dadosGeraisAprovacao.nome != this.oldNome
      ) {
        this.dadosGeraisAprovacao.cqc = true;
      }

      let dadosGeraisAprovacaoDTO1 = new DadosGeraisAprovacaoDTO1(
        this.dadosGeraisAprovacao
      );
      dadosGeraisAprovacaoDTO1.ip = this.userIP;
      this.desativaBotaoConcluir = true;

      this.isLoadFinal = true;

      try {
        const id = await this.recadastramentoService.salvarRecadastramentoParte1(
          dadosGeraisAprovacaoDTO1
        );

        this.dadosGeraisAprovacao.ip = this.userIP;
        let dadosGeraisAprovacaoDTO2 = new DadosGeraisAprovacaoDTO2(
          this.dadosGeraisAprovacao
        );
        dadosGeraisAprovacaoDTO2.loginUsuario = this.auth.jwtPayload.cpf;
        dadosGeraisAprovacaoDTO2.id = id;

        let dadosGeraisAprovacaoDTO3 = new DadosGeraisAprovacaoDTO3(
          this.dadosGeraisAprovacao
        );
        dadosGeraisAprovacaoDTO3.loginUsuario = this.auth.jwtPayload.cpf;
        dadosGeraisAprovacaoDTO3.id = id;

        await this.recadastramentoService.salvarRecadastramentoParte2(
          dadosGeraisAprovacaoDTO2
        );

        await this.recadastramentoService.salvarRecadastramentoParte3(
          dadosGeraisAprovacaoDTO3
        );

        this.desativaBotaoConcluir = false;
        this.isLoadFinal = false;

        Swal.fire(
          'Concluído',
          'O recadastramento atualizado com sucesso!',
          'success'
        );

        setTimeout(() => {
          this.carregarBanner();
        }, 5000);
      } catch (erro) {
        this.desativaBotaoConcluir = false;
        this.isLoadFinal = false;
        Swal.fire(
          'Erro',
          'Não foi possível concluir o recadastramento.',
          'error'
        );
        this.errorHandler.handle(erro);
      }
    }
  }



  validarCpfDependente() {
    var listaAux = this.dadosGeraisAprovacao.dependentes;
    var contRetorno = 0;

    var erro = false;
    for (var i = 0; i < listaAux.length; i++) {
      if (listaAux[i].cpf == this.dependente.cpf && !this.dependente.editar) {
        if (contRetorno == 0) listaAux.push(this.dependente);

        contRetorno++;
      }
      if (contRetorno == 2) {
        listaAux.splice(i, 1);
        erro = true;
        break;
      }
    }
    return erro;
  }

  salvarDependente() {
    switch (this.dependente.certidao.estadoCivil) {
      case "1":
        this.dependente.certidao.tipoCertid = "N";
        break;
      case "2":
        this.dependente.certidao.tipoCertid = "C";
        break;
      case "3":
      case "4":
      case "5":
        this.dependente.certidao.tipoCertid = "A";
        break;
    }

    if (this.validarCpfDependente()) {
      this.toasty.error("CPF dependente duplicado!");
      return;
    }

    this.dependente.estadoCivil = this.dependente.certidao.estadoCivil;

    if (
      this.dadosGeraisAprovacao.dependentes != null &&
      this.dadosGeraisAprovacao.dependentes != undefined &&
      this.dadosGeraisAprovacao.dependentes.length > 0
    ) {
      let dependenteInList = -1;

      for (var i = 0; i < this.dadosGeraisAprovacao.dependentes.length; i++) {
        if (
          this.dadosGeraisAprovacao.dependentes.length === this.dadosGeraisOld.dependentes.length &&
          this.dadosGeraisAprovacao.dependentes[i].docCpfVerso ===
          this.dadosGeraisOld.dependentes[i].docCpfVerso &&
          this.dadosGeraisAprovacao.dependentes[i].certidao ===
          this.dadosGeraisOld.dependentes[i].certidao &&
          this.dadosGeraisAprovacao.dependentes[i].cpfAtualizado ===
          this.dadosGeraisOld.dependentes[i].cpfAtualizado &&
          this.dadosGeraisAprovacao.dependentes[i].cpfTitular ===
          this.dadosGeraisOld.dependentes[i].cpfTitular &&
          this.dadosGeraisAprovacao.dependentes[i].cpf ===
          this.dadosGeraisOld.dependentes[i].cpf &&
          this.dadosGeraisAprovacao.dependentes[i].nome ===
          this.dadosGeraisOld.dependentes[i].nome &&
          this.dadosGeraisAprovacao.dependentes[i].sexo ===
          this.dadosGeraisOld.dependentes[i].sexo &&
          this.dadosGeraisAprovacao.dependentes[i].defFisica ===
          this.dadosGeraisOld.dependentes[i].defFisica &&
          this.dadosGeraisAprovacao.dependentes[i].defAuditiva ===
          this.dadosGeraisOld.dependentes[i].defAuditiva &&
          this.dadosGeraisAprovacao.dependentes[i].defIntelectual ===
          this.dadosGeraisOld.dependentes[i].defIntelectual &&
          this.dadosGeraisAprovacao.dependentes[i].defMental ===
          this.dadosGeraisOld.dependentes[i].defMental &&
          this.dadosGeraisAprovacao.dependentes[i].defVisual ===
          this.dadosGeraisOld.dependentes[i].defVisual &&
          this.dadosGeraisAprovacao.dependentes[i].estadoCivil ===
          this.dadosGeraisOld.dependentes[i].estadoCivil &&
          this.dadosGeraisAprovacao.dependentes[i].dataNasc ===
          this.dadosGeraisOld.dependentes[i].dataNasc
        ) {
          this.dadosGeraisAprovacao.dependentes[i].operacao = "N";
        }
      }

      if (!this.dependente.editar) {
        this.dependente.operacao = "I";
      } else {
        if (
          this.dependente.docCpfVerso != this.dependenteOld.docCpfVerso ||
          this.dependente.certidao != this.dependenteOld.certidao ||
          this.dependente.cpfAtualizado != this.dependenteOld.cpfAtualizado ||
          this.dependente.cpfTitular != this.dependenteOld.cpfTitular ||
          this.dependente.cpf != this.dependenteOld.cpf ||
          this.dependente.nome != this.dependenteOld.nome ||
          this.dependente.sexo != this.dependenteOld.sexo ||
          this.dependente.defFisica != this.dependenteOld.defFisica ||
          this.dependente.defAuditiva != this.dependenteOld.defAuditiva ||
          this.dependente.defIntelectual != this.dependenteOld.defIntelectual ||
          this.dependente.defMental != this.dependenteOld.defMental ||
          this.dependente.defVisual != this.dependenteOld.defVisual ||
          this.dependente.estadoCivil != this.dependenteOld.estadoCivil ||
          this.dependente.dataNasc != this.dependenteOld.dataNasc
        ) {
          this.dependente.operacao = "A";
        }
      }

      for (var i = 0; i < this.dadosGeraisAprovacao.dependentes.length; i++) {
        if (
          this.dadosGeraisAprovacao.dependentes[i].cpf == this.dependente.cpf ||
          (this.dadosGeraisAprovacao.dependentes[i].id == this.dependente.id &&
            this.dependente.id != undefined)
        ) {
          dependenteInList = i;
        }
      }
      if (dependenteInList >= 0) {
        this.dadosGeraisAprovacao.dependentes.splice(dependenteInList, 1);
        this.dadosGeraisAprovacao.dependentes.push(this.dependente);
      } else {
        this.dadosGeraisAprovacao.dependentes.push(this.dependente);
        this.dependente.cpf = this.dependente.cpf;
        this.dependente.cpfTitular = this.dadosGeraisAprovacao.cpf;
        this.dependente.origemDados = this.dadosGeraisAprovacao.origemDados;
        this.dependente.empresa = this.dadosGeraisAprovacao.empresa;
        this.dependente.numeroDependente =
          this.dadosGeraisAprovacao.dependentes.length;
      }
    } else {
      this.dependente.cpf = this.dependente.cpf;
      this.dependente.cpfTitular = this.dadosGeraisAprovacao.cpf;
      this.dependente.origemDados = this.dadosGeraisAprovacao.origemDados;
      this.dependente.empresa = this.dadosGeraisAprovacao.empresa;
      this.dadosGeraisAprovacao.dependentes = [
        Object.assign({}, this.dependente),
      ];
      this.dependente.numeroDependente = 1;
    }

    this.toasty.success("Dependente adicionado com sucesso!");
    this.limpaUploads();
    this.dependente = new Dependente();
    this.dependenteOld = new Dependente();
  }

  validarFormacao(): boolean {
    let erro: boolean = false;

    if (
      !this.cursoFormacaoDisabled &&
      (this.formacao.cursoFormacao == null ||
        this.formacao.cursoFormacao == undefined ||
        this.formacao.cursoFormacao == "")
    ) {
      this.toasty.error("Curso de Formação Obrigatório!");
      erro = true;
    }

    if (
      this.formacao.modalidade == null ||
      this.formacao.modalidade == undefined ||
      this.formacao.modalidade == ""
    ) {
      this.toasty.error("Modalidade Obrigatória!");
      erro = true;
    }

    if (
      this.formacao.entidade == null ||
      this.formacao.entidade == undefined ||
      this.formacao.entidade == ""
    ) {
      this.toasty.error("Entidade Obrigatória!");
      erro = true;
    }

    if (
      this.formacao.inicioCurso == null ||
      this.formacao.inicioCurso == undefined ||
      this.formacao.inicioCurso == ""
    ) {
      this.toasty.error("Início do Curso  Obrigatório!");
      erro = true;
    }

    if (
      this.formacao.conclusaoCurso == null ||
      this.formacao.conclusaoCurso == undefined ||
      this.formacao.conclusaoCurso == ""
    ) {
      this.toasty.error("Conclusão do Curso  Obrigatório!");
      erro = true;
    }

    for (var i = 0; i < this.dadosGeraisAprovacao.formacoes.length; i++) {
      if (
        this.dadosGeraisAprovacao.formacoes[i].conclusaoCurso ===
        this.dadosGeraisOld.formacoes[i].conclusaoCurso &&
        this.dadosGeraisAprovacao.formacoes[i].cursoFormacao ===
        this.dadosGeraisOld.formacoes[i].cursoFormacao &&
        this.dadosGeraisAprovacao.formacoes[i].empresa ===
        this.dadosGeraisOld.formacoes[i].empresa &&
        this.dadosGeraisAprovacao.formacoes[i].cpfTitular ===
        this.dadosGeraisOld.formacoes[i].cpfTitular &&
        this.dadosGeraisAprovacao.formacoes[i].entidade ===
        this.dadosGeraisOld.formacoes[i].entidade &&
        this.dadosGeraisAprovacao.formacoes[i].modalidade ===
        this.dadosGeraisOld.formacoes[i].modalidade &&
        this.dadosGeraisAprovacao.formacoes[i].inicioCurso ===
        this.dadosGeraisOld.formacoes[i].inicioCurso
      ) {
        this.dadosGeraisAprovacao.formacoes[i].operacao = "N";
      }
    }

    return erro;
  }

  salvarFormacao() {
    if (this.validarFormacao()) {
      return;
    }

    if (this.formacaoForEdit.modalidade == undefined) {
      this.formacao.operacao = "I";
    } else {
      if (
        this.formacao.conclusaoCurso != this.formacaoOld.conclusaoCurso ||
        this.formacao.cursoFormacao != this.formacaoOld.cursoFormacao ||
        this.formacao.empresa != this.formacaoOld.empresa ||
        this.formacao.cpfTitular != this.formacaoOld.cpfTitular ||
        this.formacao.entidade != this.formacaoOld.entidade ||
        this.formacao.modalidade != this.formacaoOld.modalidade ||
        this.formacao.inicioCurso != this.formacaoOld.inicioCurso
      ) {
        this.formacao.operacao = "A";
      }
    }

    this.dadosGeraisAprovacao.formacoes.indexOf(this.formacaoForEdit);
    if (
      this.dadosGeraisAprovacao.formacoes == null ||
      this.dadosGeraisAprovacao.formacoes == undefined
    ) {
      this.dadosGeraisAprovacao.formacoes = [];
    } else if (
      this.dadosGeraisAprovacao.formacoes.length > 0 &&
      this.dadosGeraisAprovacao.formacoes.indexOf(this.formacaoForEdit) >= 0
    ) {
      this.dadosGeraisAprovacao.formacoes.splice(
        this.dadosGeraisAprovacao.formacoes.indexOf(this.formacaoForEdit),
        1
      );
    }

    this.dadosGeraisAprovacao.formacoes.push(this.formacao);
    this.formacao.cpfTitular = this.dadosGeraisAprovacao.cpf;
    this.formacao.origemDados = this.dadosGeraisAprovacao.origemDados;
    this.formacao.empresa = this.dadosGeraisAprovacao.empresa;

    this.toasty.success("Formação adicionada com sucesso!");
    this.limpaUploads();
    this.formacao = new Formacao();
    this.formacaoOld = new Formacao();
    this.formacaoForEdit = new Formacao();
  }

  editarFormacao(formacao: Formacao) {
    this.formacao = JSON.parse(JSON.stringify(formacao));
    this.formacaoOld = JSON.parse(JSON.stringify(this.formacao));
    this.formacaoForEdit = formacao;
    this.disabledCursoFormacao(formacao.modalidade);
    this.dadosGeraisAprovacao.formacoes.splice(
      this.dadosGeraisAprovacao.formacoes.indexOf(formacao),
      1
    );
    this.limpaUploads();
  }

  confirmarExcluirFormacao(formacao: Formacao) {
    this.formacao.operacao = "E";
    this.dadosGeraisAprovacao.formacoes.splice(
      this.dadosGeraisAprovacao.formacoes.indexOf(formacao),
      1
    );
  }

//   confirmarExcluirDep(dep: Dependente) {

//     const confirmacao = ("Tem certeza que deseja excluir este dependente?");


//     Swal.fire({
//       title: titulo,
//       text: texto,
//       icon: tipo,
//       confirmButtonText: "Ok",
//       confirmButtonColor: "#c3c3c3",
//     });

//    // if(confirmacao){
//       dep.operacao = "E";
//       for (var i = 0; i < this.dadosGeraisAprovacao.dependentes.length; i++) {
//         if (this.dadosGeraisAprovacao.dependentes[i].cpf === dep.cpf) {
//           this.dadosGeraisAprovacao.dependentes.splice(i, 1);
//           break;
//         }
//       }


//  //   }
//  //   else {
//    //   alert("A exclusão foi cancelada pelo usuário.");
//  //   }
//   }
confirmarExcluirDep(dep: Dependente) {
  this.notificationService.enviarNotificacaoConfirmar(
    "Confirmação", "Tem certeza que deseja excluir este dependente?", "warning"
  ).then((result: any) => {
    if (result.isConfirmed === true || result.dismiss !== Swal.DismissReason.cancel) {
     //Swal.fire('Excluindo...', 'Por favor, aguarde.', 'info'); // Exibindo um alerta enquanto processa a exclusão
      // Adicionando um pequeno atraso simulado para dar tempo ao Swal.fire exibir a mensagem
      setTimeout(() => {
        for (let i = 0; i < this.dadosGeraisAprovacao.dependentes.length; i++) {
          if (this.dadosGeraisAprovacao.dependentes[i].cpf === dep.cpf) {
            this.dadosGeraisAprovacao.dependentes.splice(i, 1);
            Swal.close(); // Fechando o alerta de "Excluindo..."
            Swal.fire('Excluído!', 'O dependente foi excluído com sucesso.', 'success');
            break;
          }
        }
      }, 500); // Tempo simulado de 500 milissegundos (ajuste conforme necessário)
    } else {
      Swal.fire('Cancelado', 'A exclusão foi cancelada pelo usuário.', 'info');
    }
  });
}



  carregaMunicipioPorUF(UF: String) {
    this.municipios = [];
    this.municipios.push({ label: "selecione", value: "" });
    this.estadoMunicipio
      .listaMunicipioPorEstado(UF)
      .then((res) => {
        res.forEach((element) => {
          this.municipios.push({
            label: element.nomeCapital,
            value: element.nomeCapital,
          });
        });
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  carregaMunicipioPorUFDoc(UF: String) {
    this.municipiosDoc = [];
    this.municipiosDoc.push({ label: "selecione", value: "" });
    this.estadoMunicipio
      .listaMunicipioPorEstado(UF)
      .then((res) => {
        res.forEach((element) => {
          this.municipiosDoc.push({
            label: element.nomeCapital,
            value: element.nomeCapital,
          });
        });
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  carregarCombos() {
    this.tipoSanguineo = this.combo.tipoSanguineo;

    this.racaecor = this.combo.racaecor;
    this.grauinstrucoes = this.combo.grauinstrucoes;
    this.paises = this.combo.paises;
    this.municipios = [];
    this.ufs = this.combo.ufs;
    this.estadosCivis = this.combo.estadosCivis;
    this.idGenero = this.combo.idGenero;
    this.grupoSanguineo = this.combo.grupoSanguineo;
    this.ptBr = this.combo.ptBr;
    this.tiposCertidao = this.combo.tiposCertidao;
    this.tiposCertidaoDependente = this.combo.tiposCertidao;
    this.logradouros = this.combo.tipoLogradouros;
    this.classificacaoes = this.combo.tiposClassificacao;
    this.tiposDependentes = this.combo.tiposDependentes;
    this.paisesExt = this.combo.paisesExt;
    this.categoriaCnh = this.combo.categoriaCnh;
    this.tiposCursos = this.combo.tiposCursos;

    if (
      this.dadosGeraisAprovacao.ufNascimento != null &&
      this.dadosGeraisAprovacao.ufNascimento != ""
    ) {
      this.carregaMunicipioPorUF(this.dadosGeraisAprovacao.ufNascimento);
    }

    if (this.dadosGeraisAprovacao.certidao != null) {
      if (
        this.dadosGeraisAprovacao.certidao.uf != null &&
        this.dadosGeraisAprovacao.certidao.uf != ""
      ) {
        this.carregaMunicipioPorUF(this.dadosGeraisAprovacao.certidao.uf);
      }
    }
  }

  editarDep(dep: Dependente) {
    this.dependente = JSON.parse(JSON.stringify(dep));

    if (this.dependente.regGeralDependente == null) {
      this.dependente.regGeralDependente = new RegistroGeralDependente();
    }
    if (this.dependente.certidao == null) {
      this.dependente.certidao = new CertidaoDependente();
    }
    this.dependenteOld = JSON.parse(JSON.stringify(this.dependente));
    this.dependente.editar = true;
    if (this.dependente.certidao.estadoCivil == "1") {
      this.tiposCertidaoDependente = [{ label: "Nascimento", value: "N" }];
    } else {
      if (this.dependente.certidao.estadoCivil == "2") {
        this.tiposCertidaoDependente = [{ label: "Casamento", value: "C" }];
      } else {
        this.tiposCertidaoDependente = [{ label: "Averbação", value: "A" }];
      }
    }
    this.limpaUploads();
  }

  limpaUploads() {
    this.showUpNomeDep = false;
    this.showUpCpfDep = false;
    this.showDocNasc = false;
    this.showUpDepSexo = false;
    this.showUpCpfDep = false;
    this.showUpDefAuditivaDep = false;
    this.showUpDefFisicaDep = false;
    this.showUpDefVisualDep = false;
    this.showUpDefMental = false;
    this.showUpDefIntelectual = false;
    this.showUpCertidaoDep = false;
    this.showUpRGDep = false;
  }

  validaCargoMotorista(): boolean {
    if (
      this.dadosGeraisAprovacao.cargo != null &&
      (this.dadosGeraisAprovacao.cargo.toUpperCase() == "MOTORISTA" ||
        this.dadosGeraisAprovacao.cargoEfetivo01.toUpperCase() == "MOTORISTA" ||
        this.dadosGeraisAprovacao.cargoEfetivo02.toUpperCase() == "MOTORISTA")
    ) {
      return true;
    }
    return false;
  }

  validar(): boolean {
    let erro: boolean = false;

    if (
      this.dadosGeraisAprovacao.fotoAgente == undefined ||
      this.dadosGeraisAprovacao.fotoAgente == ""
    ) {
      this.toasty.error("Foto do Agente Obrigatória!");
      erro = true;
    }

    if (this.dadosGeraisAprovacao.nome == "") {
      this.toasty.error("Nome não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.nome != "" &&
      this.dadosGeraisAprovacao.nome != this.dadosGeraisOld.nome
    ) {
      if (this.dadosGeraisAprovacao.docNomeAttFrente == null) {
        this.toasty.error("Anexo DOC Alteração de Nome frente Obrigatório!");
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.nomeSocial != "" &&
      this.dadosGeraisAprovacao.nomeSocial != this.dadosGeraisOld.nomeSocial
    ) {
      if (this.dadosGeraisAprovacao.docNomeSocialAttFrente == null) {
        this.toasty.error(
          "Anexo DOC Alteração de Nome Social frente Obrigatório!"
        );
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.cpf != "" &&
      this.dadosGeraisAprovacao.cpf != this.dadosGeraisOld.cpf
    ) {
      if (this.dadosGeraisAprovacao.docCpfAtualizado == null) {
        this.toasty.error("Anexo DOC Alteração de CPF Obrigatório!");
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.racaCor == undefined ||
      this.dadosGeraisAprovacao.racaCor == ""
    ) {
      this.toasty.error("Raça e Cor não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.grauInstrucao == undefined ||
      this.dadosGeraisAprovacao.grauInstrucao == ""
    ) {
      this.toasty.error("Grau de Instrução não pode ser nulo!");
      erro = true;
    }

    if (this.dadosGeraisAprovacao.sexo == "") {
      this.toasty.error("Sexo não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.tituloEleitoral.numTitulo == "" ||
      this.dadosGeraisAprovacao.tituloEleitoral.numTitulo == undefined
    ) {
      this.toasty.error("Número do título de eleitor não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.tituloEleitoral.zonaEleitoral == "" ||
      this.dadosGeraisAprovacao.tituloEleitoral.zonaEleitoral == undefined
    ) {
      this.toasty.error(
        "Zona eleitoral do título de eleitor não pode ser nulo!"
      );
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.tituloEleitoral.secao == "" ||
      this.dadosGeraisAprovacao.tituloEleitoral.secao == undefined
    ) {
      this.toasty.error("Seção do título de eleitor não pode ser nulo!");
      erro = true;
    }

    if (this.dadosGeraisAprovacao.sexo == "M") {
      if (
        this.dadosGeraisAprovacao.reservista.numReserv == "" ||
        this.dadosGeraisAprovacao.reservista.numReserv == undefined
      ) {
        this.toasty.error("Número de reservista não pode ser nulo!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.reservista.serie == "" ||
        this.dadosGeraisAprovacao.reservista.serie == undefined
      ) {
        this.toasty.error("Série de reservista não pode ser nulo!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.reservista.categoria == "" ||
        this.dadosGeraisAprovacao.reservista.categoria == undefined
      ) {
        this.toasty.error("Categoria de reservista não pode ser nulo!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.reservista.orgMilit == "" ||
        this.dadosGeraisAprovacao.reservista.orgMilit == undefined
      ) {
        this.toasty.error("Órgão de reservista não pode ser nulo!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.reservista.uf == "" ||
        this.dadosGeraisAprovacao.reservista.uf == undefined
      ) {
        this.toasty.error("UF de reservista não pode ser nulo!");
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.tituloEleitoral.uf == "" ||
      this.dadosGeraisAprovacao.tituloEleitoral.uf == undefined
    ) {
      this.toasty.error("UF do título de eleitor não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.sexo != "" &&
      this.dadosGeraisAprovacao.sexo != this.dadosGeraisOld.sexo
    ) {
      if (this.dadosGeraisAprovacao.docSexoAttFrente == null) {
        this.toasty.error("Anexo DOC Alteração de Sexo Frente Obrigatório!");
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.estadoCivil == undefined ||
      this.dadosGeraisAprovacao.estadoCivil == ""
    ) {
      this.toasty.error("Estado Civil não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.identidadeGenero == undefined ||
      this.dadosGeraisAprovacao.identidadeGenero == ""
    ) {
      this.toasty.error("Identidade de Gênero não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.dataNascimento == undefined ||
      this.dadosGeraisAprovacao.dataNascimento == ""
    ) {
      this.toasty.error("Data de Nascimento não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.paisNacionalidade == undefined ||
      this.dadosGeraisAprovacao.paisNacionalidade == ""
    ) {
      this.toasty.error("Pais de Nascimento não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.deficienciaFisica != "" &&
      this.dadosGeraisAprovacao.deficienciaFisica !=
      this.dadosGeraisOld.deficienciaFisica
    ) {
      if (this.dadosGeraisAprovacao.docDeficienciaFisicaFrente == null) {
        this.toasty.error(
          "Anexo DOC Alteração de Def Física frente Obrigatório!"
        );
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.endereco.cep == undefined ||
      this.dadosGeraisAprovacao.endereco.cep == ""
    ) {
      this.toasty.error("CEP do Endereço não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.endereco.telPrincip == undefined ||
      this.dadosGeraisAprovacao.endereco.telPrincip == ""
    ) {
      this.toasty.error("Telefone Principal do Endereço não pode ser nulo!");
      erro = true;
    }
    if (
      this.dadosGeraisAprovacao.endereco.emailPrincip == undefined ||
      this.dadosGeraisAprovacao.endereco.emailPrincip == ""
    ) {
      this.toasty.error("Email Principal do Endereço não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.deficienciaVisual != "" &&
      this.dadosGeraisAprovacao.deficienciaVisual !=
      this.dadosGeraisOld.deficienciaVisual
    ) {
      if (this.dadosGeraisAprovacao.docDeficienciaVisualFrente == null) {
        this.toasty.error(
          "Anexo DOC Alteração de Def Visual frente Obrigatório!"
        );
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.deficienciaAuditiva != "" &&
      this.dadosGeraisAprovacao.deficienciaAuditiva !=
      this.dadosGeraisOld.deficienciaAuditiva
    ) {
      if (this.dadosGeraisAprovacao.docDeficienciaAuditivaFrente == null) {
        this.toasty.error(
          "Anexo DOC Alteração de Def Auditiva frente Obrigatório!"
        );
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.deficienciaMental != "" &&
      this.dadosGeraisAprovacao.deficienciaMental !=
      this.dadosGeraisOld.deficienciaMental
    ) {
      if (this.dadosGeraisAprovacao.docDeficienciaMentalFrente == null) {
        this.toasty.error(
          "Anexo DOC Alteração de Def Mental frente Obrigatório!"
        );
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.deficienciaIntelectual != "" &&
      this.dadosGeraisAprovacao.deficienciaIntelectual !=
      this.dadosGeraisOld.deficienciaIntelectual
    ) {
      if (this.dadosGeraisAprovacao.docDeficienciaIntelectualFrente == null) {
        this.toasty.error(
          "Anexo DOC Alteração de Def Intelectual frente Obrigatório!"
        );
        erro = true;
      }
    }

    if (this.dadosGeraisAprovacao.numeroNis == "") {
      this.toasty.error("Numero NIS não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.numeroNis != "" &&
      this.dadosGeraisAprovacao.numeroNis != this.dadosGeraisOld.numeroNis
    ) {
      if (this.dadosGeraisAprovacao.docNumeroNisFrente == null) {
        this.toasty.error("Anexo DOC Alteração de NIS frente Obrigatório!");
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.registroGeral.rg == undefined ||
      this.dadosGeraisAprovacao.registroGeral.rg == ""
    ) {
      this.toasty.error("Numero do Registro Geral não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.registroGeral.orgao == undefined ||
      this.dadosGeraisAprovacao.registroGeral.orgao == ""
    ) {
      this.toasty.error("Órgão do Registro Geral não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.registroGeral.uf == undefined ||
      this.dadosGeraisAprovacao.registroGeral.uf == ""
    ) {
      this.toasty.error("Uf do Registro Geral não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.registroGeral.emissao == undefined ||
      this.dadosGeraisAprovacao.registroGeral.emissao == ""
    ) {
      this.toasty.error("Emissão do Registro Geral não pode ser nulo!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.registroGeral != null &&
      this.dadosGeraisAprovacao.registroGeral.rg != "" &&
      this.dadosGeraisAprovacao.registroGeral.rg !=
      this.dadosGeraisOld.registroGeral.rg
    ) {
      if (this.dadosGeraisAprovacao.registroGeral.docNuRgFrente == null) {
        this.toasty.error("Anexo DOC Alteração de RG frente Obrigatório!");
        erro = true;
      }
    }

    var CargosProfissioes = this.docCargoObg;

    var CPFAtualizadoTit = this.dadosGeraisAprovacao.cpfAtualizado;
    if (CPFAtualizadoTit != null && CPFAtualizadoTit != "") {
      if (!this.CPFValido(CPFAtualizadoTit)) {
        this.toasty.error("Dados Gerais - CPF Atualizado não é válido! ");
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.tituloEleitoral != null &&
      this.dadosGeraisAprovacao.tituloEleitoral.numTitulo != "" &&
      this.dadosGeraisAprovacao.tituloEleitoral.numTitulo !=
      this.dadosGeraisOld.tituloEleitoral.numTitulo
    ) {
      if (
        this.dadosGeraisAprovacao.tituloEleitoral.docTitEleitorFrente == null
      ) {
        this.toasty.error("Anexo DOC Titulo Eleitoral frente Obrigatório!");
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.carteiraDeHabilitacao != null &&
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.numeroCnh != "" &&
      (this.dadosGeraisAprovacao.carteiraDeHabilitacao.numeroCnh !=
        this.dadosGeraisOld.carteiraDeHabilitacao.numeroCnh ||
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.categoria !=
        this.dadosGeraisOld.carteiraDeHabilitacao.categoria ||
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade !=
        this.dadosGeraisOld.carteiraDeHabilitacao.validade ||
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.uf !=
        this.dadosGeraisOld.carteiraDeHabilitacao.uf ||
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao !=
        this.dadosGeraisOld.carteiraDeHabilitacao.dataEmissao ||
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao !=
        this.dadosGeraisOld.carteiraDeHabilitacao.primeiraHabilitacao)
    ) {
    }

    if (
      this.dadosGeraisAprovacao.carteiraProfissional != null &&
      this.dadosGeraisAprovacao.carteiraProfissional.numeroCtps != "" &&
      this.dadosGeraisAprovacao.carteiraProfissional.numeroCtps !=
      this.dadosGeraisOld.carteiraProfissional.numeroCtps
    ) {
      if (
        this.dadosGeraisAprovacao.carteiraProfissional.docNumCtpsFrente == null
      ) {
        this.toasty.error("Anexo DOC CTPS frente Obrigatório!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.carteiraProfissional.docNumCtpsVerso == null
      ) {
        this.toasty.error("Anexo DOC CTPS verso Obrigatório!");
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.reservista != null &&
      this.dadosGeraisAprovacao.reservista.numReserv != "" &&
      (this.dadosGeraisAprovacao.reservista.numReserv !=
        this.dadosGeraisOld.reservista.numReserv ||
        this.dadosGeraisAprovacao.reservista.serie !=
        this.dadosGeraisOld.reservista.serie ||
        this.dadosGeraisAprovacao.reservista.categoria !=
        this.dadosGeraisOld.reservista.categoria ||
        this.dadosGeraisAprovacao.reservista.orgMilit !=
        this.dadosGeraisOld.reservista.orgMilit ||
        this.dadosGeraisAprovacao.reservista.uf !=
        this.dadosGeraisOld.reservista.uf)
    ) {
      if (this.dadosGeraisAprovacao.reservista.numReservistaFrente == null) {
        this.toasty.error("Anexo DOC Reservista frente Obrigatório!");
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.certidao != null &&
      this.dadosGeraisAprovacao.certidao.tipoCertidao != "" &&
      this.dadosGeraisAprovacao.certidao.tipoCertidao !=
      this.dadosGeraisOld.certidao.tipoCertidao
    ) {
      if (this.dadosGeraisAprovacao.identidadeProfissional.orgao == "") {
        this.toasty.error("Orgão Identidade Profissional não pode ser nulo!");
        erro = true;
      }

      if (
        this.dadosGeraisAprovacao.certidao.numCertidao == undefined ||
        this.dadosGeraisAprovacao.certidao.numCertidao == ""
      ) {
        this.toasty.error("Numero Certidão não pode ser nulo!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.certidao.livro == undefined ||
        this.dadosGeraisAprovacao.certidao.livro == ""
      ) {
        this.toasty.error("Livro não pode ser nulo!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.certidao.folha == undefined ||
        this.dadosGeraisAprovacao.certidao.folha == ""
      ) {
        this.toasty.error("Folha não pode ser nulo!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.certidao.cartorio == undefined ||
        this.dadosGeraisAprovacao.certidao.cartorio == ""
      ) {
        this.toasty.error("Cartório não pode ser nulo!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.certidao.uf == undefined ||
        this.dadosGeraisAprovacao.certidao.uf == ""
      ) {
        this.toasty.error("UF não pode ser nulo!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.certidao.cidade == undefined ||
        this.dadosGeraisAprovacao.certidao.cidade == ""
      ) {
        this.toasty.error("Cidade não pode ser nulo!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.certidao.docCertCasamentoFrente ==
        undefined ||
        this.dadosGeraisAprovacao.certidao.docCertCasamentoFrente == null
      ) {
        this.toasty.error("Anexo DOC Certidão Obrigatório!");
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.certidao.docCertCasamentoVerso == undefined ||
        this.dadosGeraisAprovacao.certidao.docCertCasamentoVerso == null
      ) {
        this.toasty.error("Anexo DOC Certidão Obrigatório!");
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.deficienciaMental != "" &&
      this.dadosGeraisAprovacao.deficienciaMental !=
      this.dadosGeraisOld.deficienciaMental
    ) {
      if (this.dadosGeraisAprovacao.docDeficienciaMentalFrente == null) {
        this.toasty.error(
          "Anexo DOC Alteração de Def Mental frente Obrigatório!"
        );
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.identidadeProfissional.numInscric != null &&
      this.dadosGeraisAprovacao.identidadeProfissional.numInscric != "" &&
      this.dadosGeraisAprovacao.identidadeProfissional.numInscric !=
      this.dadosGeraisOld.identidadeProfissional.numInscric
    ) {
      if (
        this.dadosGeraisAprovacao.identidadeProfissional.numInscricaoFrente ==
        null
      ) {
        this.toasty.error(
          "Anexo DOC Identidade Profissional frente Obrigatório!"
        );
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.regNacionalExtrang != null &&
      this.dadosGeraisAprovacao.regNacionalExtrang.numero != "" &&
      this.dadosGeraisAprovacao.regNacionalExtrang.numero !=
      this.dadosGeraisOld.regNacionalExtrang.numero
    ) {
      if (
        this.dadosGeraisAprovacao.regNacionalExtrang
          .regNacionalMigratorioFrente == null
      ) {
        this.toasty.error("Anexo DOC Imigração frente Obrigatório!");
        erro = true;
      }
    }

    // if (this.dadosGeraisAprovacao.endExtrangeiro.paisResid != "") {
    //   if (
    //     this.dadosGeraisAprovacao.endExtrangeiro.paisResid != null &&
    //     this.dadosGeraisAprovacao.endExtrangeiro.paisResid !=
    //       this.dadosGeraisOld.endExtrangeiro.paisResid
    //   )
    //     if (
    //       this.dadosGeraisAprovacao.endExtrangeiro.endereco == null ||
    //       this.dadosGeraisAprovacao.endExtrangeiro.numero == null ||
    //       this.dadosGeraisAprovacao.endExtrangeiro.bairro == null ||
    //       this.dadosGeraisAprovacao.endExtrangeiro.cidade == null ||
    //       this.dadosGeraisAprovacao.endExtrangeiro.cep == null
    //     ) {
    //       this.toasty.error(
    //         "Preenchimento dos campos de Endereço no Exterior são Obrigatório!"
    //       );
    //       erro = true;
    //     }
    // }

    if (this.dadosGeraisAprovacao.aposentadoria.aposentado == null) {
      this.toasty.error("Campo Informações Complementares Obrigatório!");
      erro = true;
    }

    if (this.dadosGeraisAprovacao.arquivoInformacao == null) {
      this.toasty.error("Campo Arquivo Informação Obrigatório!");
      erro = true;
    }

    if (
      this.dadosGeraisAprovacao.cargo != null &&
      (this.dadosGeraisAprovacao.cargo.toUpperCase() == "MOTORISTA" ||
        this.dadosGeraisAprovacao.cargoEfetivo01.toUpperCase() == "MOTORISTA" ||
        this.dadosGeraisAprovacao.cargoEfetivo02.toUpperCase() == "MOTORISTA")
    ) {
      if (
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.numeroCnh == "" ||
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.numeroCnh == null
      ) {
        this.toasty.error(
          "Preenchimento dos campos de Carteira Nacional de Habilitação Obrigatórios!"
        );
        erro = true;
      }

      if (
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.categoria == "" ||
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.categoria == null
      ) {
        this.toasty.error(
          "Preenchimento do campo Categoria de Carteira Nacional de Habilitação Obrigatório!"
        );
        erro = true;
      }

      if (
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade == "" ||
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade == null
      ) {
        this.toasty.error(
          "Preenchimento do campo Validade de Carteira Nacional de Habilitação Obrigatório!"
        );
        erro = true;
      }

      if (
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.uf == "" ||
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.uf == null
      ) {
        this.toasty.error(
          "Preenchimento do campo UF de Carteira Nacional de Habilitação Obrigatório!"
        );
        erro = true;
      }

      if (
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao == "" ||
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao == null
      ) {
        this.toasty.error(
          "Preenchimento do campo Data Emissão de Carteira Nacional de Habilitação Obrigatório!"
        );
        erro = true;
      }

      if (
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao ==
        "" ||
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao ==
        null
      ) {
        this.toasty.error(
          "Preenchimento do campo Primeira Habilitação de Carteira Nacional de Habilitação Obrigatório!"
        );
        erro = true;
      }
      if (
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.docCnhFrente == null
      ) {
        this.toasty.error("Anexo DOC CNH frente Obrigatório!");
        erro = true;
      }
      if (this.dadosGeraisAprovacao.carteiraDeHabilitacao.docCnhVerso == null) {
        this.toasty.error("Anexo DOC CNH verso Obrigatório!");
        erro = true;
      }
    }

    if (
      this.dadosGeraisAprovacao.paisNacionalidade != "105" &&
      this.dadosGeraisAprovacao.paisNacionalidade != ""
    ) {
      if (
        this.dadosGeraisAprovacao.regNacionalExtrang.casamentoBras == null ||
        this.dadosGeraisAprovacao.regNacionalExtrang.casamentoBras == ""
      ) {
        this.toasty.error(
          "Preenchimento dos campos de Registro de Estrangeiros (RNE/RNM)Obrigatórios!"
        );
        erro = true;
      } else if (
        this.dadosGeraisAprovacao.regNacionalExtrang.classiPerm == null ||
        this.dadosGeraisAprovacao.regNacionalExtrang.classiPerm == ""
      ) {
        this.toasty.error(
          "Preenchimento dos campos de Registro de Estrangeiros (RNE/RNM)Obrigatórios!"
        );
        erro = true;
      } else if (
        this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada == null ||
        this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada == ""
      ) {
        this.toasty.error(
          "Preenchimento dos campos de Registro de Estrangeiros (RNE/RNM)Obrigatórios!"
        );
        erro = true;
      } else if (
        this.dadosGeraisAprovacao.regNacionalExtrang.dataNatural == null ||
        this.dadosGeraisAprovacao.regNacionalExtrang.dataNatural == ""
      ) {
        erro = true;
        this.toasty.error(
          "Preenchimento dos campos de Registro de Estrangeiros (RNE/RNM)Obrigatórios!"
        );
      } else if (
        this.dadosGeraisAprovacao.regNacionalExtrang.emissao == null ||
        this.dadosGeraisAprovacao.regNacionalExtrang.emissao == ""
      ) {
        this.toasty.error(
          "Preenchimento dos campos de Registro de Estrangeiros (RNE/RNM)Obrigatórios!"
        );
        erro = true;
      } else if (
        this.dadosGeraisAprovacao.regNacionalExtrang.filhoBras == null ||
        this.dadosGeraisAprovacao.regNacionalExtrang.filhoBras == ""
      ) {
        this.toasty.error(
          "Preenchimento dos campos de Registro de Estrangeiros (RNE/RNM)Obrigatórios!"
        );
        erro = true;
      } else if (
        this.dadosGeraisAprovacao.regNacionalExtrang.numero == null ||
        this.dadosGeraisAprovacao.regNacionalExtrang.numero == ""
      ) {
        this.toasty.error(
          "Preenchimento dos campos de Registro de Estrangeiros (RNE/RNM)Obrigatórios!"
        );
        erro = true;
      } else if (
        this.dadosGeraisAprovacao.regNacionalExtrang.orgao == null ||
        this.dadosGeraisAprovacao.regNacionalExtrang.orgao == ""
      ) {
        this.toasty.error(
          "Preenchimento dos campos de Registro de Estrangeiros (RNE/RNM)Obrigatórios!"
        );
        erro = true;
      } else if (
        this.dadosGeraisAprovacao.regNacionalExtrang
          .regNacionalMigratorioFrente == null ||
        this.dadosGeraisAprovacao.regNacionalExtrang
          .regNacionalMigratorioFrente == ""
      ) {
        this.toasty.error(
          "Preenchimento dos campos de Registro de Estrangeiros (RNE/RNM)Obrigatórios!"
        );
        erro = true;
      } else if (
        this.dadosGeraisAprovacao.regNacionalExtrang
          .regNacionalMigratorioVerso == null ||
        this.dadosGeraisAprovacao.regNacionalExtrang
          .regNacionalMigratorioVerso == ""
      ) {
        this.toasty.error(
          "Preenchimento dos campos de Registro de Estrangeiros (RNE/RNM)Obrigatórios!"
        );
        erro = true;
      }
    }

    var emissao = this.getDate(
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao
    );
    var habilitacao = this.getDate(
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao
    );
    var validade = this.getDate(
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade
    );

    if (emissao < habilitacao || validade < habilitacao) {
      this.toasty.error(
        "A Data da primeira CNH : " +
        this.getDateBR(habilitacao) +
        " Não pode ser maior que a Data de emissão e Validade."
      );
      erro = true;
    }
    if (
      this.dadosGeraisAprovacao.dependentes != null &&
      this.dadosGeraisAprovacao.dependentes != undefined &&
      this.dadosGeraisAprovacao.dependentes.length > 0
    ) {
      this.dadosGeraisAprovacao.dependentes.forEach((dependente) => {
        let dependenteOld: Dependente;

        if (this.dadosGeraisOld.dependentes != undefined &&
          this.dadosGeraisOld.dependentes != null) {
          this.dadosGeraisOld.dependentes.forEach(d => {
            if (dependente.cpf === d.cpf) {
              dependenteOld = d;
            }
          })
        }

        if (dependenteOld == undefined || dependenteOld == null) {
          dependenteOld = new Dependente();
        }

        if (
          dependente.dataNasc == null ||
          dependente.dataNasc == undefined ||
          dependente.dataNasc == ""
        ) {
          this.toasty.error("Data de Nascimento Obrigatória!");
          erro = true;
        }

        if (
          dependente.estadoCivil == undefined ||
          dependente.estadoCivil == ""
        ) {
          this.toasty.error("Estado civil do dependente é obrigatório");
          erro = true;
        }

        // Validação de alguns campos do dependente
        if (dependente.tipoDepend == undefined || dependente.tipoDepend == "") {
          this.toasty.error("Tipo dependente não pode ser nulo!");
          erro = true;
        }

        if (dependente.cpf == undefined || dependente.cpf == "") {
          this.toasty.error("Cpf do dependente não pode ser nulo!");
          erro = true;
        }

        if (dependente.nome == undefined || dependente.nome == "") {
          this.toasty.error("Nome dependente não pode ser nulo!");
          erro = true;
        }

        if (dependente.dataNasc == undefined || dependente.dataNasc == "") {
          this.toasty.error(
            "Data de nascimento do dependente não pode ser nulo!"
          );
          erro = true;
        }

        if (dependente.sexo == undefined || dependente.sexo == "") {
          this.toasty.error("Sexo dependente não pode ser nulo!");
          erro = true;
        }

        if (dependente.regGeralDependente.numRg == undefined) {
          this.toasty.error("Rg dependente não pode ser nulo!");
          erro = true;
        }

        if (
          dependente.regGeralDependente.orgaoEmis == undefined ||
          dependente.regGeralDependente.orgaoEmis == ""
        ) {
          this.toasty.error("Orgão Rg dependente não pode ser nulo!");
          erro = true;
        }

        if (
          dependente.regGeralDependente.uf == undefined ||
          dependente.regGeralDependente.uf == ""
        ) {
          this.toasty.error("Uf Rg dependente não pode ser nulo!");
          erro = true;
        }

        if (dependente.irpf == undefined || dependente.irpf == "") {
          this.toasty.error("Irpf dependente não pode ser nulo!");
          erro = true;
        }

        if (dependente.planoPriv == undefined || dependente.planoPriv == "") {
          this.toasty.error("Plano privado dependente não pode ser nulo!");
          erro = true;
        }

        if (dependente.incapaz == undefined || dependente.incapaz == "") {
          this.toasty.error(
            "Incapacidade física dependente não pode ser nulo!"
          );
          erro = true;
        }

        if (
          dependente.endDependente.cep == undefined ||
          dependente.endDependente.cep == ""
        ) {
          this.toasty.error("Cep dependente não pode ser nulo!");
          erro = true;
        }

        if (
          dependente.endDependente.numero == undefined ||
          dependente.endDependente.numero == ""
        ) {
          this.toasty.error("Número endereço dependente não pode ser nulo!");
          erro = true;
        }

        if (
          dependente.endDependente.telPrincip == undefined ||
          dependente.endDependente.telPrincip == ""
        ) {
          this.toasty.error("Telefone principal dependente não pode ser nulo!");
          erro = true;
        }

        if (
          dependente.endDependente.emailPrincip == undefined ||
          dependente.endDependente.emailPrincip == ""
        ) {
          this.toasty.error("Email principal dependente não pode ser nulo!");
          erro = true;
        }

        if (!this.CPFValido(dependente.cpf)) {
          this.toasty.error("Dependente  -  CPF não é válido!!");
          erro = true;
        }


        if ((dependente != null && dependente != undefined) &&
          (dependenteOld != null && dependenteOld != undefined) &&
          (dependente.nome != dependenteOld.nome)) {
          if (dependente.docNomeAttFrente == null) {
            this.toasty.error("Anexo DOC Nome Dependente frente Obrigatório!");
            erro = true;
          }
        }

        if (
          dependente != null &&
          dependente.cpfAtualizado != "" &&
          dependente.cpfAtualizado != dependenteOld.cpfAtualizado
        ) {
          if (dependente.docCpfAtualizado == null) {
            this.toasty.error("Anexo DOC CPF Dependente Obrigatório!");
            erro = true;
          }
        }

        if (
          dependente != null &&
          dependente.sexo != "" &&
          dependenteOld.sexo &&
          dependente.sexo != dependenteOld.sexo
        ) {
          if (dependente.docSexoAttFrente == null) {
            this.toasty.error("Anexo DOC Sexo Dependente frente Obrigatório!");
            erro = true;
          }
        }

        if (
          dependente != null &&
          dependente.defFisica != "" &&
          dependente.defFisica != "N" &&
          dependente.defFisica != dependenteOld.defFisica
        ) {
          if (dependente.docDeficienciaFisicaFrente == null) {
            this.toasty.error(
              "Anexo DOC Def Fisica Dependente frente Obrigatório!"
            );
            erro = true;
          }
        }

        if (
          dependente != null &&
          dependente.defAuditiva != "" &&
          dependente.defAuditiva != "N" &&
          dependente.defAuditiva != dependenteOld.defAuditiva
        ) {
          if (dependente.docDeficienciaAuditivaFrente == null) {
            this.toasty.error(
              "Anexo DOC Def Auditiva Dependente frente Obrigatório!"
            );
            erro = true;
          }
        }

        if (
          dependente != null &&
          dependente.defVisual != "" &&
          dependente.defVisual != "N" &&
          dependente.defVisual != dependenteOld.defVisual
        ) {
          if (dependente.docDeficienciaVisualFrente == null) {
            this.toasty.error(
              "Anexo DOC Def Visual Dependente frente Obrigatório!"
            );
            erro = true;
          }
        }

        if (
          dependente != null &&
          dependente.defMental != "" &&
          dependente.defMental != "N" &&
          dependente.defMental != dependenteOld.defMental
        ) {
          if (dependente.docDeficienciaMentalFrente == null) {
            this.toasty.error("Anexo DOC Def Mental frente Obrigatório!");
            erro = true;
          }
        }

        if (
          dependente != null &&
          dependente.defIntelectual != "" &&
          dependente.defIntelectual != "N" &&
          dependente.defIntelectual != dependenteOld.defIntelectual
        ) {
          if (dependente.docDeficienciaIntelectualFrente == null) {
            this.toasty.error("Anexo DOC Def Intelectual frente Obrigatório!");
            erro = true;
          }
        }

        if (dependente.regGeralDependente.numRg != dependenteOld.regGeralDependente.numRg) {
          console.log(dependente.nome)
          console.info("old ", dependenteOld.regGeralDependente)
          console.info("new ", dependente.regGeralDependente)
        }



        if (
          ((dependente != null &&
            dependente.regGeralDependente != null &&
            dependente.regGeralDependente.numRg != "") &&
            (dependenteOld != null &&
              dependenteOld.regGeralDependente != null &&
              dependenteOld.regGeralDependente.numRg != ""))

          &&

          (dependente.regGeralDependente.numRg !=
            dependenteOld.regGeralDependente.numRg ||
            dependente.regGeralDependente.orgaoEmis !=
            dependenteOld.regGeralDependente.orgaoEmis ||
            dependente.regGeralDependente.uf !=
            dependenteOld.regGeralDependente.uf ||
            dependente.regGeralDependente.emissao !=
            dependenteOld.regGeralDependente.emissao)
        ) {
          if (dependente.regGeralDependente.docNuRgFrente == null) {
            this.toasty.error("Anexo DOC RG Dependente frente Obrigatório!");
            erro = true;
          }
          if (dependente.regGeralDependente.docNuRgVerso == null) {
            this.toasty.error("Anexo DOC RG Dependente verso Obrigatório!");
            erro = true;
          }
        }


        if (
          dependente.dataNasc == null ||
          dependente.dataNasc == undefined ||
          dependente.dataNasc == ""
        ) {
          this.toasty.error("Data de Nascimento Obrigatória!");
          erro = true;
        }

        if (dependente.cpf == undefined || dependente.cpf == "") {
          this.toasty.error("Dependente  -  CPF não é válido!!");
          erro = true;
        }

        if (dependente.cpfTitular == undefined || dependente.cpfTitular == "") {
          this.toasty.error("CPF Títular do dependente é obrigatório");
          erro = true;
        }
      });
    }
    return erro;
  }


  validaUploads() {
    let erro: boolean = false;

    if (this.showUpCertidao) {
      if (this.dadosGeraisAprovacao.certidao.docCertCasamentoFrente == null) {
        this.toasty.error('Anexo Doc. Certidão "Frente" Obrigatório!');
        erro = true;
      }
    }

    if (
      this.showDocNasc &&
      (this.dadosGeraisAprovacao.docNascFrente == undefined ||
        this.dadosGeraisAprovacao.docNascFrente == "" ||
        this.dadosGeraisAprovacao.docNascFrente == null)
    ) {
      this.toasty.error("Anexo do dados de nascimento obrigatório!");
      erro = true;
    }

    if (this.showUpCnh) {
      if (
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.docCnhFrente == null
      ) {
        this.toasty.error('Anexo Doc. CNH "Frente" Obrigatório!');
        erro = true;
      }
      if (this.dadosGeraisAprovacao.carteiraDeHabilitacao.docCnhVerso == null) {
        this.toasty.error('Anexo Doc. CNH "Verso" Obrigatório!');
        erro = true;
      }
    }
    if (this.showUpCpf) {
      if (this.dadosGeraisAprovacao.docCpfAtualizado == null) {
        this.toasty.error("Anexo Doc. CPF   Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpCtps) {
      if (
        this.dadosGeraisAprovacao.carteiraProfissional.docNumCtpsFrente == null
      ) {
        this.toasty.error("Anexo Doc. CPTS Frente Obrigatório!");
        erro = true;
      }

      if (
        this.dadosGeraisAprovacao.carteiraProfissional.docNumCtpsVerso == null
      ) {
        this.toasty.error("Anexo Doc. CPTS Verso Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpDefAuditiva) {
      if (this.dadosGeraisAprovacao.docDeficienciaAuditivaFrente == null) {
        this.toasty.error("Anexo Doc. Def. Auditiva Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpDefFisica) {
      if (this.dadosGeraisAprovacao.docDeficienciaFisicaFrente == null) {
        this.toasty.error("Anexo Doc.  Def. Física Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpDefIntelectual) {
      if (this.dadosGeraisAprovacao.docDeficienciaIntelectualFrente == null) {
        this.toasty.error("Anexo Doc.  Def. Intelectual Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpDefVisual) {
      if (this.dadosGeraisAprovacao.docDeficienciaVisualFrente == null) {
        this.toasty.error("Anexo Doc.  Def. visual Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpDefMental) {
      if (this.dadosGeraisAprovacao.docDeficienciaMentalFrente == null) {
        this.toasty.error("Anexo Doc.  Def. mental Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpIp) {
      if (
        this.dadosGeraisAprovacao.identidadeProfissional.numInscricaoFrente ==
        null
      ) {
        this.toasty.error("Anexo Doc. Id. Profissional  Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpNome) {
      if (this.dadosGeraisAprovacao.docNomeAttFrente == null) {
        this.toasty.error("Anexo Doc. Nome  Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpNomeSocial) {
      if (this.dadosGeraisAprovacao.docNomeSocialAttFrente == null) {
        this.toasty.error("Anexo Doc. Nome Social Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpDepSexo) {
      if (this.dependente.docSexoAttFrente == null) {
        this.toasty.error("Anexo Doc. Sexo Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpNumNis) {
      if (this.dadosGeraisAprovacao.docNumeroNisFrente == null) {
        this.toasty.error("Anexo Doc. Número NIS Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpPais) {
      if (this.dadosGeraisAprovacao.docNacionalidadeFrente == null) {
        this.toasty.error("Anexo Doc. Nacionalidade Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpPais) {
      if (this.dadosGeraisAprovacao.docNacionalidadeFrente == null) {
        this.toasty.error("Anexo Doc. Nacionalidade Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpRG) {
      if (this.dadosGeraisAprovacao.registroGeral.docNuRgFrente == null) {
        this.toasty.error("Anexo Doc. RG Frente Obrigatório!");
        erro = true;
      }

      if (this.dadosGeraisAprovacao.registroGeral.docNuRgVerso == null) {
        this.toasty.error("Anexo Doc. RG Verso Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpReservista) {
      if (this.dadosGeraisAprovacao.reservista.numReservistaFrente == null) {
        this.toasty.error("Anexo Doc. Reservista Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpTiPoSanguineto) {
    }

    if (this.showUpTitulo) {
      if (
        this.dadosGeraisAprovacao.tituloEleitoral.docTitEleitorFrente == null
      ) {
        this.toasty.error("Anexo Doc. Título Eleitoral Frente Obrigatório!");
        erro = true;
      }
    }

    if (this.showUpSexo) {
      if (this.dependente.docSexoAttFrente == null) {
        this.toasty.error("Anexo Doc. Título Eleitoral Frente Obrigatório!");
        erro = true;
      }
    }

    return erro;
  }

  validarSexo(): boolean {
    if (this.showUpDepSexo) {
      return true;
    }
    return false;
  }
  validarDependentes(): boolean {
    if (this.showUpNomeDep) {
      return true;
    }
    return false;
  }

  mudouDefFisica(input: any, dadosGeraisAprovacao) {
    dadosGeraisAprovacao.docDeficienciaFisicaFrente = null;
    dadosGeraisAprovacao.docDeficienciaFisicaVerso = null;
    this.showUpDefFisica = input.value == "S";
  }

  mudouDefVisual(input: any, dadosGeraisAprovacao) {
    dadosGeraisAprovacao.docDeficienciaVisualFrente = null;
    dadosGeraisAprovacao.docDeficienciaVisualVerso = null;
    this.showUpDefVisual = input.value == "S";
  }

  mudouDefAuditiva(input: any, dadosGeraisAprovacao) {
    dadosGeraisAprovacao.docDeficienciaAuditivaFrente = null;
    dadosGeraisAprovacao.docDeficienciaAuditivaVerso = null;
    this.showUpDefAuditiva = input.value == "S";
  }

  mudouDefMental(input: any, dadosGeraisAprovacao) {
    dadosGeraisAprovacao.docDeficienciaMentalFrente = null;
    dadosGeraisAprovacao.docDeficienciaMentalVerso = null;
    this.showUpDefMental = input.value == "S";
  }

  mudouDefIntelectual(input: any, dadosGeraisAprovacao) {
    dadosGeraisAprovacao.docDeficienciaIntelectualFrente = null;
    dadosGeraisAprovacao.docDeficienciaIntelectualVerso = null;
    this.showUpDefIntelectual = input.value == "S";
  }

  mudouDadosNasc() {
    this.showDocNasc = true;
    this.exibirAnexosDocNasc = false;
    this.dadosGeraisAprovacao.docNascFrente = null;
    this.dadosGeraisAprovacao.docNascVerso = null;
  }

  mudouDadosCertidao() {
    this.showDocCertidao = true;
    this.exibirAnexosDocCertidao = false;
    this.dadosGeraisAprovacao.docNascFrente = null;
    this.dadosGeraisAprovacao.docNascVerso = null;
  }


  mudouNomeDep(input: any) {
    if (input.value === "") {
      this.showUpNomeDep = true;
      this.exibirAnexosNome = false;
    } else if (input.value != "" && input.value !== this.dependenteOld.nome) {
      this.showUpNomeDep = true;
      this.exibirAnexosNome = false;
    } else if (input.value == this.dependenteOld.nome) {
      this.showUpNomeDep = false;
      this.exibirAnexosNome = false;
    } else {
      this.showUpNomeDep = false;
      this.exibirAnexosNome = true;
    }
  }

  mudouNome(input: any) {
    if (input.value === "") {
      this.showUpNome = true;
      this.exibirAnexosNome = false;
    } else if (input.value != "" && input.value !== this.dadosGeraisOld.nome) {
      this.showUpNome = true;
      this.exibirAnexosNome = false;
    } else if (input.value == this.dadosGeraisOld.nome) {
      this.showUpNome = false;
      this.exibirAnexosNome = false;
    } else {
      this.showUpNome = false;
      this.exibirAnexosNome = true;
    }
  }

  mudouCpf(input: any) {
    if (input.value == "") {
      this.showUpCpf = true;
      this.exibirAnexosCpf = false;
    }
    else if (input.value != "" && input.value !== this.dadosGeraisOld.cpf) {
      this.showUpCpf = true;
      this.exibirAnexosCpf = false;
    } else if (input.value == this.dadosGeraisOld.cpf) {
      this.showUpCpf = false;
      this.exibirAnexosCpf = false;
    } else {
      this.showUpCpf = false;
      this.exibirAnexosCpf = true;
    }
  }

  mudouNomeSocial(input: any) {
    if (input.value == "") {
      this.showUpNomeSocial = false;
      this.exibirAnexosNomeSocial = false;
    } else if (input.value != "" && input.value !== this.dadosGeraisOld.nomeSocial) {
      this.showUpNomeSocial = true;
      this.exibirAnexosNomeSocial = false;
    } else if (input.value == this.dadosGeraisOld.nomeSocial) {
      this.showUpNomeSocial = false;
      this.exibirAnexosNomeSocial = false;
    } else {
      this.showUpNomeSocial = false;
      this.exibirAnexosNomeSocial = true;
    }
  }

  mudouSexo(input: any) {
    if (input.value == "") {
      this.showUpSexo = false;
      this.exibirAnexosSexo = false;
    } else if (input.value != "" && input.value !== this.dadosGeraisOld.sexo) {
      this.showUpSexo = true;
      this.exibirAnexosSexo = false;
    } else if (input.value == this.dadosGeraisOld.sexo) {
      this.showUpSexo = false;
      this.exibirAnexosSexo = false;
    } else {
      this.showUpSexo = false;
      this.exibirAnexosSexo = true;
    }
  }



  mudouNis(input: any) {
    if (input.value == "") {
      this.showUpNumNis = true;
      this.exibirAnexosNumNis = false;
    } else if (input.value != "" && input.value !== this.dadosGeraisOld.numeroNis) {
      this.showUpNumNis = true;
      this.exibirAnexosNumNis = false;
    } else if (input.value == this.dadosGeraisOld.numeroNis) {
      this.showUpNumNis = false;
      this.exibirAnexosNumNis = false;
    } else {
      this.showUpNumNis = false;
      this.exibirAnexosNumNis = true;
    }
  }

  mudouRg() {
    if (
      (this.dadosGeraisAprovacao.registroGeral.rg != null &&
        this.dadosGeraisAprovacao.registroGeral.rg != "" &&
        this.dadosGeraisAprovacao.registroGeral.rg !=
        this.dadosGeraisOld.registroGeral.rg) ||
      (this.dadosGeraisAprovacao.registroGeral.orgao != null &&
        this.dadosGeraisAprovacao.registroGeral.orgao != "" &&
        this.dadosGeraisAprovacao.registroGeral.orgao !=
        this.dadosGeraisOld.registroGeral.orgao) ||
      (this.dadosGeraisAprovacao.registroGeral.uf != null &&
        this.dadosGeraisAprovacao.registroGeral.uf != "" &&
        this.dadosGeraisAprovacao.registroGeral.uf !=
        this.dadosGeraisOld.registroGeral.uf) ||
      (this.dadosGeraisAprovacao.registroGeral.emissao != null &&
        this.dadosGeraisAprovacao.registroGeral.emissao != "" &&
        this.dadosGeraisAprovacao.registroGeral.emissao !=
        this.dadosGeraisOld.registroGeral.emissao)
    ) {
      this.showUpRG = true;
      this.exibirAnexosRG = false;
    } else {
      this.showUpRG = false;
    }
  }

  mudouTitulo() {
    if (
      this.dadosGeraisAprovacao.tituloEleitoral.numTitulo != null &&
      this.dadosGeraisAprovacao.tituloEleitoral.numTitulo != "" &&
      (this.dadosGeraisAprovacao.tituloEleitoral.numTitulo !=
        this.dadosGeraisOld.tituloEleitoral.numTitulo ||
        this.dadosGeraisAprovacao.tituloEleitoral.zonaEleitoral !=
        this.dadosGeraisOld.tituloEleitoral.zonaEleitoral ||
        this.dadosGeraisAprovacao.tituloEleitoral.secao !=
        this.dadosGeraisOld.tituloEleitoral.secao ||
        this.dadosGeraisAprovacao.tituloEleitoral.uf !=
        this.dadosGeraisOld.tituloEleitoral.uf)
    ) {
      this.showUpTitulo = true;
      this.exibirAnexosTitulo = false;

    } else {
      this.showUpTitulo = false;
    }
  }

  mudouCNH() {
    if (
      (this.dadosGeraisAprovacao.carteiraDeHabilitacao.numeroCnh != null &&
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.numeroCnh != "") ||
      (this.dadosGeraisAprovacao.carteiraDeHabilitacao.categoria != null &&
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.categoria != "") ||
      (this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade != null &&
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade != "") ||
      (this.dadosGeraisAprovacao.carteiraDeHabilitacao.uf != null &&
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.uf != "") ||
      (this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao != null &&
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao != "") ||
      (this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao !=
        null &&
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao !=
        "" &&
        (this.dadosGeraisAprovacao.carteiraDeHabilitacao.numeroCnh !=
          this.dadosGeraisOld.carteiraDeHabilitacao.numeroCnh ||
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.categoria !=
          this.dadosGeraisOld.carteiraDeHabilitacao.categoria ||
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade !=
          this.dadosGeraisOld.carteiraDeHabilitacao.validade ||
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.uf !=
          this.dadosGeraisOld.carteiraDeHabilitacao.uf ||
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao !=
          this.dadosGeraisOld.carteiraDeHabilitacao.dataEmissao ||
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao !=
          this.dadosGeraisOld.carteiraDeHabilitacao.primeiraHabilitacao))
    ) {
      this.showUpCnh = true;
      this.exibirAnexosCnh = false;
    } else {
      this.showUpCnh = false;
    }
    let erro: boolean = false;
    var emissao = this.getDate(
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao
    );
    var habilitacao = this.getDate(
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao
    );

    if (emissao < habilitacao) {
      this.toasty.error(
        "Data da primeira habilitação não pode ser maior que a data de emissão."
      );
      erro = true;
    }
  }

  mudouCtps() {
    if (
      this.dadosGeraisAprovacao.carteiraProfissional.numeroCtps != null &&
      this.dadosGeraisAprovacao.carteiraProfissional.numeroCtps != "" &&
      (this.dadosGeraisAprovacao.carteiraProfissional.numeroCtps !=
        this.dadosGeraisOld.carteiraProfissional.numeroCtps ||
        this.dadosGeraisAprovacao.carteiraProfissional.serie !=
        this.dadosGeraisOld.carteiraProfissional.serie ||
        this.dadosGeraisAprovacao.carteiraProfissional.uf !=
        this.dadosGeraisOld.carteiraProfissional.uf)
    ) {
      this.showUpCtps = true;
      this.exibirAnexosCtps = false;
    } else {
      this.showUpCtps = false;
    }
  }

  mudouReservista() {

    console.log(((this.dadosGeraisAprovacao.reservista.numReserv != null &&
      this.dadosGeraisAprovacao.reservista.numReserv != "") ||
      (this.dadosGeraisAprovacao.reservista.serie != null &&
        this.dadosGeraisAprovacao.reservista.serie != "") ||
      (this.dadosGeraisAprovacao.reservista.categoria != null &&
        this.dadosGeraisAprovacao.reservista.categoria != "") ||
      (this.dadosGeraisAprovacao.reservista.uf != null &&
        this.dadosGeraisAprovacao.reservista.uf != "") ||
      (this.dadosGeraisAprovacao.reservista.orgMilit != null &&
        this.dadosGeraisAprovacao.reservista.orgMilit != ""))

      &&

      ((this.dadosGeraisAprovacao.reservista.numReserv !=
        this.dadosGeraisOld.reservista.numReserv ||
        this.dadosGeraisAprovacao.reservista.serie !=
        this.dadosGeraisOld.reservista.serie ||
        this.dadosGeraisAprovacao.reservista.categoria !=
        this.dadosGeraisOld.reservista.categoria ||
        this.dadosGeraisAprovacao.reservista.uf !=
        this.dadosGeraisOld.reservista.uf ||
        this.dadosGeraisAprovacao.reservista.orgMilit !=
        this.dadosGeraisOld.reservista.orgMilit)))
    if (
      ((this.dadosGeraisAprovacao.reservista.numReserv != null &&
        this.dadosGeraisAprovacao.reservista.numReserv != "") ||
        (this.dadosGeraisAprovacao.reservista.serie != null &&
          this.dadosGeraisAprovacao.reservista.serie != "") ||
        (this.dadosGeraisAprovacao.reservista.categoria != null &&
          this.dadosGeraisAprovacao.reservista.categoria != "") ||
        (this.dadosGeraisAprovacao.reservista.uf != null &&
          this.dadosGeraisAprovacao.reservista.uf != "") ||
        (this.dadosGeraisAprovacao.reservista.orgMilit != null &&
          this.dadosGeraisAprovacao.reservista.orgMilit != ""))

      &&

      ((this.dadosGeraisAprovacao.reservista.numReserv !=
        this.dadosGeraisOld.reservista.numReserv ||
        this.dadosGeraisAprovacao.reservista.serie !=
        this.dadosGeraisOld.reservista.serie ||
        this.dadosGeraisAprovacao.reservista.categoria !=
        this.dadosGeraisOld.reservista.categoria ||
        this.dadosGeraisAprovacao.reservista.uf !=
        this.dadosGeraisOld.reservista.uf ||
        this.dadosGeraisAprovacao.reservista.orgMilit !=
        this.dadosGeraisOld.reservista.orgMilit))


    ) {
      this.showUpReservista = true;
      this.exibirAnexosReservista = false;

    } else {
      this.showUpReservista = false;
    }

    console.log(this.showUpReservista)
  }

  mudouIp() {
    if (
      ((this.dadosGeraisAprovacao.identidadeProfissional.numInscric != null &&
        this.dadosGeraisAprovacao.identidadeProfissional.numInscric != "") ||
        (this.dadosGeraisAprovacao.identidadeProfissional.uf != null &&
          this.dadosGeraisAprovacao.identidadeProfissional.uf != "") ||
        (this.dadosGeraisAprovacao.identidadeProfissional.orgao != null &&
          this.dadosGeraisAprovacao.identidadeProfissional.orgao != "") ||
        (this.dadosGeraisAprovacao.identidadeProfissional.emissao != null &&
          this.dadosGeraisAprovacao.identidadeProfissional.emissao != "") ||
        (this.dadosGeraisAprovacao.identidadeProfissional.validade != null &&
          this.dadosGeraisAprovacao.identidadeProfissional.validade != "")) &&
      ((this.dadosGeraisAprovacao.identidadeProfissional.numInscric !=
        this.dadosGeraisOld.identidadeProfissional.numInscric ||
        this.dadosGeraisAprovacao.identidadeProfissional.uf !=
        this.dadosGeraisOld.identidadeProfissional.uf ||
        this.dadosGeraisAprovacao.identidadeProfissional.orgao !=
        this.dadosGeraisOld.identidadeProfissional.orgao ||
        this.dadosGeraisAprovacao.identidadeProfissional.emissao !=
        this.dadosGeraisOld.identidadeProfissional.emissao ||
        this.dadosGeraisAprovacao.identidadeProfissional.validade !=
        this.dadosGeraisOld.identidadeProfissional.validade))
    ) {
      this.showUpIp = true;
      this.exibirAnexosIp = false;
    } else {
      this.showUpIp = false;
    }
  }
  // --1360x768 TODO EDGE
  mudouRne() {
    if (
      (this.dadosGeraisAprovacao.regNacionalExtrang.numero != null &&
        this.dadosGeraisAprovacao.regNacionalExtrang.numero != "" &&
        this.dadosGeraisAprovacao.regNacionalExtrang.numero !=
        this.dadosGeraisOld.regNacionalExtrang.numero) ||
      (this.dadosGeraisAprovacao.regNacionalExtrang.orgao != null &&
        this.dadosGeraisAprovacao.regNacionalExtrang.orgao != "" &&
        this.dadosGeraisAprovacao.regNacionalExtrang.orgao !=
        this.dadosGeraisOld.regNacionalExtrang.orgao) ||
      (this.dadosGeraisAprovacao.regNacionalExtrang.emissao != null &&
        this.dadosGeraisAprovacao.regNacionalExtrang.emissao != "" &&
        this.dadosGeraisAprovacao.regNacionalExtrang.emissao !=
        this.dadosGeraisOld.regNacionalExtrang.emissao) ||
      (this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada != null &&
        this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada != "" &&
        this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada !=
        this.dadosGeraisOld.regNacionalExtrang.dataChegada) ||
      (this.dadosGeraisAprovacao.regNacionalExtrang.dataNatural != null &&
        this.dadosGeraisAprovacao.regNacionalExtrang.dataNatural != "" &&
        this.dadosGeraisAprovacao.regNacionalExtrang.dataNatural !=
        this.dadosGeraisOld.regNacionalExtrang.dataNatural) ||
      (this.dadosGeraisAprovacao.regNacionalExtrang.casamentoBras != null &&
        this.dadosGeraisAprovacao.regNacionalExtrang.casamentoBras != "" &&
        this.dadosGeraisAprovacao.regNacionalExtrang.casamentoBras !=
        this.dadosGeraisOld.regNacionalExtrang.casamentoBras) ||
      (this.dadosGeraisAprovacao.regNacionalExtrang.filhoBras != null &&
        this.dadosGeraisAprovacao.regNacionalExtrang.filhoBras != "" &&
        this.dadosGeraisAprovacao.regNacionalExtrang.filhoBras !=
        this.dadosGeraisOld.regNacionalExtrang.filhoBras) ||
      (this.dadosGeraisAprovacao.regNacionalExtrang.classiPerm != null &&
        this.dadosGeraisAprovacao.regNacionalExtrang.classiPerm != "" &&
        this.dadosGeraisAprovacao.regNacionalExtrang.classiPerm !=
        this.dadosGeraisOld.regNacionalExtrang.classiPerm)
    ) {
      this.showUpRne = true;
      this.exibirAnexosRne = false;

    } else {
      this.showUpRne = false;
    }
  }

  mudouPais() {
    if (
      this.dadosGeraisAprovacao.paisNacionalidade != null &&
      this.dadosGeraisAprovacao.paisNacionalidade != "105"
    ) {
      this.showUpPais = true;
      this.exibirAnexosPais = false;

    } else {
      this.showUpPais = false;
    }
  }

  mudouNascionalidade() {
    if (
      this.dadosGeraisAprovacao.nacionalidade != null &&
      this.dadosGeraisAprovacao.nacionalidade != "105"
    ) {
      this.showUpPais = true;
      this.exibirAnexosPais = false;
    } else {
      this.showUpPais = false;
    }

  }

  mudouPai() {
    if (this.dadosGeraisOld.nomePai != this.dadosGeraisAprovacao.nomePai) {
      if (
        this.showDataNascimento == true ||
        this.showNomeMae == true ||
        this.showMunicipioNascimento == true ||
        this.showUFNascimento == true
      ) {
        this.showNomePai = false;
      } else {
        this.showNomePai = true;

      }
    }
  }

  mudouDataNascimento() {
    if (
      this.showNomeMae == true ||
      this.showNomePai == true ||
      this.showMunicipioNascimento == true ||
      this.showUFNascimento == true
    ) {
      this.showDataNascimento = false;
    } else {
      this.showDataNascimento = true;
    }
  }

  mudouMae() {
    if (this.dadosGeraisOld.nomeMae != this.dadosGeraisAprovacao.nomeMae) {
      if (
        this.showDataNascimento == true ||
        this.showNomePai == true ||
        this.showMunicipioNascimento == true ||
        this.showUFNascimento == true
      ) {
        this.showNomeMae = false;
      } else {
        this.showNomeMae = true;
      }
    }
  }

  mudouUf() {
    this.mudouDadosNasc();
    if (
      this.dadosGeraisAprovacao.ufNascimento != this.dadosGeraisOld.ufNascimento
    ) {
      if (
        this.dadosGeraisAprovacao.ufNascimento != null &&
        this.dadosGeraisAprovacao.ufNascimento != ""
      ) {
        this.carregaMunicipioPorUF(this.dadosGeraisAprovacao.ufNascimento);
      }
      if (
        this.showDataNascimento == true ||
        this.showNomePai == true ||
        this.showMunicipioNascimento == true ||
        this.showNomeMae == true
      ) {
        this.showUFNascimento = false;
      } else {
        this.showUFNascimento = true;

      }
    }
    this.carregaMunicipioPorUF(this.dadosGeraisAprovacao.ufNascimento);
  }

  mudouMunicipio() {
    this.mudouDadosNasc();
    if (
      (this.dadosGeraisAprovacao.municipioNascimento != null &&
        this.dadosGeraisAprovacao.municipioNascimento != "") ||
      this.dadosGeraisAprovacao.municipioNascimento !=
      this.dadosGeraisOld.municipioNascimento
    ) {
      if (
        this.showDataNascimento == true ||
        this.showNomeMae == true ||
        this.showNomePai == true ||
        this.showUFNascimento == true
      ) {
        this.showMunicipioNascimento = false;
      } else {
        this.showMunicipioNascimento = true;
      }
    }
  }

  mudouUfCertidaoDoc() {
    if (
      this.dadosGeraisAprovacao.certidao.uf !=
      this.dadosGeraisOld.certidao.uf &&
      this.dadosGeraisAprovacao.municipioNascimento !=
      this.dadosGeraisAprovacao.certidao.cidade
    ) {
      this.carregaMunicipioPorUFDoc(this.dadosGeraisAprovacao.certidao.uf);
    }
  }

  mudouCidadeDoc() {
    if (
      (this.dadosGeraisAprovacao.certidao.cidade != null &&
        this.dadosGeraisAprovacao.certidao.cidade != "") ||
      this.dadosGeraisAprovacao.certidao.cidade !=
      this.dadosGeraisOld.certidao.cidade
    ) {
      this.showDocCertidao = true;
    }
  }

  mudouCertidaoInicio() {
    if (this.dadosGeraisAprovacao.estadoCivil == "1") {
      this.tiposCertidao = [{ label: "Nascimento", value: "N" }];
      this.dadosGeraisAprovacao.certidao.tipoCertidao = "N";
      this.uniaoEstavel = false;
    } else {
      if (this.dadosGeraisAprovacao.estadoCivil == "2") {
        this.tiposCertidao = [{ label: "Casamento", value: "C" }];
        this.uniaoEstavel = true;
        this.dadosGeraisAprovacao.certidao.tipoCertidao = "C";
        this.tipoDeUniao = this.cs.group({
          myRadioUniao: ["N", []],
        });
      } else {
        this.tiposCertidao = [{ label: "Averbação", value: "A" }];
        this.uniaoEstavel = false;
        this.dadosGeraisAprovacao.certidao.tipoCertidao = "A";
      }
    }
  }

  mudouCertidao() {
    this.dadosGeraisAprovacao.certidao.docCertCasamentoFrente = null;
    this.dadosGeraisAprovacao.certidao.docCertCasamentoVerso = null;
    this.dadosGeraisAprovacao.certidao.docCertidaoFrente = null;
    this.dadosGeraisAprovacao.certidao.docCertidaoVerso = null;

    this.showUpCertidao = true;
    this.exibirAnexosCertidao = false;
    if (this.dadosGeraisAprovacao.estadoCivil == "1") {
      this.tiposCertidao = [{ label: "Nascimento", value: "N" }];
      this.dadosGeraisAprovacao.certidao.tipoCertidao = "N";
      this.uniaoEstavel = false;
    } else {
      if (this.dadosGeraisAprovacao.estadoCivil == "2") {
        this.tiposCertidao = [{ label: "Casamento", value: "C" }];
        this.uniaoEstavel = true;
        this.dadosGeraisAprovacao.certidao.tipoCertidao = "C";
        this.tipoDeUniao = this.cs.group({
          myRadioUniao: ["N", []],
        });
      } else {
        this.tiposCertidao = [{ label: "Averbação", value: "A" }];
        this.dadosGeraisAprovacao.certidao.tipoCertidao = "A";
        this.uniaoEstavel = false;
      }
    }

    if (
      this.dadosGeraisAprovacao.certidao.numCertidao !=
      this.dadosGeraisOld.certidao.numCertidao ||
      (this.dadosGeraisAprovacao.certidao.numCertidao != null &&
        this.dadosGeraisAprovacao.certidao.numCertidao != "")
    ) {
      this.showUpCertidao = true;
      return;
    }

    if (
      this.dadosGeraisAprovacao.certidao.tipoCertidao !=
      this.dadosGeraisOld.certidao.tipoCertidao ||
      (this.dadosGeraisAprovacao.certidao.tipoCertidao != null &&
        this.dadosGeraisOld.certidao.tipoCertidao != "")
    ) {
      return;
    }
    if (
      this.dadosGeraisAprovacao.certidao.livro !=
      this.dadosGeraisOld.certidao.livro ||
      (this.dadosGeraisAprovacao.certidao.livro != null &&
        this.dadosGeraisOld.certidao.livro != "")
    ) {
      this.showUpCertidao = true;
      return;
    }

    if (
      this.dadosGeraisAprovacao.certidao.folha !=
      this.dadosGeraisOld.certidao.folha ||
      (this.dadosGeraisAprovacao.certidao.folha != null &&
        this.dadosGeraisOld.certidao.folha != "")
    ) {
      this.showUpCertidao = true;
      return;
    }

    if (
      this.dadosGeraisAprovacao.certidao.cartorio !=
      this.dadosGeraisOld.certidao.cartorio ||
      (this.dadosGeraisAprovacao.certidao.cartorio != null &&
        this.dadosGeraisOld.certidao.cartorio != "")
    ) {
      this.showUpCertidao = true;
      return;
    }

    if (
      this.dadosGeraisAprovacao.certidao.cidade !=
      this.dadosGeraisOld.certidao.cidade ||
      (this.dadosGeraisAprovacao.certidao.cidade != null &&
        this.dadosGeraisOld.certidao.cidade != "")
    ) {
      this.showUpCertidao = true;
      return;
    }

    if (
      this.dadosGeraisAprovacao.certidao.uf != this.dadosGeraisOld.certidao.uf
    ) {
      if (
        this.dadosGeraisAprovacao.certidao.uf != null &&
        this.dadosGeraisAprovacao.certidao.uf != ""
      ) {
        this.carregaMunicipioPorUF(this.dadosGeraisAprovacao.certidao.uf);
      }

      this.showUpCertidao = true;
      return;
    }
  }

  mudouuniaoEstavel(input: any) {
    if (input.value == "") {
      this.showUpTipodeUniao = true;
      this.exibirAnexosTipodeUniao = false;
    } else if (
      input.value != "" &&
      input.value != "N" &&
      input.value !== this.dadosGeraisOld.certidao.uniaoEstavel
    ) {
      this.showUpTipodeUniao = true;
      this.exibirAnexosTipodeUniao = false;
    }
    else if (input.value == this.dadosGeraisOld.certidao.uniaoEstavel) {
      this.showUpTipodeUniao = false;
      this.exibirAnexosTipodeUniao = false;
    }
    else {
      this.showUpTipodeUniao = false;
      this.exibirAnexosTipodeUniao = true;
    }
  }

  mudouFormacao() {
    if (
      this.dadosGeraisAprovacao.modalidade !== null &&
      this.dadosGeraisAprovacao.modalidade == "01"
    ) {
      this.dadosGeraisAprovacao.conclusaoCurso = "";
      this.dadosGeraisAprovacao.entidade = "";
      this.dadosGeraisAprovacao.inicioCurso = "";

      this.campoForm = true;
    } else {
      this.campoForm = false;
    }
  }

  mudouCpfDep(input: any) {
    if (input.value == "") {
      this.showUpCpfDep = true;
      this.exibirAnexosCpfDep = false;
    }
    else if (input.value != "" && input.value !== this.dependenteOld.cpf) {
      this.showUpCpfDep = true;
      this.exibirAnexosCpfDep = false;

    }
    else if (input.value == this.dependenteOld.cpf) {
      this.showUpCpfDep = false;
      this.exibirAnexosCpfDep = false;
    }
    else {
      this.showUpCpfDep = false;
      this.exibirAnexosCpfDep = true;
    }
  }

  mudouDtNascDep(input: any) {
    var d = new Date(input.value);
    var curr_date = d.getDate();
    var date: String;
    if (curr_date.toString().length < 2) {
      date = "0" + curr_date;
    } else {
      if (date != undefined && date != null) {
        date = date.toString();
      }
    }
    var month: String;
    var curr_month = d.getMonth() + 1;
    if (curr_month.toString().length < 2) {
      month = "0" + curr_month;
    } else {
      month = curr_month.toString();
    }
    var curr_year = d.getFullYear();
    let a = date + "/" + month + "/" + curr_year;

  }

  mudouSexoDep(input: any) {
    if (input.value == "") {
      this.showUpDepSexo = true;
      this.exibirAnexosDepSexo = false;
    } else if (input.value != "" && input.value !== this.dependenteOld.sexo) {
      this.showUpDepSexo = true;
      this.exibirAnexosDepSexo = false;

    } else if (input.value == this.dependenteOld.sexo) {
      this.showUpDepSexo = false;
      this.exibirAnexosDepSexo = false;
    }
    else {
      this.showUpDepSexo = false;
      this.exibirAnexosDepSexo = false;

    }
  }

  mudouDefFisicaDep(input: any, dependente) {
    dependente.docDeficienciaFisicaFrente = null;
    dependente.docDeficienciaFisicaVerso = null;
    this.showUpDefFisicaDep = input.value == "S";

  }

  mudouDefVisualDep(input: any, dependente) {
    dependente.docDeficienciaVisualFrente = null;
    dependente.docDeficienciaVisualVerso = null;
    this.showUpDefVisualDep = input.value == "S";
  }

  mudouDefAuditivaDep(input: any, dependente) {
    dependente.docDeficienciaAuditivaFrente = null;
    dependente.docDeficienciaAuditivaVerso = null;
    this.showUpDefAuditivaDep = input.value == "S";
  }

  mudouDefMentalDep(input: any, dependente) {
    dependente.docDeficienciaMentalFrente = null;
    dependente.docDeficienciaMentalVerso = null;
    this.showUpDefMentalDep = input.value == "S";
  }

  mudouDefIntelectualDep(input: any, dependente) {
    dependente.docDeficienciaIntelectualFrente = null;
    dependente.docDeficienciaIntelectualVerso = null;
    this.showUpDefIntelectualDep = input.value == "S";
  }

  mudouRgDep() {
    if (
      this.dependente.regGeralDependente != null &&
      this.dependente.regGeralDependente.numRg != "" &&
      (this.dependente.regGeralDependente.numRg !=
        this.dependenteOld.regGeralDependente.numRg ||
        this.dependente.regGeralDependente.orgaoEmis !=
        this.dependenteOld.regGeralDependente.orgaoEmis ||
        this.dependente.regGeralDependente.uf !=
        this.dependenteOld.regGeralDependente.uf ||
        this.dependente.regGeralDependente.emissao !=
        this.dependenteOld.regGeralDependente.emissao)
    ) {
      this.showUpRGDep = true;
      this.exibirAnexosRGDep = false;
    } else {
      this.showUpRGDep = false;
    }
  }

  mudouCertidaoDep() {
    if (
      this.dependente.certidao != null &&
      this.dependente.certidao.estadoCivil != "" &&
      (this.dependente.certidao.estadoCivil !=
        this.dependenteOld.certidao.estadoCivil ||
        this.dependente.certidao.numCertid !=
        this.dependenteOld.certidao.numCertid ||
        this.dependente.certidao.livro != this.dependenteOld.certidao.livro ||
        this.dependente.certidao.folha != this.dependenteOld.certidao.folha ||
        this.dependente.certidao.cartorio !=
        this.dependenteOld.certidao.cartorio ||
        this.dependente.certidao.cidade != this.dependenteOld.certidao.cidade ||
        this.dependente.certidao.uf != this.dependenteOld.certidao.uf)
    ) {
      this.showUpCertidaoDep = true;
      this.exibirAnexosCertidaoDep = false;

    } else {
      this.showUpCertidaoDep = false;
    }

    if (this.dependente.certidao.estadoCivil == "1") {
      this.tiposCertidaoDependente = [{ label: "Nascimento", value: "N" }];
    } else {
      if (this.dependente.certidao.estadoCivil == "2") {
        this.tiposCertidaoDependente = [{ label: "Casamento", value: "C" }];
      } else {
        this.tiposCertidaoDependente = [{ label: "Averbação", value: "A" }];
      }
    }
  }

  //==================CPF atualizado=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedCpfAtualizado(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringCpfAtualizado = btoa(binaryString);
  }

  cpfUpload(event, upload: any) {
    let image = event.files[0];

    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedCpfAtualizado.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_CPF",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgCpfAtualizado =
                this.base64textStringCpfAtualizado;
              this.dadosGeraisAprovacao.docCpfAtualizado = resultado.message;
              upload.chooseLabel = this.dadosGeraisAprovacao.docCpfAtualizado;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Nome Frente Upload=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedNomeFrenteUpload(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringNomeFrenteUpload = btoa(binaryString);
  }
  nomeFrenteUpload(event, upload: any) {
    let image = event.files[0];

    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedNomeFrenteUpload.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_NOME",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgNomeAttFrente =
                this.base64textStringNomeFrenteUpload;
              this.dadosGeraisAprovacao.docNomeAttFrente = resultado.message;
              upload.chooseLabel = this.dadosGeraisAprovacao.docNomeAttFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Nome Verso Upload=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedNomeVersoUpload(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringNomeVersoUpload = btoa(binaryString);
  }

  nomeVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedNomeVersoUpload.bind(this);
        reader.readAsBinaryString(image);
        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_NOME",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgNomeAttVerso =
                this.base64textStringNomeVersoUpload;
              this.dadosGeraisAprovacao.docNomeAttVerso = resultado.message;
              upload.chooseLabel = this.dadosGeraisAprovacao.docNomeAttVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Nome Social Frente=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedNomeSocialFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringNomeSocialFrente = btoa(binaryString);
  }
  nomeSocialFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedNomeSocialFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_NSOC",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgNomeSocialAttFrente =
                this.base64textStringNomeSocialFrente;
              this.dadosGeraisAprovacao.docNomeSocialAttFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docNomeSocialAttFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Nome Social Verso=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedNomeSocialVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringNomeSocialVerso = btoa(binaryString);
  }
  nomeSocialVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedNomeSocialVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_NSOC",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgNomeSocialAttVerso =
                this.base64textStringNomeSocialVerso;
              this.dadosGeraisAprovacao.docNomeSocialAttVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docNomeSocialAttVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Sexo Frente Upload=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedSexoFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringSexoFrente = btoa(binaryString);
  }

  sexoFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedSexoFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_SEXO",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgSexoAttFrente =
                this.base64textStringSexoFrente;
              this.dadosGeraisAprovacao.docSexoAttFrente = resultado.message;
              upload.chooseLabel = this.dadosGeraisAprovacao.docSexoAttFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Sexo Verso Upload=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedSexoVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringSexoVerso = btoa(binaryString);
  }

  sexoVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedSexoVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_SEXO",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgSexoAttVerso =
                this.base64textStringSexoVerso;
              this.dadosGeraisAprovacao.docSexoAttVerso = resultado.message;
              upload.chooseLabel = this.dadosGeraisAprovacao.docSexoAttVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================defFisicaFrenteUp=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadeddefFisicaFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringdefFisicaFrente = btoa(binaryString);
  }

  defFisicaFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadeddefFisicaFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_DEFF",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.defFisicaFrente =
                this.base64textStringdefFisicaFrente;
              this.dadosGeraisAprovacao.docDeficienciaFisicaFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docDeficienciaFisicaFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================defFisicaVersoUp=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadeddefFisicaVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringdefFisicaVerso = btoa(binaryString);
  }

  defFisicaVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadeddefFisicaVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_DEFF",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.defFisicaVerso =
                this.base64textStringdefFisicaVerso;
              this.dadosGeraisAprovacao.docDeficienciaFisicaVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docDeficienciaFisicaVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================defVisualFrenteUp=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadeddefVisualFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringdefVisualFrente = btoa(binaryString);
  }

  defVisualFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadeddefVisualFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_DEFV",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.defVisualFrente =
                this.base64textStringdefVisualFrente;
              this.dadosGeraisAprovacao.docDeficienciaVisualFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docDeficienciaVisualFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================defVisualVersoUp=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadeddefVisualVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringdefVisualVerso = btoa(binaryString);
  }

  defVisualVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadeddefVisualVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_DEFV",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.defVisualVerso =
                this.base64textStringdefVisualVerso;
              this.dadosGeraisAprovacao.docDeficienciaVisualVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docDeficienciaVisualVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================defAuditivaFrenteUp=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadeddefAuditivaFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringdefAuditivaFrente = btoa(binaryString);
  }

  defAuditivaFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadeddefAuditivaFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_DEFA",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.defAuditivaFrente =
                this.base64textStringdefAuditivaFrente;
              this.dadosGeraisAprovacao.docDeficienciaAuditivaFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docDeficienciaAuditivaFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================defAuditivaVersoUp=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadeddefAuditivaVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringdefAuditivaVerso = btoa(binaryString);
  }

  defAuditivaVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadeddefAuditivaVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_DEFA",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.defAuditivaVerso =
                this.base64textStringdefAuditivaVerso;
              this.dadosGeraisAprovacao.docDeficienciaAuditivaVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docDeficienciaAuditivaVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================defMentalFrenteUp=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadeddefMentalFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringdefMentalFrente = btoa(binaryString);
  }

  defMentalFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadeddefMentalFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_DEFM",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.defMentalFrente =
                this.base64textStringdefMentalFrente;
              this.dadosGeraisAprovacao.docDeficienciaMentalFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docDeficienciaMentalFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================defMentalVersoUp=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadeddefMentalVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringdefMentalVerso = btoa(binaryString);
  }

  defMentalVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadeddefMentalVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_DEFM",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.defMentalVerso =
                this.base64textStringdefMentalVerso;
              this.dadosGeraisAprovacao.docDeficienciaMentalVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docDeficienciaMentalVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================defIntelectualFrenteUp=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadeddefIntelectualFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringdefIntelectualFrente = btoa(binaryString);
  }

  defIntelectualFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadeddefIntelectualFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_DEFI",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.defIntelectualFrente =
                this.base64textStringdefIntelectualFrente;
              this.dadosGeraisAprovacao.docDeficienciaIntelectualFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docDeficienciaIntelectualFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================defIntelectualVersoUp=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadeddefIntelectualVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringdefIntelectualVerso = btoa(binaryString);
  }

  defIntelectualVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadeddefIntelectualVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_DEFI",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.defIntelectualVerso =
                this.base64textStringdefIntelectualVerso;
              this.dadosGeraisAprovacao.docDeficienciaIntelectualVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docDeficienciaIntelectualVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Nis Frente Up=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedNisFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringNisFrente = btoa(binaryString);
  }
  nisFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedNisFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_NIS",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgNumeroNisFrente =
                this.base64textStringNisFrente;
              this.dadosGeraisAprovacao.docNumeroNisFrente = resultado.message;
              upload.chooseLabel = this.dadosGeraisAprovacao.docNumeroNisFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Nis Verso Up=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedNisVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringNisVerso = btoa(binaryString);
  }
  nisVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedNisVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(image, "_VERSO", "_NIS", this.dadosGeraisOld.cpf, null)
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgNumeroNisVerso =
                this.base64textStringNisVerso;
              this.dadosGeraisAprovacao.docNumeroNisVerso = resultado.message;
              upload.chooseLabel = this.dadosGeraisAprovacao.docNumeroNisVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  getImagePreview(file: File) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imgPreview = reader.result;
      document
        .getElementsByClassName("ui-fileupload-content")[0]
        .insertAdjacentHTML(
          "beforeend",
          "<img id='imgNew' style=\"width:126%;height:111%;margin-left: -15px;margin-top: -11px; overflow: hidden;background-size: cover;background-position: center;background-image:url('" +
          this.imgPreview +
          "')\"></img>"
        );
    };
  }

  // Gera a URL da imagem
  _handleReaderLoadedAgente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringAgente = btoa(binaryString);
  }

  salvarFoto(event, upload: any) {
    let image = event.files[0];

    this.resizeImage(image, 200, 200).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedAgente.bind(this);
        reader.readAsBinaryString(image);

        this.recadastramentoService
          .salvarFotoAgente(image, "", "_FOTO", this.dadosGeraisOld.cpf, null)
          .then((resultado) => {
            this.dadosGeraisAprovacao.imagemAgente =
              this.base64textStringAgente;
            this.dadosGeraisAprovacao.fotoAgente = resultado.message;
            upload.chooseLabel = this.dadosGeraisAprovacao.fotoAgente;
            if (
              this.dadosGeraisAprovacao.id != null &&
              this.dadosGeraisAprovacao.id != undefined
            ) {
              this.saveImg();
            } else {
              this.salvar();
            }
          })
          .catch((erro) => this.errorHandler.handle(erro));
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );

    if (document.getElementById("imgOld"))
      document.getElementById("imgOld").remove();
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    let MAX_SIZE = 7340032;
    return new Promise((resolve, reject) => {
      if (file.size > MAX_SIZE) {
        this.toasty.error("Arquivo anexado é maior do que o limite permitido: 7MB");
        return reject("file maxsize")
      }
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        let canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        let context = canvas.getContext("2d");

        context.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

  createBlob(file: File): Promise<Blob> {
    let MAX_SIZE = 7340032;

    return new Promise((resolve, reject) => {

      if (file.size > MAX_SIZE) {
        this.toasty.error("Arquivo anexado é maior do que o limite permitido: 7MB");
        return reject("file maxsize")
      }

      if (file.type == 'application/pdf') {
        let pdfBlob = new Blob([file], {
          type: 'application/pdf'
        });
        resolve(pdfBlob)
      }



      let image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        let width = image.width;
        let height = image.height;

        let canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        let context = canvas.getContext("2d");

        context.drawImage(image, 0, 0, width, height);

        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

  //======= Anexo RG Frente =======
  // Gera a URL da imagem
  _handleReaderLoadedFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringFrente = btoa(binaryString);
  }

  rgFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(image, "_FRENTE", "_RG", this.dadosGeraisOld.cpf, null)
            .then((resultado) => {
              this.dadosGeraisAprovacao.registroGeral.anexoImagemFrente =
                this.base64textStringFrente;
              this.dadosGeraisAprovacao.registroGeral.docNuRgFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.registroGeral.docNuRgFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //======= Anexo RG Verso =======
  //======= Gera a URL da imagem =======
  _handleReaderLoadedVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringVerso = btoa(binaryString);
  }

  rgVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(image, "_VERSO", "_RG", this.dadosGeraisOld.cpf, null)
            .then((resultado) => {
              this.dadosGeraisAprovacao.registroGeral.anexoImagemVerso =
                this.base64textStringVerso;
              this.dadosGeraisAprovacao.registroGeral.docNuRgVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.registroGeral.docNuRgVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //========Anexo Titulo Eleitoral Verso=======
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedTituloFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringTituloFrente = btoa(binaryString);
  }

  tituloFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedTituloFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_TITULO",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.tituloEleitoral.imgTituloEleitoralFrente =
                this.base64textStringTituloFrente;
              this.dadosGeraisAprovacao.tituloEleitoral.docTitEleitorFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.tituloEleitoral.docTitEleitorFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //========Anexo Titulo Eleitoral Verso=======
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedTituloVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringTituloVerso = btoa(binaryString);
  }

  tituloVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedTituloVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_TITULO",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.tituloEleitoral.imgTituloEleitoralVerso =
                this.base64textStringTituloVerso;
              this.dadosGeraisAprovacao.tituloEleitoral.docTitEleitorVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.tituloEleitoral.docTitEleitorVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  // ========CnhFrenteUp========
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedCnhFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringCnhFrente = btoa(binaryString);
  }

  cnhFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedCnhFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_CNH",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.carteiraDeHabilitacao.imgCnhFrente =
                this.base64textStringCnhFrente;
              this.dadosGeraisAprovacao.carteiraDeHabilitacao.docCnhFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.carteiraDeHabilitacao.docCnhFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  // ========CnhFrenteUp========
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedCnhVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringCnhVerso = btoa(binaryString);
  }
  cnhVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedCnhVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(image, "_VERSO", "_CNH", this.dadosGeraisOld.cpf, null)
            .then((resultado) => {
              this.dadosGeraisAprovacao.carteiraDeHabilitacao.imgCnhVerso =
                this.base64textStringCnhVerso;
              this.dadosGeraisAprovacao.carteiraDeHabilitacao.docCnhVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.carteiraDeHabilitacao.docCnhVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  // ========CtpsFrenteUp========
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedCtpsFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringCtpsFrente = btoa(binaryString);
  }
  ctpsFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedCtpsFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_CTPS",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.carteiraProfissional.imgNumCtpsFrente =
                this.base64textStringCtpsFrente;
              this.dadosGeraisAprovacao.carteiraProfissional.docNumCtpsFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.carteiraProfissional.docNumCtpsFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  // ========CtpsVersoUp========
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedCtpsVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringCtpsVerso = btoa(binaryString);
  }
  ctpsVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedCtpsVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_CTPS",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.carteiraProfissional.imgNumCtpsVerso =
                this.base64textStringCtpsVerso;
              this.dadosGeraisAprovacao.carteiraProfissional.docNumCtpsVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.carteiraProfissional.docNumCtpsVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  // ========IpFrenteUpload========
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedIpFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringIpFrente = btoa(binaryString);
  }

  ipFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedIpFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(image, "_FRENTE", "_IP", this.dadosGeraisOld.cpf, null)
            .then((resultado) => {
              this.dadosGeraisAprovacao.identidadeProfissional.imgNumInscricaoFrente =
                this.base64textStringIpFrente;
              this.dadosGeraisAprovacao.identidadeProfissional.numInscricaoFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.identidadeProfissional.numInscricaoFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  // ========IpVersoUpload========
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedIpVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringIpVerso = btoa(binaryString);
  }
  ipVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedIpVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(image, "_VERSO", "_IP", this.dadosGeraisOld.cpf, null)
            .then((resultado) => {
              this.dadosGeraisAprovacao.identidadeProfissional.imgNumInscricaoVerso =
                this.base64textStringIpVerso;
              this.dadosGeraisAprovacao.identidadeProfissional.numInscricaoVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.identidadeProfissional.numInscricaoVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedRegNacionalEstrangeiroFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringRegNacionalEstrangeiroFrente = btoa(binaryString);
  }

  regExtFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;
        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload =
          this._handleReaderLoadedRegNacionalEstrangeiroFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_RNE",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.regNacionalExtrang.imgRegNacionalMigratorioFrente =
                this.base64textStringRegNacionalEstrangeiroFrente;
              this.dadosGeraisAprovacao.regNacionalExtrang.regNacionalMigratorioFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.regNacionalExtrang.regNacionalMigratorioFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  // ======= Gera a URL da imagem =======
  _handleReaderLoadedRegNacionalEstrangeiroVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringRegNacionalEstrangeiroVerso = btoa(binaryString);
  }

  regExtVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload =
          this._handleReaderLoadedRegNacionalEstrangeiroVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(image, "_VERSO", "_RNE", this.dadosGeraisOld.cpf, null)
            .then((resultado) => {
              this.dadosGeraisAprovacao.regNacionalExtrang.imgRegNacionalMigratorioVerso =
                this.base64textStringRegNacionalEstrangeiroVerso;
              this.dadosGeraisAprovacao.regNacionalExtrang.regNacionalMigratorioVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.regNacionalExtrang.regNacionalMigratorioVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Nacionalidade Frente=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedNacionalidadeFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringNacionalidadeFrente = btoa(binaryString);
  }
  nacionalidadeFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedNacionalidadeFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_NACIO",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgNacionalidadeFrente =
                this.base64textStringNacionalidadeFrente;
              this.dadosGeraisAprovacao.docNacionalidadeFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docNacionalidadeFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Nacionalidade Verso=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedNacionalidadeVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringNacionalidadeVerso = btoa(binaryString);
  }

  nacionalidadeVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedNacionalidadeVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_NACIO",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgNacionalidadeVerso =
                this.base64textStringNacionalidadeVerso;
              this.dadosGeraisAprovacao.docNacionalidadeVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.docNacionalidadeVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Reservista Frente Up=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedReservistaFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringReservistaFrente = btoa(binaryString);
  }

  reservistaFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedReservistaFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(image, "_FRENTE", "_DM", this.dadosGeraisOld.cpf, null)
            .then((resultado) => {
              this.dadosGeraisAprovacao.reservista.imgReservistaFrente =
                this.base64textStringReservistaFrente;
              this.dadosGeraisAprovacao.reservista.numReservistaFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.reservista.numReservistaFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================Reservista Verso Up=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedReservistaVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringReservistaVerso = btoa(binaryString);
  }

  reservistaVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedReservistaVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(image, "_VERSO", "_DM", this.dadosGeraisOld.cpf, null)
            .then((resultado) => {
              this.dadosGeraisAprovacao.reservista.imgReservistaVerso =
                this.base64textStringReservistaVerso;
              this.dadosGeraisAprovacao.reservista.numReservistaVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.reservista.numReservistaVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  // ======= Gera a URL da imagem =======
  _handleReaderLoadedCertidaoFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringUniaoEstavelFrente = btoa(binaryString);
  }

  certidaoFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;
        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedCertidaoFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_CCASA",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.certidao.imgCertCasamentoFrente =
                this.base64textStringUniaoEstavelFrente;
              this.dadosGeraisAprovacao.certidao.docCertCasamentoFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.certidao.docCertCasamentoFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  _handleReaderLoadedCertidaoVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringCertidaoVerso = btoa(binaryString);
  }

  certidaoVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedCertidaoVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_CCASA",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.certidao.imgCertCasamentoVerso =
                this.base64textStringCertidaoVerso;
              this.dadosGeraisAprovacao.certidao.docCertCasamentoVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.certidao.docCertCasamentoVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  _handleReaderLoadedUniaoEstavelFrenteUpload(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringuniaoEstavelFrenteUpload = btoa(binaryString);
  }

  docuniaoEstavelFrenteUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload =
          this._handleReaderLoadedUniaoEstavelFrenteUpload.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_TIPOUNI",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.certidao.imgUniaoEstavelFrente =
                this.base64textStringuniaoEstavelFrenteUpload;
              this.dadosGeraisAprovacao.certidao.docUniaoEstavelFrente =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.certidao.docUniaoEstavelFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  _handleReaderLoadedUniaoEstavelVersoUpload(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringuniaoEstavelVersoUpload = btoa(binaryString);
  }

  docuniaoEstavelVersoUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload =
          this._handleReaderLoadedUniaoEstavelVersoUpload.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_TIPOUNI",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.certidao.imgUniaoEstavelVerso =
                this.base64textStringuniaoEstavelVersoUpload;
              this.dadosGeraisAprovacao.certidao.docUniaoEstavelVerso =
                resultado.message;
              upload.chooseLabel =
                this.dadosGeraisAprovacao.certidao.docUniaoEstavelVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================CPF Dependente Frente Up=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedCpfDepUpload(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringCpfDepFrente = btoa(binaryString);
  }

  cpfDepUpload(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedCpfDepUpload.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_CPF",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDepCpfFrente =
                this.base64textStringCpfDepFrente;
              this.dependente.docCpfFrente = resultado.message;
              upload.chooseLabel = this.dependente.docCpfFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================CPF Dependente Verso Up=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedCpfDepVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringCpfDepVerso = btoa(binaryString);
  }

  cpfDepUploadVerso(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedCpfDepVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_CPF",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDepCpfVerso = this.base64textStringCpfDepVerso;
              this.dependente.docCpfVerso = resultado.message;
              upload.chooseLabel = this.dependente.docCpfVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDadosNascFrente(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDadosNascFrente = btoa(binaryString);
  }

  DadosNascUploadFrente(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedDadosNascFrente.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_CERTNASC",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgNascFrente =
                this.base64textStringDadosNascFrente;
              this.dadosGeraisAprovacao.docNascFrente = resultado.message;
              upload.chooseLabel = this.dadosGeraisAprovacao.docNascFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDadosNascVerso(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDadosNascVerso = btoa(binaryString);
  }

  DadosNascUploadVerso(event, upload: any) {
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedDadosNascVerso.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_CERTNASC",
              this.dadosGeraisOld.cpf,
              null
            )
            .then((resultado) => {
              this.dadosGeraisAprovacao.imgNascVerso =
                this.base64textStringDadosNascVerso;
              this.dadosGeraisAprovacao.docNascVerso = resultado.message;
              upload.chooseLabel = this.dadosGeraisAprovacao.docNascVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  // ======= DEPENDENTES =======
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedNomeDepFrenteUpload(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringNomeDepFrenteUpload = btoa(binaryString);
  }

  nomeDepFrenteUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente Att obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedNomeDepFrenteUpload.bind(this);
        reader.readAsBinaryString(image);
        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_NOME",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDepNomeAttFrente =
                this.base64textStringNomeDepFrenteUpload;
              this.dependente.docNomeAttFrente = resultado.message;
              console.log("modou nome depedente frente", this.dependente.docNomeAttFrente)
              upload.chooseLabel = this.dependente.docNomeAttFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  _handleReaderLoadedNomeDepVersoUpload(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringNomeDepVersoUpload = btoa(binaryString);
  }

  nomeDepVersoUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente Att obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedNomeDepVersoUpload.bind(this);
        reader.readAsBinaryString(image);
        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_NOME",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDepNomeAttVerso =
                this.base64textStringNomeDepVersoUpload;
              this.dependente.docNomeAttVerso = resultado.message;
              upload.chooseLabel = this.dependente.docNomeAttVerso;
              console.log("modou nome depedente frente", this.dependente.docNomeAttFrente)
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  _handleReaderLoadedSexoDepFrenteUpload(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringSexoDepFrenteUpload = btoa(binaryString);
  }

  sexoFrenteDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente Att obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedSexoDepFrenteUpload.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_SEXO",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDepSexoAttFrente =
                this.base64textStringSexoDepFrenteUpload;
              this.dependente.docSexoAttFrente = resultado.message;
              upload.chooseLabel = this.dependente.docSexoAttFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  _handleReaderLoadedSexoDepVersoUpload(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringSexoDepVersoUpload = btoa(binaryString);
  }

  sexoVersoDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedSexoDepVersoUpload.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_SEXO",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDepSexoAttVerso =
                this.base64textStringSexoDepVersoUpload;
              this.dependente.docSexoAttVerso = resultado.message;
              upload.chooseLabel = this.dependente.docSexoAttVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================def Fisica Frente Dependente =============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDefFisicaFrenteDep(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDefFisicaFrenteDep = btoa(binaryString);
  }

  defFisicaFrenteDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente Att obrigatório!");
      return;
    }
    let image = event.files[0];

    this.createBlob(image).then(
      (blob) => {
        image = blob;
        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedDefFisicaFrenteDep.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_DEFF",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDefFisicaFrente =
                this.base64textStringDefFisicaFrenteDep;
              this.dependente.docDeficienciaFisicaFrente = resultado.message;
              upload.chooseLabel = this.dependente.docDeficienciaFisicaFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================def Fisica Verso Dependente =============

  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDefFisicaVersoDep(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDefFisicaVersoDep = btoa(binaryString);
  }

  defFisicaVersoDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedDefFisicaVersoDep.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_DEFF",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDefFisicaVerso =
                this.base64textStringDefFisicaVersoDep;
              this.dependente.docDeficienciaFisicaVerso = resultado.message;
              upload.chooseLabel = this.dependente.docDeficienciaFisicaVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================def Visual Frente Dependente =============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDefVisualFrenteDep(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDefVisualFrenteDep = btoa(binaryString);
  }

  defVisualFrenteDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente Att obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedDefVisualFrenteDep.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_DEFV",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDeficienciaVisualFrente =
                this.base64textStringDefVisualFrenteDep;
              this.dependente.docDeficienciaVisualFrente = resultado.message;
              upload.chooseLabel = this.dependente.docDeficienciaVisualFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //==================def Visual Verso Dependente =============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDefVisualVersoDep(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDefVisualVersoDep = btoa(binaryString);
  }

  defVisualVersoDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedDefVisualVersoDep.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_DEFV",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDeficienciaVisualVerso =
                this.base64textStringDefVisualVersoDep;
              this.dependente.docDeficienciaVisualVerso = resultado.message;
              upload.chooseLabel = this.dependente.docDeficienciaVisualVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //================== def Auditivo Frente Dependente =============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDefAuditivoFrenteDep(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDefAuditivoFrenteDep = btoa(binaryString);
  }

  defAuditivaFrenteDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente Att obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedDefAuditivoFrenteDep.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_DEFA",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDeficienciaAuditivaFrente =
                this.base64textStringDefAuditivoFrenteDep;
              this.dependente.docDeficienciaAuditivaFrente = resultado.message;
              upload.chooseLabel = this.dependente.docDeficienciaAuditivaFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //================== def Auditivo Verso Dependente =============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDefAuditivoVersoDep(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDefAuditivoVersoDep = btoa(binaryString);
  }

  defAuditivaVersoDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedDefAuditivoVersoDep.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_DEFA",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDeficienciaAuditivaVerso =
                this.base64textStringDefAuditivoVersoDep;
              this.dependente.docDeficienciaAuditivaVerso = resultado.message;
              upload.chooseLabel = this.dependente.docDeficienciaAuditivaVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //================== def Mental Frente Dependente =============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDefMentalFrenteDep(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDefMentalFrenteDep = btoa(binaryString);
  }

  defMentalFrenteDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente Att obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedDefMentalFrenteDep.bind(this);
        reader.readAsBinaryString(image);

        this.recadastramentoService
          .salvarAnexo(
            image,
            "_FRENTE",
            "_DEFM",
            this.dadosGeraisOld.cpf,
            this.dependente.cpf == undefined
              ? this.dependente.cpfAtualizado
              : this.dependente.cpf
          )
          .then((resultado) => {
            this.dependente.imgDeficienciaMentalFrente =
              this.base64textStringDefMentalFrenteDep;
            this.dependente.docDeficienciaMentalFrente = resultado.message;
            upload.chooseLabel = this.dependente.docDeficienciaMentalFrente;
          })
          .catch((erro) => this.errorHandler.handle(erro));
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //================== def Mental Verso Dependente =============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDefMentalVersoDep(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDefMentalVersoDep = btoa(binaryString);
  }

  defMentalVersoDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedDefMentalVersoDep.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_DEFM",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDeficienciaMentalVerso =
                this.base64textStringDefMentalVersoDep;
              this.dependente.docDeficienciaMentalVerso = resultado.message;
              upload.chooseLabel = this.dependente.docDeficienciaMentalVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //================== def Intelectual Frente Dependente =============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDefIntelectualFrenteDep(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDefIntelectualFrenteDep = btoa(binaryString);
  }

  defIntelectualFrenteDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente Att obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload =
          this._handleReaderLoadedDefIntelectualFrenteDep.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_DEFI",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDeficienciaIntelectualFrente =
                this.base64textStringDefIntelectualFrenteDep;
              this.dependente.docDeficienciaIntelectualFrente =
                resultado.message;
              upload.chooseLabel =
                this.dependente.docDeficienciaIntelectualFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //================== def Intelectual Verso Dependente =============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedDefIntelectualVersoDep(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringDefIntelectualVersoDep = btoa(binaryString);
  }

  defIntelectualVersoDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload =
          this._handleReaderLoadedDefIntelectualVersoDep.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_DEFI",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.imgDeficienciaIntelectualVerso =
                this.base64textStringDefIntelectualVersoDep;
              this.dependente.docDeficienciaIntelectualVerso =
                resultado.message;
              upload.chooseLabel =
                this.dependente.docDeficienciaIntelectualVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);
        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //=================Rg Frente Dep Upload=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedRgFrenteDepUpload(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringRgDepFrente = btoa(binaryString);
  }

  rgFrenteDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente Att obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedRgFrenteDepUpload.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_FRENTE",
              "_RG",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.regGeralDependente.imgDepNuRgFrente =
                this.base64textStringRgDepFrente;
              this.dependente.regGeralDependente.docNuRgFrente =
                resultado.message;
              upload.chooseLabel =
                this.dependente.regGeralDependente.docNuRgFrente;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  //=================Rg Frente Dep Upload=============
  // ======= Gera a URL da imagem =======
  _handleReaderLoadedRgDepVersoUpload(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringRgDepVerso = btoa(binaryString);
  }

  rgVersoDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente obrigatório!");
      return;
    }
    let image = event.files[0];
    this.createBlob(image).then(
      (blob) => {
        image = blob;

        // Converte imagem em URL
        var reader = new FileReader();
        reader.onload = this._handleReaderLoadedRgDepVersoUpload.bind(this);
        reader.readAsBinaryString(image);

        setTimeout(() => {
          this.recadastramentoService
            .salvarAnexo(
              image,
              "_VERSO",
              "_RG",
              this.dadosGeraisOld.cpf,
              this.dependente.cpf == undefined
                ? this.dependente.cpfAtualizado
                : this.dependente.cpf
            )
            .then((resultado) => {
              this.dependente.regGeralDependente.imgDepNuRgVerso =
                this.base64textStringRgDepVerso;
              this.dependente.regGeralDependente.docNuRgVerso =
                resultado.message;
              upload.chooseLabel =
                this.dependente.regGeralDependente.docNuRgVerso;
            })
            .catch((erro) => this.errorHandler.handle(erro));
        }, 1000);

        upload.clear();
      },
      (err) => {
        console.error("Photo error", err);
        upload.clear();
      }
    );
  }

  certFrenteDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente Att obrigatório!");
      return;
    }
    //   let image = event.files[0];
    //   this.createBlob(image).then(blob => {
    //       image = blob;
    //       this.recadastramentoService.salvarAnexo(image, '_FRENTE', this.dependente.certidao.tipoCertid == 'N' ? '_CNASC' : '_CCASA',
    //     this.dadosGeraisOld.cpf, this.dependente.cpf == undefined ? this.dependente.cpfAtualizado : this.dependente.cpf)
    //     .then(resultado => {
    //       this.dependente.certidao.docEstCivilFrente = resultado.message;
    //       upload.chooseLabel = this.dependente.certidao.docEstCivilFrente;
    //     })
    //     .catch(erro => this.errorHandler.handle(erro));
    //   upload.clear();
    // }, err => {
    //   console.error("Photo error", err);
    // });
  }

  certVersoDepUpload(event, upload: any) {
    if (
      this.dependente.cpf == undefined &&
      (this.dependente.cpfAtualizado == undefined ||
        this.dependente.cpfAtualizado == "")
    ) {
      this.toasty.error("CPF Dependente obrigatório!");
      return;
    }
    // let image = event.files[0];
    // this.createBlob(image).then(blob => {
    //     image = blob;
    //     this.recadastramentoService.salvarAnexo(image, '_VERSO', this.dependente.certidao.tipoCertid == 'N' ? '_CNASC' : '_CCASA',
    //   this.dadosGeraisOld.cpf, this.dependente.cpf == undefined ? this.dependente.cpfAtualizado : this.dependente.cpf)
    //   .then(resultado => {
    //     this.dependente.certidao.docEstCivilVerso = resultado.message;
    //     upload.chooseLabel = this.dependente.certidao.docEstCivilVerso;
    //   })
    //   .catch(erro => this.errorHandler.handle(erro));
    // upload.clear();    }, err => {
    //   console.error("Photo error", err);
    // });
  }

  buscar() {
    this.recadastramentoService
      .buscaCep(this.dadosGeraisAprovacao.endereco.cep)
      .then((endereco: Endereco) => {
        this.dadosGeraisAprovacao.endereco.bairro = endereco.bairro;
        this.dadosGeraisAprovacao.endereco.cep = endereco.cep;
        this.dadosGeraisAprovacao.endereco.endereco = endereco.endereco;
        this.dadosGeraisAprovacao.endereco.municipio = endereco.municipio;
        this.dadosGeraisAprovacao.endereco.uf = endereco.uf;
        this.dadosGeraisAprovacao.endereco.tipoLograd = endereco.tipoLograd;
        if (this.dadosGeraisAprovacao.endereco.endereco != endereco.endereco) {
          this.dadosGeraisAprovacao.endereco.complemento = endereco.complemento;
          this.dadosGeraisAprovacao.endereco.numero = endereco.numero;
        }
      })
      .catch(() => {
        let endereco = this.dadosGeraisAprovacao.endereco.cep;

        this.toasty.error("Verifique o CEP informado");
      });
  }

  buscarEndDep() {
    this.recadastramentoService
      .buscaCep(this.dependente.endDependente.cep)
      .then((endereco: Endereco) => {
        this.dependente.endDependente.bairro = endereco.bairro;
        this.dependente.endDependente.cep = endereco.cep;
        this.dependente.endDependente.endereco = endereco.endereco;
        this.dependente.endDependente.municipio = endereco.municipio;
        this.dependente.endDependente.uf = endereco.uf;
        this.dependente.endDependente.tipoLograd = endereco.tipoLograd;
        if (this.dependente.endDependente.endereco != endereco.endereco) {
          this.dependente.endDependente.complemento = endereco.complemento;
          this.dependente.endDependente.numero = endereco.numero;
        }
      })
      .catch(() => {
        let endereco = this.dependente.endDependente.cep;
        this.toasty.error("Verifique o CEP informado");
      });
  }

  dialogoNome() {
    this.display = false;
  }

  mudouPrimeiroemprego() {
    let Fim = this.getDate(this.dadosGeraisAprovacao.primeiroEmprego);
    let Incio = this.obterData();

    if (Incio != null) {
      var DataInicio = Date.parse(Incio);
      var DataFim = Date.parse(Fim);
      if (DataFim > DataInicio) {
        this.dadosGeraisAprovacao.primeiroEmprego = "";
        this.toasty.error(
          "Informações Complementares - Data do Primeiro Emprego: " +
          this.getDateBR(Fim) +
          " não pode ser futura!"
        );
      }
    }
  }

  DataEmissaoRNERNM() {
    let DataEmissaoESTR = this.dadosGeraisAprovacao.regNacionalExtrang.emissao;
    let DataFutura = this.DataOrgaoClasseFut(DataEmissaoESTR);

    if (DataFutura) {
      this.dadosGeraisAprovacao.regNacionalExtrang.emissao = "";
      this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada = "";
      this.toasty.error(
        "Registro de Estrangeiros (RNE/RNM) - Data de Emissão: " +
        DataEmissaoESTR +
        " Não pode ser futura!"
      );
    } else {
      let Incio = this.getDate(
        this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada
      );
      let Fim = this.getDate(
        this.dadosGeraisAprovacao.regNacionalExtrang.emissao
      );

      if (Incio && Fim) {
        var DataInicio = Date.parse(Incio);
        var DataFim = Date.parse(Fim);

        if (DataInicio < DataFim) {
          this.dadosGeraisAprovacao.regNacionalExtrang.emissao = "";
          this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada = "";
          this.toasty.error(
            "Registro de Estrangeiros (RNE/RNM) - Data de Emissão: " +
            this.getDateBR(Fim) +
            " não pode ser maior que data de chegada!"
          );
        }
      }

      let dtNatural = this.getDate(
        this.dadosGeraisAprovacao.regNacionalExtrang.dataNatural
      );
      if (dtNatural != null || dtNatural != "") {
        if (Date.parse(dtNatural) < DataFim) {
          this.dadosGeraisAprovacao.regNacionalExtrang.emissao = "";
          this.toasty.error(
            "Registro de Estrangeiros (RNE/RNM) - Data de Emissão: " +
            this.getDateBR(Fim) +
            " não pode ser maior que Data de Naturalização!"
          );
        }
      }
    }
  }

  DataChegadaRNERNM() {
    let dataChegada = this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada;
    let DataFutura = this.DataOrgaoClasseFut(dataChegada);

    if (DataFutura) {
      this.dadosGeraisAprovacao.regNacionalExtrang.emissao = "";
      this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada = "";
      this.toasty.error(
        "Registro de Estrangeiros (RNE/RNM) - Data de Chegada: " +
        dataChegada +
        " Não pode ser futura!"
      );
    } else {
      let Incio = this.getDate(
        this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada
      );
      let Fim = this.getDate(
        this.dadosGeraisAprovacao.regNacionalExtrang.emissao
      );

      if (Incio && Fim) {
        var DataInicio = Date.parse(Incio);
        var DataFim = Date.parse(Fim);

        if (DataInicio < DataFim) {
          this.dadosGeraisAprovacao.regNacionalExtrang.emissao = "";
          this.dadosGeraisAprovacao.regNacionalExtrang.emissao = "";
          this.toasty.error(
            "Registro de Estrangeiros (RNE/RNM) - Data de Emissão: " +
            this.getDateBR(Fim) +
            " não pode ser maior que data de chegada!"
          );
        }
      }
    }
  }

  DataNaturalizacaoRNERNM() {
    let Fim = this.getDate(
      this.dadosGeraisAprovacao.regNacionalExtrang.dataNatural
    );
    let Incio = this.obterData();
    let dtEmissao = Date.parse(
      this.getDate(this.dadosGeraisAprovacao.regNacionalExtrang.emissao)
    );
    let dtChegada = Date.parse(
      this.getDate(this.dadosGeraisAprovacao.regNacionalExtrang.dataChegada)
    );

    if (Incio != null) {
      var DataInicio = Date.parse(Incio);
      var DataFim = Date.parse(Fim);
      if (DataFim > DataInicio) {
        this.dadosGeraisAprovacao.regNacionalExtrang.dataNatural = "";
        this.toasty.error(
          "Registro de Estrangeiros (RNE/RNM) - Data Naturalização: " +
          this.getDateBR(Fim) +
          " não pode ser futura!"
        );
      } else if (dtEmissao > DataFim) {
        this.dadosGeraisAprovacao.regNacionalExtrang.dataNatural = "";
        this.toasty.error(
          "Registro de Estrangeiros (RNE/RNM) - Data Naturalização: " +
          this.getDateBR(Fim) +
          " não pode ser menor que Data Emissão!"
        );
      } else if (dtChegada > DataFim) {
        this.dadosGeraisAprovacao.regNacionalExtrang.dataNatural = "";
        this.toasty.error(
          "Registro de Estrangeiros (RNE/RNM) - Data Naturalização: " +
          this.getDateBR(Fim) +
          " não pode ser maior que Data Chegada!"
        );
      }
    }
  }

  DataNasciDependentes() {
    this.showUpCpfDep = true;
    let Fim = this.getDate(this.dependente.dataNasc);
    let Incio = this.obterData();

    if (Incio != null) {
      var DataInicio = Date.parse(Incio);
      var DataFim = Date.parse(Fim);
      if (DataFim > DataInicio) {
        this.dependente.dataNasc = "";
        this.toasty.error(
          "Dependentes - Data Nascimento: " +
          this.getDateBR(Fim) +
          " não pode ser futura!"
        );
      }
    }
  }
  DataEmissaoDepe() {
    let Fim = this.getDate(this.dependente.regGeralDependente.emissao);
    let Incio = this.obterData();
    if (Incio != null) {
      var DataInicio = Date.parse(Incio);
      var DataFim = Date.parse(Fim);
      if (DataFim > DataInicio) {
        this.dependente.regGeralDependente.emissao = "";
        this.toasty.error(
          "Dependentes - Data Emissão: " +
          this.getDateBR(Fim) +
          " não pode ser futura!"
        );
        this.showUpRGDep = false;
      } else {
        this.mudouRgDep();
      }
    }
  }

  DataEmissaoCNH() {
    let Fim = this.getDate(
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao
    );
    let Incio = this.obterData();

    if (Incio != null) {
      var DataInicio = Date.parse(Incio);
      var DataFim = Date.parse(Fim);
      if (DataFim > DataInicio) {
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao = "";
        this.toasty.error(
          "Dados da Carteira Nacional de Habilitação  - Data de Emissão: " +
          this.getDateBR(Fim) +
          " não pode ser futura!"
        );
        this.showUpCnh = false;
      } else {
        this.mudouCNH();
      }
    }
  }

  DataPriCNH() {
    let dtPriHab =
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao.split(
        "/"
      );
    let dtEmissao =
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao.split("/");

    let diaPriHab = dtPriHab[0];
    let mesPriHab = dtPriHab[1];
    let anoPriHab = dtPriHab[2];
    let diaEmissao = dtEmissao[0];
    let mesEmissao = dtEmissao[1];
    let anoEmissao = dtEmissao[2];

    if (
      new Date(anoPriHab + "/" + mesPriHab + "/" + diaPriHab) >
      new Date(anoEmissao + "/" + mesEmissao + "/" + diaEmissao)
    ) {
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao = "";
      this.toasty.error(
        "Dados da Carteira Nacional de Habilitação  - Data primeira Habilitação: " +
        this.getDateBR(
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao
        ) +
        " não pode ser maior que data de emissão!"
      );
      this.showUpCnh = false;
    } else {
      this.mudouCNH();
    }

    if (new Date(anoPriHab + "/" + mesPriHab + "/" + diaPriHab) > new Date()) {
      this.toasty.error(
        "Dados da Carteira Nacional de Habilitação  - Data primeira Habilitação: " +
        this.getDateBR(
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao
        ) +
        " não pode ser futura!"
      );
      this.showUpCnh = false;
    }
  }

  DataValidadeCNH() {
    let DataEmissaoCNH =
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao;
    let DataFutura = this.DataOrgaoClasseFut(DataEmissaoCNH);

    if (DataFutura) {
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao = "";
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade = "";

      this.toasty.error(
        "Dados da Carteira Nacional de Habilitação  - Data de Emissão: " +
        DataEmissaoCNH +
        " Não pode ser futura!"
      );
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao = "";
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade = "";
    } else {
      let Incio = this.getDate(
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao
      );
      let Fim = this.getDate(
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade
      );

      if (Incio && Fim) {
        var DataInicio = Date.parse(Incio);
        var DataFim = Date.parse(Fim);

        if (DataInicio > DataFim) {
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao = "";
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade = "";
          this.toasty.error(
            "Dados da Carteira Nacional de Habilitação  - Validade: : " +
            this.getDateBR(Incio) +
            " não pode ser menor que data de missão: " +
            this.getDateBR(Fim)
          );
        }
      }
    }
  }

  DataPrimeiraCNH() {
    let Fim1 = this.getDate(
      this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao
    );
    let DataFutura = false;
    let Incio = this.obterData();

    if (Incio != null) {
      var DataInicio = Date.parse(Incio);
      var DataFim = Date.parse(Fim1);

      if (DataFim > DataInicio) {
        DataFutura = true;
      }
    }

    if (DataFutura) {
      this.toasty.error(
        "Dados da Carteira Nacional de Habilitação  - Data de primeira CNH: " +
        Fim1 +
        " Não pode ser futura!"
      );
    } else {
      let Incio = this.getDate(
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao
      );
      let Fim = this.getDate(
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade
      );
      let Fim1 = this.getDate(
        this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao
      );

      if (Incio && Fim && Fim1) {
        var DataInicio = Date.parse(Incio);
        var DataFim = Date.parse(Fim);
        var DataFim1 = Date.parse(Fim1);

        if (DataFim1 > DataInicio && DataFim1 > DataFim) {
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.dataEmissao = "";
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.validade = "";
          this.dadosGeraisAprovacao.carteiraDeHabilitacao.primeiraHabilitacao =
            "";

          this.toasty.error(
            "A Data da primeira CNH : " +
            this.getDateBR(Fim1) +
            " Não pode ser maior que a Data de emissão e Validade "
          );
        }
      }
    }
  }

  DataEmissaoRGeral() {
    let Fim = this.getDate(this.dadosGeraisAprovacao.registroGeral.emissao);
    let Incio = this.obterData();

    if (Incio != null) {
      var DataInicio = Date.parse(Incio);
      var DataFim = Date.parse(Fim);
      if (DataFim > DataInicio) {
        this.dadosGeraisAprovacao.registroGeral.emissao = "";
        this.toasty.error(
          "Registro Geral  - Data de Emissão: " +
          this.getDateBR(Fim) +
          " não pode ser futura!"
        );
        this.showUpCnh = false;
      } else {
        this.mudouCNH();
      }
    }
  }

  DataFormacao() {
    let Fim = this.getDate(this.formacao.conclusaoCurso);
    let Incio = this.getDate(this.formacao.inicioCurso);

    if (Incio && Fim) {
      var DataInicio = Date.parse(Incio);
      var DataFim = Date.parse(Fim);

      if (DataInicio > DataFim) {
        this.formacao.conclusaoCurso = "";
        this.formacao.inicioCurso = "";
        this.toasty.error(
          "Formação - Início do Curso não ser maior: " +
          this.getDateBR(Incio) +
          " Que a Conlusão!" +
          this.getDateBR(Fim)
        );
      }
    }
  }

  DataOrgaoClasseFut(DataOC) {
    let Fim = this.getDate(DataOC);
    let Incio = this.obterData();

    if (Incio != null) {
      var DataInicio = Date.parse(Incio);
      var DataFim = Date.parse(Fim);
      if (DataFim > DataInicio) {
        return true;
      } else {
        return false;
      }
    }
  }

  DataOrgaoClasse() {
    let DataEmissaoOC =
      this.dadosGeraisAprovacao.identidadeProfissional.emissao;
    let DataFutura = this.DataOrgaoClasseFut(DataEmissaoOC);

    if (DataFutura) {
      this.dadosGeraisAprovacao.identidadeProfissional.emissao = "";
      this.dadosGeraisAprovacao.identidadeProfissional.validade = "";
      this.toasty.error(
        "Dados de Órgão de Classe - Data de Emissão OC: " +
        DataEmissaoOC +
        " Não pode ser futura!"
      );
    } else {
      let Incio = this.getDate(
        this.dadosGeraisAprovacao.identidadeProfissional.emissao
      );
      let Fim = this.getDate(
        this.dadosGeraisAprovacao.identidadeProfissional.validade
      );

      if (Incio && Fim) {
        var DataInicio = Date.parse(Incio);
        var DataFim = Date.parse(Fim);

        if (DataInicio > DataFim) {
          this.dadosGeraisAprovacao.identidadeProfissional.emissao = "";
          this.dadosGeraisAprovacao.identidadeProfissional.validade = "";
          this.toasty.error(
            "Dados de Órgão de Classe - Data de Emissão OC não ser maior: " +
            this.getDateBR(Incio) +
            " Que a Data de Validade OC: " +
            this.getDateBR(Fim)
          );
        }
      }
    }
  }

  public getDateBR(data: string) {
    if (!data) {
      return "";
    }
    let minhaData = data.split("-");
    let retorno = minhaData[2] + "/" + minhaData[1] + "/" + minhaData[0];

    if (retorno == "") {
      retorno = "";
    }

    return retorno;
  }

  public getDate(data: string) {
    if (!data) {
      return "";
    }

    let minhaData = data.split("/");
    let retorno = minhaData[2] + "-" + minhaData[1] + "-" + minhaData[0];
    return retorno;
  }

  obterData() {
    const date = new Date();
    const ano = date.getFullYear();
    const mes = date.getMonth() + 1;
    const dia = date.getDate();
    let mesValor = "";
    let diaValor = "";
    mesValor = (mes < 10 ? "0" : "").concat(mes.toString());
    diaValor = (dia < 10 ? "0" : "").concat(dia.toString());
    return ano
      .toString()
      .concat("-")
      .concat(mesValor)
      .concat("-")
      .concat(diaValor);
  }
  toJsDate(data) {
    let dtNasc = data.split("/");
    let diaValidade = dtNasc[0];
    let mesValidade = dtNasc[1];
    let anoValidade = dtNasc[2];

    return anoValidade + "/" + mesValidade + "/" + diaValidade;
  }

  calcIdade() {
    if (new Date(this.dadosGeraisAprovacao.dataNascimento) > new Date()) {
      this.dadosGeraisAprovacao.dataNascimento = "";
      this.toasty.error(
        "Dados Gerais - data de nascimento não pode ser futura "
      );
      this.mudouDataNascimento();
      return;
    } else {
      this.mudouDataNascimento();
    }

    let idade =
      new Date().getFullYear() -
      new Date(this.dadosGeraisAprovacao.dataNascimento).getFullYear();

    if (idade < 18) {
      this.dadosGeraisAprovacao.dataNascimento = "";
      this.toasty.error(
        "Dados Gerais - data de nascimento não pode ser menor que 18 anos "
      );

      this.mudouDadosNasc();
      return;
    } else {
      this.mudouDadosNasc();
    }
  }

  onKeydown(event) {
    console.log(event);
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key.toString() == "Enter") {
      this.block = false;
    }
  }

  @HostListener("window:keydown", ["$event"])
  keyEven(event: KeyboardEvent) {
    if (event.key.toString() == "Enter") {
      this.block = true;
    }
  }

  //Ajuste para ticket 672
  validaCpf(cpf) {
    if (!this.CPFValido(cpf)) {
      this.toasty.error(" CPF Inválido. ");
    }
  }

  CPFValido(strCPF) {
    if (strCPF == null || strCPF == "" || strCPF == undefined) {
      return false;
    } else {
      var Soma;
      var Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (var i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) {
        return false;
      }
      return true;
    }
  }

  disabledCursoFormacao(modalidade: string) {
    this.cursoFormacaoDisabled = true;
    this.modalidadesComCursoFormacao.forEach((m) => {
      if (m == modalidade) {
        this.cursoFormacaoDisabled = false;
      }
    });
  }

  // Habilita e desabilita o botão com base no estado da chk de leitura e aceite dos termos
  hide_button(value: boolean) {
    var aceitar = document.getElementById("aceitar");
    if (value) {
      aceitar.setAttribute("disabled", "false");
    } else {
      aceitar.setAttribute("disabled", "true");
    }
  }

  downloadFileCpfAtualizado(cpfAtualizado: string) {
    // this.getImgUrlCpfAtualizado(cpfAtualizado);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CPF" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
  }

  getImgUrlCpfAtualizado(cpfAtualizado: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCpfAtualizado(cpfAtualizado)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  downloadFileNomeAttFrente(cpf: string) {
    // this.getImgUrlNomeAttFrente(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "NOME" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
  }

  getImgUrlNomeAttFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNomeAttFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Nome atualizado verso========
  downloadFileNomeAttVerso(cpf: string) {
    // this.getImgUrlNomeAttVerso(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "NOME" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
  }

  getImgUrlNomeAttVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNomeAttVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  downloadFileNomeSocialFrente(cpf: string) {
    // this.getImgUrlNomeSocialFrente(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "NSOC" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
  }

  getImgUrlNomeSocialFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNomeSocialFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Nome Social Verso========
  downloadFileNomeSocialVerso(cpf: string) {
    // this.getImgUrlNomeSocialVerso(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "NSOC" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlNomeSocialVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNomeSocialVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  downloadFileSexoFrente(cpf: string) {
    // this.getImgUrlSexoFrente(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "SEXO" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlSexoFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageSexoFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Sexo Verso========
  downloadFileSexoVerso(cpf: string) {
    // this.getImgUrlSexoVerso(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "SEXO" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlSexoVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageSexoVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Nasc Frente========
  downloadFileNascFrente(cpf: string) {
    // this.getImgUrlNascFrente(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CERTNASC" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }


  getImgUrlNascFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNascFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ========Nasc Verso========
  downloadFileNascVerso(cpf: string) {
    // this.getImgUrlNascVerso(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "CERTNASC" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlNascVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNascVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Nacionalidade Frente========
  downloadFileNacionalidadeFrente(cpf: string) {
    // this.getImgUrlNacionalidadeFrente(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "NACIO" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlNacionalidadeFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNacionalidadeFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Nacionalidade Verso========
  downloadFileNacionalidadeVerso(cpf: string) {
    // this.getImgUrlNacionalidadeVerso(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "NACIO" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlNacionalidadeVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNacionalidadeVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente DefFisica========
  downloadFileFrenteDefFisica(cpf: string) {
    // this.getImgUrlFrenteDefFisica(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFF" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlFrenteDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrenteDefFisica(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso DefFisica========
  downloadFileVersoDefFisica(cpf: string) {
    // this.getImgUrlVersoDefFisica(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFF" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlVersoDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVersoDefFisica(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente DefVisual========
  downloadFileFrenteDefVisual(cpf: string) {
    // this.getImgUrlFrenteDefVisual(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFV" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlFrenteDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrenteDefVisual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso DefVisual========
  downloadFileVersoDefVisual(cpf: string) {
    // this.getImgUrlVersoDefVisual(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFV" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlVersoDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVersoDefVisual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente DefAuditiva========
  downloadFileFrenteDefAuditiva(cpf: string) {
    // this.getImgUrlFrenteDefAuditiva(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFA" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlFrenteDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrenteDefAuditiva(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso DefAuditiva========
  downloadFileVersoDefAuditiva(cpf: string) {
    // this.getImgUrlVersoDefAuditiva(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFA" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlVersoDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVersoDefAuditiva(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente DefMental========
  downloadFileFrenteDefMental(cpf: string) {
    // this.getImgUrlFrenteDefMental(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFM" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlFrenteDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrenteDefMental(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso DefMental========
  downloadFileVersoDefMental(cpf: string) {
    // this.getImgUrlVersoDefMental(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFM" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlVersoDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVersoDefMental(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente DefIntelectual========
  downloadFileFrenteDefIntelectual(cpf: string) {
    // this.getImgUrlFrenteDefIntelectual(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFI" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlFrenteDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrenteDefIntelectual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso DefIntelectual========
  downloadFileVersoDefIntelectual(cpf: string) {
    // this.getImgUrlVersoDefIntelectual(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFI" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlVersoDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVersoDefIntelectual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte da frente RG========
  downloadFileFrente(id: number) {
    // this.getImgUrlFrente(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "RG" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Busca a imagem parte do verso RG========
  downloadFileVerso(id: number) {
    // this.getImgUrlVerso(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "RG" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Titulo de eleitor frente========
  downloadFileTitEleitorFrente(id: number) {
    // this.getImgUrlTitEleitorFrente(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "TITULO" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlTitEleitorFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageTitEleitorFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ======== Titulo de eleitor verso========
  downloadFileTitEleitorVerso(id: number) {
    // this.getImgUrlTitEleitorVerso(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "TITULO" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlTitEleitorVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageTitEleitorVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);

      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }


  // ========Cnh frente========
  downloadFileCnhFrente(id: number) {
    // this.getImgUrlCnhFrente(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CNH" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlCnhFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCnhFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Cnh verso========
  downloadFileCnhVerso(id: number) {
    //this.getImgUrlCnhVerso(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "CNH" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlCnhVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCnhVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Ctps Frente========
  downloadFileCtpsFrente(id: number) {
    // this.getImgUrlCtpsFrente(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CTPS" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlCtpsFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCtpsFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Ctps Verso========
  downloadFileCtpsVerso(id: number) {
    // this.getImgUrlCtpsVerso(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "CTPS" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlCtpsVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCtpsVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }


  // ========Numero Nis Frente========
  downloadFileNumeroNisFrente(numeroNis: string) {
    // this.getImgUrlNumeroNisFrente(numeroNis);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "NIS" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlNumeroNisFrente(numeroNis: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNumeroNisFrente(numeroNis)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Numero Nis Verso========
  downloadFileNumeroNisVerso(numeroNis: string) {
    // this.getImgUrlNumeroNisVerso(numeroNis);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "NIS" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlNumeroNisVerso(numeroNis: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNumeroNisVerso(numeroNis)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }



  // ========Documento Militar Frente========
  downloadFileDocumentoMilitarFrente(id: number) {
    // this.getImgUrlDocumentoMilitarFrente(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DM" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDocumentoMilitarFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDocumentoMilitarFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ========Documento Militar Verso========
  downloadFileDocumentoMilitarVerso(id: number) {
    // this.getImgUrlDocumentoMilitarVerso(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DM" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDocumentoMilitarVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDocumentoMilitarVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Numero Inscricao Frente========
  downloadFileNumInscricaoFrente(id: number) {
    // this.getImgUrlNumInscricaoFrente(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "IP" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlNumInscricaoFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNumInscricaoFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.errorHandler.handle(erro);
      });
  }


  // ========Numero Inscricao Verso========
  downloadFileNumInscricaoVerso(id: number) {
    // this.getImgUrlNumInscricaoVerso(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "IP" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlNumInscricaoVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageNumInscricaoVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.errorHandler.handle(erro);
      });
  }


  // ========Certidão UniaoEstavel Verso========
  downloadFileCertidaoUniaoEstavelFrente(cpf: string) {
    // this.getImgUrlCertidaoUniaoEstavelFrente(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CCASA" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlCertidaoUniaoEstavelFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCertidaoUniaoEstavelFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Certidão UniaoEstavel Verso========
  downloadFileCertidaoUniaoEstavelVerso(cpf: string) {
    // this.getImgUrlCertidaoUniaoEstavelVerso(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "CCASA" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlCertidaoUniaoEstavelVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCertidaoUniaoEstavelVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }


  // ========RNE FRENTE========
  downloadFileRneFrente(id: number) {
    // this.getImgUrlRneFrente(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "RNE" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlRneFrente(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageRneFrente(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========RNE VERSO========
  downloadFileRneVerso(id: number) {
    // this.getImgRneVerso(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "RNE" })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgRneVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageRneVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }


  // ========CPF atualizado========
  downloadFileCpfFrenteDependente(cpf: string) {
    // this.getImgUrlCpfFrenteDependente(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "CPF", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlCpfFrenteDependente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCpfFrenteDependente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========CPF atualizado========
  downloadFileCpfVersoDependente(cpf: string) {
    // this.getImgUrlCpfVersoDependente(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "CPF", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlCpfVersoDependente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageCpfVersoDependente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }


  // ========Dependente SexoAttFrente========
  downloadFileDependenteSexoAttFrente(cpf: string) {
    // this.getImgUrlDependenteSexoAttFrente(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "SEXO", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteSexoAttFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteSexoAttFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Dependente SexoAttVerso========
  downloadFileDependenteSexoAttVerso(cpf: string) {
    // this.getImgUrlDependenteSexoAttVerso(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "SEXO", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteSexoAttVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteSexoAttVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }



  // ========Dependente NomeAttFrente========
  downloadFileDependenteNomeAttFrente(cpf: string) {
    // this.getImgUrlDependenteNomeAttFrente(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "NOME", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteNomeAttFrente(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteNomeAttFrente(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Dependente NomeAttVerso========
  downloadFileDependenteNomeAttVerso(cpf: string) {
    // this.getImgUrlDependenteNomeAttVerso(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "NOME", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteNomeAttVerso(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteNomeAttVerso(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }


  // ========Dependente Registro Geral Frente========
  downloadFileDependenteRegGeral(cpf: string) {
    // this.getImgUrlDependenteRegGeral(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "RG", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteRegGeral(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteRegGeral(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  // ========Dependente Registro Geral Verso========
  downloadFileDependenteRegGeralVerso(cpf: string) {
    // this.getImgUrlDependenteRegGeralVerso(id);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "RG", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteRegGeralVerso(id: number) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteRegGeralVerso(id)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }


  // ======== Busca a imagem parte da frente DefVisual========

  downloadFileDependenteFrenteDefVisual(cpf: string) {
    // this.getImgUrlDependenteFrenteDefVisual(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFV", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteFrenteDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefVisual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da verso DefVisual========

  downloadFileDependenteVersoDefVisual(cpf: string) {
    // this.getImgUrlDependenteVersoDefVisual(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFV", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteVersoDefVisual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteVersoDefVisual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da frente DefFisica========

  downloadFileDependenteFrenteDefAuditiva(cpf: string) {
    // this.getImgUrlDependenteFrenteDefAuditiva(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFA", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteFrenteDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefAuditiva(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da Verso DefAuditiva========

  downloadFileDependenteVersoDefAuditiva(cpf: string) {
    // this.getImgUrlDependenteVersoDefAuditiva(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFA", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteVersoDefAuditiva(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteVersoDefAuditiva(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da frente DefMental========

  downloadFileDependenteFrenteDefMental(cpf: string) {
    // this.getImgUrlDependenteFrenteDefMental(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFM", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteFrenteDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefMental(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da Verso DefFisica========

  downloadFileDependenteVersoDefMental(cpf: string) {
    // this.getImgUrlDependenteVersoDefMental(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFM", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteVersoDefMental(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefMental(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da frente DefIntelectual========

  downloadFileDependenteFrenteDefIntelectual(cpf: string) {
    // this.getImgUrlDependenteFrenteDefIntelectual(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFI", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteFrenteDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefIntelectual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da Verso DefFisica========

  downloadFileDependenteVersoDefIntelectual(cpf: string) {
    // this.getImgUrlDependenteVersoDefIntelectual(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFI", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteVersoDefIntelectual(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteVersoDefIntelectual(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  downloadFileDependenteFrenteDefFisica(cpf: string) {
    // this.getImgUrlDependenteFrenteDefFisica(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "FRENTE", campo: "DEFF", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteFrenteDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteFrenteDefFisica(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }
  // ======== Busca a imagem parte da verso DefFisica========

  downloadFileDependenteVersoDefFisica(cpf: string) {
    // this.getImgUrlDependenteVersoDefFisica(cpf);
    this.isLoadImagem = true;
    this.recadastramentoService.downloadImagem({ cpf: this.dadosGeraisAprovacao.cpf, tipo: "VERSO", campo: "DEFF", dependente: cpf })
      .then((resultado) => {
        this.isLoadImagem = false;
        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }

  getImgUrlDependenteVersoDefFisica(cpf: string) {
    this.isLoadImagem = true;
    this.recadastramentoService
      .getImageDependenteVersoDefFisica(cpf)
      .then((resultado) => {
        this.isLoadImagem = false;

        this.openImageNewWindow(resultado);
      })
      .catch((erro) => {
        this.isLoadImagem = false;
        this.errorHandler.handle(erro);
      });
  }




  openImageNewWindow(data: JsonMessage) {
    if (data == null || data.message == 'Arquivo não localizado no servidor') {
      this.toasty.error("Imagem não localizada, tente novamente mais tarde!")
      return;
    }
    if (data.fileType != "pdf") {
      var image = new Image();
      image.src = "data:image/" + data.fileType + ";base64," + data.message;

      var w = window.open("");
      w.document.body.style.margin = "0px";
      w.document.body.style.background = "#0e0e0e";
      w.document.body.style.height = "100%";

      w.document.body.appendChild(w.document.createElement("img"));
      var img = w.document.getElementsByTagName("img")[0];
      img.style.width = "100%";
      img.style.height = "auto";
      img.style.display = "block";
      img.src = image.src;
    } else {

      var byteCharacters = atob(data.message);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

  }

  downloadLocalFile(base64: any) {
    const source = `data:application/pdf;base64,${base64}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `Document - ${new Date().toISOString()}.pdf`
    link.click();
  }
}
