import { Lotacao } from "./../model/Lotacao";

import { LotacaoAssociacaoService } from "./lotacao-associacao.service";
import { ErrorHandlerService } from "./../core/error-handler.service";
// import { PerfilService } from './perfil.service';
import { Usuario } from "./../model/Usuario";
// import { ConsultaLotacaoService } from './consulta-lotacao.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastyService } from "ng2-toasty";
import { UsuarioFiltro } from "../model/UsuarioFiltro";
import { Router, ActivatedRoute } from "@angular/router";
import { RecadastramentoService } from "../recadastramento/recadastramento.service";
import { AuthService } from "../seguranca/auth.service";

@Component({
  selector: "app-lotacao-associacao",
  templateUrl: "./lotacao-associacao.component.html",
  styleUrls: ["./lotacao-associacao.component.css"],
})
export class LotacaoAssociacaoComponent implements OnInit {
  novaLotacao: Lotacao;

  @ViewChild("tabela") grid;

  cols: any[];

  usuarios: Usuario[];

  filter = new UsuarioFiltro();

  lotacoes: Lotacao[];
  lotacoes2: Lotacao[];

  edit = false;

  usuario: any;
  LotacaoAssociacaoService: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private lotacaoService: LotacaoAssociacaoService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private recadastramentoService: RecadastramentoService,
    private router: Router,
    public auth: AuthService
  ) {
    this.usuario = activatedRoute.snapshot.queryParams;
  }

  ngOnInit() {
    this.preencherLotacoes();
    this.listaLotacao2();
    this.hide_logo();
  }
  hide_logo() {
    var el = document.getElementsByClassName("image")[0];

    el.setAttribute("style", "background: none;");
  }

  preencherLotacoes() {
    this.lotacaoService
      .pesquisar(this.usuario.cpf)
      .then((lotacoes) => (this.lotacoes = lotacoes))
      .catch((erro) => this.errorHandler.handle(erro));
  }

  listaLotacao2() {
    this.lotacaoService
      .listarLotPorCpf(this.usuario.cpf)
      .then((resultado) => {
        this.lotacoes2 = resultado;
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  onSubmit() {
    if (this.lotacoes2.length <= 0) {
      return this.toasty.error(
        "Servidor deve ter uma ou mais lotações associadas!"
      );
    }
    this.recadastramentoService
      .salvar(this.usuario.cpf, this.lotacoes2)
      .then((lotacoes) => {
        this.lotacoes = lotacoes
        this.toasty.success("Nova lotação inserida com sucesso!");
        this.ngOnInit();
      })
      .catch((erro) => {this.errorHandler.handle(erro)});
  }
  voltaPag() {
    this.router.navigate(["/consulta-lotacao"]);
  }
}
