import { ErrorHandlerService } from "./../core/error-handler.service";
import { PerfilService } from "./perfil.service";
import { Usuario } from "./../model/Usuario";
import { UserSantanderService } from "./user-santander.service";
import { Component, OnInit, ViewChild } from "@angular/core";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { Perfil } from "../model/Perfil";
import { LazyLoadEvent } from "primeng/primeng";
import { ToastyService } from "ng2-toasty";
import { UsuarioFilter } from "../model/UsuarioFilter";

library.add(fas, far);

@Component({
  selector: "app-user-santander",
  templateUrl: "./user-santander.component.html",
  styleUrls: ["./user-santander.component.css"],
})
export class UserSantanderComponent implements OnInit {
  totalRegistros = 0;
  registerForm: FormGroup;

  @ViewChild("tabela") grid;

  cols: any[];

  headers = ["Nome", "Matrícula", "Perfil", "Editar", "Excluir"];

  usuarios: Usuario[];

  filter = new UsuarioFilter();

  perfis: Perfil[];
  submitted = false;

  edit = false;

  usuario: Usuario;

  usuarioExcluir = new Usuario();

  constructor(
    private formBuilder: FormBuilder,
    private userSantanderService: UserSantanderService,
    private perfilservice: PerfilService,
    private toasty: ToastyService,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      nome: ["", Validators.required],
      matricula: ["", Validators.required],
      cpf: ["", [Validators.required]],

      perfil: ["", [Validators.required]],
    });

    this.preencherPerfis();
    this.filter = new UsuarioFilter();
    this.usuario = new Usuario();
    this.edit = false;
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = false;

    if (this.registerForm.invalid) {
      this.submitted = true;
      return;
    }

    if (!this.CPFValido(this.filterToUsuario().cpf)) {
      this.toasty.error(" CPF Inválido. ");
      this.submitted = true;
      return;
    }

    this.userSantanderService
      .salvar(this.filterToUsuario())
      .then(() => {
        this.toasty.success("Usuario salvo com sucesso!");

        this.toasty.error("Usuario salvo com sucesso!");

        this.limpar();
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  pesquisar(pagina = 0) {
    this.filter.page = pagina;
    this.filter.isServidor = "false";
    this.userSantanderService
      .pesquisar(this.filter)
      .then((resultado) => {
        this.usuarios = resultado.usuarios;
        this.totalRegistros = resultado.total;
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  preencherPerfis() {
    this.perfilservice
      .pesquisar()
      .then((perfis) => (this.perfis = perfis))
      .catch((erro) => this.errorHandler.handle(erro));
  }

  limpar() {
    this.filter = new UsuarioFilter();
    this.edit = false;
    this.pesquisar();
  }

  mudaPagina(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    if (this.edit) {
      this.limpar();
    }
    this.pesquisar(pagina);
  }

  confirmarInativar(usuario: any) {
    this.inativar(usuario);
  }

  inativar(usuario: any) {
    this.userSantanderService
      .inativar(usuario.id)
      .then(() => {
        this.pesquisar();
        this.toasty.success("Usuario excluido com sucesso!");
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  editar(usuario: any) {
    this.edit = true;
    this.filter.id = usuario.id;
    this.filter.nome = usuario.nome;
    this.filter.login = usuario.login;
    this.filter.cpf = usuario.cpf;
    this.filter.perfil = usuario.perfis[0].id;
  }

  filterToUsuario() {
    this.usuario = new Usuario();
    if (this.filter.id != null) {
      this.usuario.id = this.filter.id;
    }
    this.usuario.login = this.filter.login;
    this.usuario.nome = this.filter.nome;
    this.usuario.cpf = this.filter.cpf;
    this.usuario.status = "1";

    let perfs: Perfil[] = [
      {
        id: this.filter.perfil.id,
        descricao: null,
        dataInicioVigencia: null,
        dataFimVigencia: null,
      },
    ];

    this.usuario.perfis = perfs;
    this.usuario.termo = "S";
    return this.usuario;
  }

  CPFValido(strCPF) {
    if (strCPF == null || strCPF == "" || strCPF == undefined) {
      return false;
    } else {
      var Soma;
      var Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (var i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) {
        return false;
      }
      return true;
    }
  }
}
