
import { RecadastramentoHttp } from '../seguranca/recadastramentoHttp';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Perfil } from './../model/Perfil';

@Injectable({
  providedIn: 'root'
})
export class CadastroVigenciasService {

    perfilVigenciaUrl: any;

    constructor(private http: RecadastramentoHttp) { 
        this.perfilVigenciaUrl = `${environment.apiUrl}/perfil/vigencia`;
    }

    async listarPerfilVigencia(): Promise<any> {
        const res = await this.http.get<any>(`${this.perfilVigenciaUrl}/listar/`)
            .toPromise();
        const data = (res as Perfil[]);
        return data;
    }

    async salvar(perfis: Perfil[]): Promise<any> {
        return this.http.post<Perfil>(`${this.perfilVigenciaUrl}/salvar/`, perfis)
          .toPromise();
    }

}