export class RegistroGeralDependente {
  id: number;
  numRg: string;
  docNuRgFrente: string;
  docNuRgVerso: string;
  imgDepNuRgFrente: string;
  imgDepNuRgVerso: string;
  orgaoEmis: string;
  uf: string;
  emissao: string;
}
