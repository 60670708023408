import { Component, OnInit, ElementRef } from '@angular/core';
import { Lotacao } from '../model/Lotacao';
import { LotacaoAssociacaoService } from '../lotacao-associacao/lotacao-associacao.service';
import { RecadastramentoService } from '../recadastramento/recadastramento.service';
import { ErrorHandlerService } from '../core/error-handler.service';
import { Dashboard } from '../model/Dashboard';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { ToastyService } from 'ng2-toasty';



library.add(fas, far);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dashboardForm: FormGroup;
  mappedToArray: any;
  data: any;
  status: any;
  options: any;
  color: any;
  lotacoes: Lotacao[];
  filtroStatus: string;
  filtroSetor: string;
  filtroPerfil: string;
  dashboard = new Dashboard();
  total: any;
  quantidade: number;
  perfil: any;

  title = 'STATUS';
  type = 'PieChart';

  chartData: any[] = [];
  chartLabel: any[] = [];


  pieChartLabels: string[] = this.chartLabel;
  pieChartData: number[] = this.chartData;
  chartOptions = {
    responsive: true,
  };
  pieChartColors = [
    {
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'],
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private lotacaoService: LotacaoAssociacaoService,
    private recadastramentoService: RecadastramentoService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private ref: ElementRef
  ) { }

  ngOnInit() {
    this.dashboardForm = new FormGroup({
      perfil: new FormControl(),
      status: new FormControl(),
      lotacoes: new FormControl()
    });

    this.preencherLotacoes();

    this.getAllData();

  }

  async getAllData() {
    this.dashboard = await this.buscarDashboard();

    this.status = await this.getStatusList();
    this.perfil = await this.getPerfilList();

    this.data = await this.getInfoDashBoard("1");
    this.data.forEach(element => {
      this.chartData.push(element[1]);
      this.chartLabel.push(element[0]);
    });
    this.total = this.dashboard.qtdaTotal;
  }

  buscarDashboard() {
    return this.recadastramentoService.listDashboard(null, null, null).toPromise();
  }

  async pesquisar() {    
    this.chartData = [];
    this.chartLabel = [];

    this.dashboard = await this.dadosPesquisa();
    if (this.dashboard.itens.length > 0) {

      this.data = await this.getInfoDashBoard("1");
      this.data.forEach(element => {
        this.chartData.push(element[1]);
        this.chartLabel.push(element[0]);
      });
      this.pieChartData = this.chartData;
      this.pieChartLabels = this.chartLabel;

      this.total = this.dashboard.qtdaTotal;
    } else {
      this.limparSemBuscar();
      this.toasty.error("A pesquisa não retornou resultados");
      this.getAllData();

    }
  }

  async limpar() {
    this.filtroPerfil = null;
    this.filtroSetor = null;
    this.filtroStatus = null;
    this.dashboard = await this.buscarDashboard();
    this.data = await this.getInfoDashBoard("1");
    this.total = this.dashboard.qtdaTotal;
  }

  async limparSemBuscar() {
    this.filtroPerfil = null;
    this.filtroSetor = null;
    this.filtroStatus = null;
    this.data = null;
    this.total = "";

    this.dashboard = null;

  }

  dadosPesquisa() {
    return this.recadastramentoService.listDashboard(this.filtroStatus, this.filtroSetor, this.filtroPerfil).toPromise()
  }

  getStatusList() {
    const dataarray = [
      { descricao: "Pendente", id: 0 },
      { descricao: "Concluídos", id: 4 },
      { descricao: "Rejeitados", id: 5 },
      { descricao: "Em Análise ", id: 1 },
      { descricao: "Em Aprovação", id: 2 }
    ]
    return dataarray;
  }

  getPerfilList() {
    const dataarray = [
      { id: 0, descricao: "Nenhum" },
      { id: 2, descricao: "RH Master" },
      { id: 3, descricao: "RH Central" },
      { id: 4, descricao: "RH Agente" },
      { id: 5, descricao: "Ativo" }
    ]
    return dataarray;
  }

  onChange(id: any) {
    this.options = this.getColorDash(id);
  }

  mudouLotacao(lotacao: string) {
    this.filtroSetor = lotacao;
  }

  mudouPerfil(perfil: string) {
    this.filtroPerfil = perfil;
  }

  mudouStatus(status: string) {
    this.filtroStatus = status;
  }

  getInfoDashBoard(id: any) {

    if (id === "1") {
      this.mappedToArray = this.dashboard.itens;

      this.mappedToArray.forEach(function (value) {
        let count = 0
        let quantidade = 0;

        for (let index = 0; index < value.length; index++) {
          count = count + index;

          if (count === 0) {
            quantidade = value[index];
            value.shift();
          }

          if (count === 1) {
            value[0] = value[0] + " - " + String(quantidade)
          }
        }
      });
    }
    return this.mappedToArray;
  }

  getColorDash(id: any) {
    if (id === "1") {
      this.color = {
        colors: ['#836FFF'],
        is3D: true
      };
    }
    if (id === "2") {
      this.color = {
        colors: ['#3366cc'],
        is3D: true
      };
    }
    if (id === "3") {
      this.color = {
        colors: ['#dc3912'],
        is3D: true
      };
    }
    if (id === "4") {
      this.color = {
        colors: ['#ff9900'],
        is3D: true
      };
    }

    if (id === "5") {
      this.color = {
        colors: ['#109618'],
        is3D: true
      };
    }

    return this.color;
  }


  preencherLotacoes() {
    this.lotacaoService.findAll()
      .then(lotacoes => this.lotacoes = lotacoes)
      .catch(erro => this.errorHandler.handle(erro));
  }
}