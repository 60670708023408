export class CarteiraProfissional {
  id: number
  numeroCtps: string;
  docNumCtpsFrente: string;
  imgNumCtpsFrente: string;
  docNumCtpsVerso: string;
  imgNumCtpsVerso: string;
  serie: string;
  uf: string;
}
