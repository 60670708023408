import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-concluir-recadastramento',
  templateUrl: './concluir-recadastramento.component.html',
  styleUrls: ['./concluir-recadastramento.component.css']
})
export class ConcluirRecadastramentoComponent implements OnInit {
  logoutService: any;

  errorHandler: any;

  constructor(private router: Router) { }

  ngOnInit() {

    this.hide_logo();

  }

  logout() {
    this.router.navigate(['/login']);
  }

  hide_logo() {

    var el = document.getElementsByClassName('image')[0];

    el.setAttribute("style", "background: none;");
  }




}

