import { Perfil } from './Perfil';

export class Usuario {
  id: number;
  nome: string;
  login: string;
  cpf: string;
  perfis: Perfil[];
  status: string;
  matricula01: string;
  matricula02: string;
  perfilDescricao: string;
  termo: string;
  compartilhaInfo: string;
}
