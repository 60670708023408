import { Lotacao } from './../model/Lotacao';
import { Injectable } from '@angular/core';
import { RecadastramentoHttp } from '../seguranca/recadastramentoHttp';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})


export class LotacaoAssociacaoService {

  lotacaoUrl: string;
  usuariosUrl: any;

  constructor(private http: RecadastramentoHttp) {
    this.lotacaoUrl = `${environment.apiUrl}/lotacao`;
  }

  async listarLotPorCpf(cpf: string): Promise<any> {

    const response = await this.http.get<any>(`${this.lotacaoUrl}/listar/` + cpf)
      .toPromise();
    const lotacoes = response;
    return lotacoes;

  }

  async pesquisar(cpf: string) {
    const res = await this.http.get<any>(`${this.lotacaoUrl}/listar/all/` + cpf)
      .toPromise();
    const data = (res as Lotacao[]);
    return data;
  }


  async findAll() {
    const res = await this.http.get<any>(`${this.lotacaoUrl}/all`)
      .toPromise();
    const data = (res as Lotacao[]);
    return data;
  }



}
