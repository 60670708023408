export class Reservista {
  id: number;
  numReserv: string;
  numReservistaFrente: string;
  imgReservistaFrente: string;
  numReservistaVerso: string;
  imgReservistaVerso: string;
  serie: string;
  categoria: string;
  orgMilit: string;
  uf: string;
}
