import { Component, OnInit } from "@angular/core";
import { RecadastramentoService } from "../recadastramento/recadastramento.service";
import { ActivatedRoute } from "@angular/router";
import { ErrorHandlerService } from "../core/error-handler.service";
import { AuthService } from "../seguranca/auth.service";

@Component({
  selector: "app-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["timeline.component.css"],
})
export class TimelineComponent implements OnInit {
  alternate: boolean = true;
  toggle: boolean = true;
  color: boolean = true;
  size: number = 30;
  expandEnabled: boolean = true;

  dadostimeline: any;
  cpf: string;

  constructor(
    private recadastramentoService: RecadastramentoService,
    private activatedRoute: ActivatedRoute,
    public auth: AuthService,
    private errorHandler: ErrorHandlerService
  ) {
    this.activatedRoute.queryParams.subscribe((parametros) => {
      if (parametros["cpf"]) {
        this.cpf = parametros["cpf"];
      }
    });
  }

  ngOnInit() {
    this.pesquisar();
  }

  ngAfterViewInit() {}

  setMyStyles(data: any) {
    let styles = {};
    if (data !== null) {
      styles = {
        "background-color": "#1e94d2",
      };
    } else {
      styles = {
        "background-color": "#ff000000",
      };
    }

    return styles;
  }

  pesquisar() {
    this.recadastramentoService
      .listarTimeline(
        this.cpf != null && this.cpf != undefined
          ? this.cpf
          : this.auth.jwtPayload.cpf
      )
      .then((resultado) => {
        this.dadostimeline = resultado;
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }
}
